import React, { useEffect, useRef, useState } from 'react'
import { Container, Navbar, NavDropdown } from 'react-bootstrap'
import styles from './NavBar.module.css'
import './Navbar.css'
import pavilionLogo from 'assets/imgs/pavilionLogo.png'
import pavilionLogoNavbarLight from 'assets/imgs/pavilionLogoNavbarLight.png'

import {ReactComponent as MenuIcon} from 'assets/icons/menuIcon.svg'
import {ReactComponent as MessageIcon} from 'assets/icons/messageIcon.svg'
import {ReactComponent as PhoneIcon} from 'assets/icons/phoneIcon.svg'
import { Link, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ChangeLangueList from 'components/Global/Elements/ChangeLangueList/ChangeLangueList'
import { useSelector } from 'react-redux'
import { emailConnect,  whtsAppConnect } from 'utils/features'
import { HashLink } from 'react-router-hash-link'
function NavBar({toggleSideNavBar ,isRouteExist}) {
    const {t} =useTranslation()
    const navbarRef=useRef(null)
    const navbarDropdownFirstRef=useRef(null)
    const navbarDropdownSecondRef=useRef(null)
    // useEffect(()=>{
    //     setTimeout(()=>{navbarDropdownFirstRef.current.querySelector('.dropdown-toggle').click()},1)
    //     setTimeout(()=>{navbarDropdownFirstRef.current.querySelector('.dropdown-toggle').click()},2)
    //     setTimeout(()=>{navbarDropdownSecondRef.current.querySelector('.dropdown-toggle').click()},3)
    //     setTimeout(()=>{navbarDropdownSecondRef.current.querySelector('.dropdown-toggle').click()},4)
    // },[navbarDropdownSecondRef])
    const [settings,setSettings]=useState([])
    const selector=useSelector(data=>data.GlobalReducer)
    useEffect(()=>{
        setSettings(selector.generalSettings)
    },[selector])
  return (
    <Navbar expand="xl" className={`${styles['navbar']} ${isRouteExist ?styles['navbar--light']:''}`} ref={navbarRef} >
        <div className='d-flex flex-column w-100' data-aos='fade-up' data-aos-once='true'  data-aos-duration='800'>
            <div className={`${styles['navbar-top']} d-none d-xl-flex`}>
                <Container fluid='lg' className='container--header'>
                    <div className={styles['navbar-top__wrapper']}>
                        <div className={styles['navbar-top__contact-wrapper']}>
                            <NavLink to='/'>
                                <img src={pavilionLogo} alt='logo' className={styles['navbar__logo']}/>
                                <img src={pavilionLogoNavbarLight} alt='logo' className={`${styles['navbar__logo']} ${styles['navbar__logo--light']}`}/>
                            </NavLink>
                            <div className='d-flex align-items-center'>
                                <ChangeLangueList/>
                            </div>
                            <button role='button' onClick={()=>{window.open(`tel:${settings?.contact_phone_number?settings?.contact_phone_number:'+971 54 217 2270'}`, '_self')}} className={`${styles['navbar-top__contact-item']} ${styles['navbar-top__contact-item--margin']}`}>
                                <PhoneIcon/>
                                <span className={styles['navbar-top__contact-text']}>{settings?.contact_phone_number?settings?.contact_phone_number:'+971 54 217 2270'}</span>
                            </button>
                            <a  href={emailConnect(settings?.contact_email?settings?.contact_email:'info@pavilion-teck.com')} className={`${styles['navbar-top__contact-item']} ${styles['navbar-top__contact-item--email']}`}>
                                <MessageIcon/>
                                <span className={styles['navbar-top__contact-text']}>{settings?.contact_email?settings?.contact_email:'info@pavilion-teck.com'}</span>
                            </a>
                        </div>
                    </div>
                </Container>
            </div>
            <div className={styles['navbar-bottom']}>
                <Container className='h-100 d-flex'>
                    <div className='h-100 d-flex d-xl-none align-items-center justify-content-between w-100'>
                        <NavLink to='/' className={`${styles['navbar-top__language-wrapper']} ${styles['navbar-top__language-wrapper--responsive']}`}>
                            <img src={pavilionLogoNavbarLight} alt='logo' className={`${styles['navbar__logo']} ${styles['navbar__logo--light']}`}/>
                            <img src={pavilionLogo} alt='logo' className={styles['navbar__logo']}/>   
                        </NavLink>
                        <button role='button' className={`${styles['navbar__menu-btn']} `} onClick={toggleSideNavBar}>
                            <MenuIcon/>
                        </button>
                    </div>
                    <Navbar.Collapse className='h-100'>
                        <ul className={`${styles['navbar-nav']} navbar-nav w-100 h-100 d-flex align-items-center justify-content-center`}>
                            <li className={`${styles["navbar__menu-items"]} nav-item `}>
                                <HashLink smooth to='/' className={`${styles['navbar-menu-link']} nav-link`}>{t('Home')}</HashLink>
                            </li>
                            <li className={`${styles["navbar__menu-items"]} nav-item`}>
                                <HashLink to='/about-us' className={`${styles['navbar-menu-link']} nav-link`}>{t('About us')}</HashLink>
                                {/* <NavLink to='/about-us' className={`${styles['navbar-menu-link']} nav-link`}>{t('About us')}</NavLink> */}
                            </li>
                            <li className={`${styles["navbar__menu-items"]} nav-item`}>
                                <HashLink to='/services' className={`${styles['navbar-menu-link']} nav-link`}>{t('Services')}</HashLink>
                                {/* <NavLink to='/services' className={`${styles['navbar-menu-link']} nav-link`}>{t('Services')}</NavLink> */}
                                <div className={styles['navbar__menu-dropdown-items-list']}>
                                    <HashLink to='/services/e-commerce-web-design' className={`${styles['navbar-menu-link']} nav-link`}>
                                        {t('E-Commerce')}
                                    </HashLink>
                                    <HashLink to='/services/digital-marketing' className={`${styles['navbar-menu-link']} nav-link`}>
                                        {t('Social Media Marketing')}
                                    </HashLink>
                                    <HashLink to='/services/brand-identity' className={`${styles['navbar-menu-link']} nav-link`}>
                                        {t('Brand Identity')}
                                    </HashLink>
                                    <HashLink to='/services/web-design' className={`${styles['navbar-menu-link']} nav-link`}>
                                        {t('Website-Design')}
                                    </HashLink>
                                    <HashLink to='/services/mobile-applications' className={`${styles['navbar-menu-link']} nav-link`}>
                                        {t('Mobile Apps')}
                                    </HashLink>
                                </div>
                            </li>
                            <li className={`${styles["navbar__menu-items"]} nav-item`}>
                                {/* <HashLink to='/#portfolio' className={`${styles['navbar-menu-link']} nav-link`}>{t('Portfolio')}</HashLink> */}
                                <NavLink to='/portfolio' className={`${styles['navbar-menu-link']} nav-link`}>{t('Portfolio')}</NavLink>
                            </li>
                            {/* <li className={`${styles["navbar__menu-items"]} nav-item`}>
                                <NavLink to='/' className={`${styles['navbar-menu-link']} nav-link`}>{t('Products')}</NavLink>
                            </li> */}
                            <li className={`${styles["navbar__menu-items"]} nav-item`}>
                                <a href={'https://blog.pavilion-teck.com/index.php/our-blog/'} target='_blank'  className={`${styles['navbar-menu-link']} nav-link`}>{t('Blogs')}</a>
                            </li>
                            <li className={`${styles["navbar__menu-items"]} nav-item`}>
                                <HashLink to='/contact-us' className={`${styles['navbar-menu-link']} nav-link`}>{t('Contact Us')}</HashLink>
                                {/* <NavLink to='/contact-us' className={`${styles['navbar-menu-link']} nav-link`}>{t('Contact Us')}</NavLink> */}
                            </li>
                        </ul> 
                    </Navbar.Collapse>
                </Container>
            </div>

        </div>


    </Navbar>
  )
}

export default NavBar