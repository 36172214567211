import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import ContactUs from 'components/Home/ContactUs/ContactUs'
import Footer from 'components/Global/Layout/Footer/Footer'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { axiosConfig } from 'utils/axiosConfig'
import RequestACallPopUp from 'components/Global/Elements/RequestACallPopUp/RequestACallPopUp'
import axios from 'axios'
import Loading from 'components/Global/Elements/Loading/Loading'
import SuccessRequestPopup from 'components/Global/Elements/SuccessRequestPopup/SuccessRequestPopup'
import HeaderAboutUs from 'components/AboutUs/HeaderAboutUs/HeaderAboutUs'
import PortfolioHeader from 'components/Portfolio/PortfolioHeader/PortfolioHeader'
import { Col, Container, Row } from 'react-bootstrap'
import PortfolioProjectCard from 'components/Portfolio/PortfolioProjectCard/PortfolioProjectCard'
import styles from './Portfolio.module.css'
function Portfolio() {
  const {t} =useTranslation()
  const selector=useSelector(data=>data.GlobalReducer)
  const [projects,setProjects]=useState([])
  const [isSuccessOpen,setIsSuccessOpen]=useState(false)
  const [projectCategories ,setProjectCategories] =useState([])
  const [filteredProjects ,setFilteredProjects] = useState([])
  const [activeCategory ,setActiveCategory] =useState('all')
  function filterProjects(id){
      setActiveCategory(id)
      // projectsRef.current.classList.add(styles['our-projects--filter-active'])
      // setTimeout(()=>{
      //     projectsRef.current.classList.remove(styles['our-projects--filter-active'])
      // },1000)
      let filteredPojectsData=[]
      projects?.forEach(project=>{
          if(project?.project_category_id == id){
              filteredPojectsData.push(project)
          }
      })
      setFilteredProjects(filteredPojectsData)
  }
  function filterAllProjects(){
      setActiveCategory('all')
      // projectsRef.current.classList.add(styles['our-projects--filter-active'])
      // setTimeout(()=>{
      //     projectsRef.current.classList.remove(styles['our-projects--filter-active'])
      // },1000)
      setFilteredProjects(projects);
  }
  function filterMobileDevice(event){
      if(event.target.value=='all'){
          filterAllProjects()
      }else{
          filterProjects(event.target.value)
      }
  }
  useEffect(()=>{
      setFilteredProjects(selector?.projects)
      setProjects(selector?.projects)
      setProjectCategories(selector?.projectCategories)
  },[selector])

  return (
    <>
    <motion.div 
      init={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
        <MetaTags>
              <title>Pavilion</title>
        </MetaTags>
        {/* {isPageLoading&&<Loading/>} */}
        {isSuccessOpen&&<SuccessRequestPopup/>}
        {
          !localStorage.getItem('popUpSubmitted')&&
          <RequestACallPopUp setIsSuccessOpen={setIsSuccessOpen}/>
        }
        <PortfolioHeader totalNumberOfProjects={projects?.length}/>
        <section>
          <Container>
            <ul className={styles['our-projects__list']} data-aos='fade-up' data-aos-once='true' data-aos-delay='200' data-aos-duration='800'>
              <li className={styles['our-projects__item']}>
                  <button onClick={()=>{filterAllProjects()}}
                  className={`${styles['our-projects__item-button']} 
                  ${activeCategory =='all'?styles['our-projects__item-button--active']:''}`}>{t('All')}</button>
              </li>
              {
                  projectCategories &&projectCategories?.map(category=>(
                      <li className={styles['our-projects__item']} key={category?.id}>
                          <button onClick={()=>filterProjects(category?.id)}
                              className={`${styles['our-projects__item-button']} 
                              ${activeCategory ==category?.id?styles['our-projects__item-button--active']:''}`}>{category?.title}</button>
                      </li>
                  ))
              }
            </ul>
            <select className={styles['our-projects__list-select']} onChange={(e)=>{filterMobileDevice(e)}}>
                <option value={'all'}>{t('All')}</option>
                {
                    projectCategories &&projectCategories?.map(category=>(
                        <option value={category?.id}
                        
                        key={category?.id}>
                            {category?.title}
                            {/* <button onClick={()=>filterProjects(category?.id)}
                                className={`${styles['our-projects__item-button']} 
                                ${activeCategory ==category?.id?styles['our-projects__item-button--active']:''}`}>{category?.title}</button> */}
                        </option>
                    ))
                }
            </select>
            <Row>
              {
                filteredProjects&&filteredProjects.map((project,index)=>(
                  <Col lg='4' md='6' xs='12' className={styles['projcet__col']} key={index}>
                    <PortfolioProjectCard project={project}/>
                  </Col>
                ))
              }
            </Row>
          </Container>
        </section>
        
        <ContactUs/>
        <Footer/>
    </motion.div>
    </>
  )
}

export default Portfolio