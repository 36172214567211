import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './PortfolioHeader.module.css'
import pavilionSmallLogo from 'assets/icons/pavilionSmallLogo.svg'
import { useTranslation } from 'react-i18next'

function PortfolioHeader({totalNumberOfProjects}) {
    const {t} =useTranslation()
 
  return (
    <header className={styles['header']}>
        <Container className='h-100 container--header'>
            <h1 className={styles['header__title']}>{t('We have worked on')} <span className={styles['header__title-span']}>1{totalNumberOfProjects}</span> {t('projects through 5 years, Here are samples')}</h1>
            <img src={pavilionSmallLogo} alt='logo' className={styles['header__logo']}/>
        </Container>
    </header>
  )
}

export default PortfolioHeader