import React from 'react'
import styles from './OurClientsCard.module.css'
import whatOurClientSayImgPerson from 'assets/imgs/whatOurClientSayImgPerson.png'
import {ReactComponent as StarIcon} from 'assets/icons/starIcon.svg'
import {ReactComponent as GoogleIcon} from 'assets/icons/googleIcon.svg'
import './OurClientsCard.css'
import { useTranslation } from 'react-i18next'
function OurClientsCard({review}) {
  const {t} =useTranslation()
  return (
    <div className={`${styles['our-clients__card']} our-clients__card`}>
        <div>
            <div className={styles['our-clients__card-info']}>
                {/* <img src={whatOurClientSayImgPerson} className={styles['our-clients__card-img']} alt='our client'/> */}
                <div className={`${styles['our-clients__card-info-wrapper']} w-100`}>
                    <div className='d-flex align-items-center justify-content-between w-100'>
                        <h4 className={styles['our-clients__card-info-title']} data-aos='fade-up' data-aos-once='true' data-aos-delay='100' data-aos-duration='800'>
                            {review?.author_name}
                            {/* {t('Clients Reviews')} */}
                        </h4>
                        {/* <h5 className={styles['our-clients__card-info-date']}>22/8/2022</h5> */}
                        {/* <img src={review?.profile_photo_url} className={`${styles['our-clients__card-img--responsive']} d-flex d-lg-none`} alt='our client'
                data-aos='fade-up' data-aos-once='true' data-aos-delay='100' data-aos-duration='800'/> */}
            </div>
                    <div data-aos='fade-up' data-aos-once='true' data-aos-delay='200' data-aos-duration='800'>
                    <GoogleIcon />
                    </div>
                    <div className={styles['our-clients__card-info-icon-wrapper']} data-aos='fade-up' data-aos-once='true' data-aos-delay='300' data-aos-duration='800'>
                        <span className={styles['our-clients__card-info-rate']}>{Math.round(review?.rating)}</span>
                        {review?.rating >0 &&<StarIcon className={styles['our-clients__card-info-icon']}/>}
                        {review?.rating >=1 &&<StarIcon className={styles['our-clients__card-info-icon']}/>}
                        {review?.rating >=2 &&<StarIcon className={styles['our-clients__card-info-icon']}/>}
                        {review?.rating >=3 &&<StarIcon className={styles['our-clients__card-info-icon']}/>}
                        {review?.rating >=4 &&<StarIcon className={styles['our-clients__card-info-icon']}/>}
                    </div>
                </div>
                <p className={styles['our-clients__card-info-quote']}></p>
            </div>
            <div className={styles['our-clients__content-wrapper']}>
                <div className={styles['our-clients__content-description-icon']}>
                    <p className={styles['our-clients__content-wrapper-description']} data-aos='fade-up' data-aos-once='true' data-aos-delay='400' data-aos-duration='800'>
                        {review?.text}
                        {/* {t('The experience, talent and dedication of everyone on our team is what makes us unique. Pavilion Technology is a dynamic and rapidly growing environment at all levels, where ambition meets teamwork in a renewed attempt to meet challenges and achieve success and progress.')} */}
                    </p>
                    
                    {/* <img src={googleDarkSrc} className={styles['our-clients__content-google-icon']} alt='google icon'/> */}
                </div>
                {/* <h3 className={styles['our-clients__content-wrapper-title']}>This is a Great Whole Experience</h3> */}
            </div>
        </div>
        <div className='d-none d-md-flex'>
            {/* <img src={review?.profile_photo_url} className={`${styles['our-clients__card-img']} d-none d-lg-flex`} alt='our client'
            data-aos='fade-up' data-aos-once='true' data-aos-delay='100' data-aos-duration='800'/> */}
        </div>
    </div>
  )
}

export default OurClientsCard