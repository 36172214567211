import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import ContactUs from 'components/Home/ContactUs/ContactUs'
import Footer from 'components/Global/Layout/Footer/Footer'
import { useState } from 'react'
import RequestACallPopUp from 'components/Global/Elements/RequestACallPopUp/RequestACallPopUp'
import Loading from 'components/Global/Elements/Loading/Loading'
import SuccessRequestPopup from 'components/Global/Elements/SuccessRequestPopup/SuccessRequestPopup'
import ServicesTransparentHeader from 'components/Services/ServicesTransparentHeader/ServicesTransparentHeader'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './EcommerceWebDesign.module.css'
import webDesignConsultayionIcon from 'assets/imgs/webDesignConsultayionIcon.png'
import EcommerceBuildingBG from 'assets/imgs/EcommerceBuildingBG.jpg'
import EcommerceBuildingSectionBG1 from 'assets/imgs/EcommerceBuildingSectionBG1.png'
import EcommerceBuildingSectionBG2 from 'assets/imgs/EcommerceBuildingSectionBG2.png'
import EcommerceBuildiingImageSectionImage1 from 'assets/imgs/EcommerceBuildiingImageSectionImage1.png'
import EcommerceBuildiingImageSectionImage2 from 'assets/imgs/EcommerceBuildiingImageSectionImage2.png'
import EcommerceBuildiingImageSectionJoinUs from 'assets/imgs/EcommerceBuildiingImageSectionJoinUs.png'
// import webDesignFinalDelivery from 'assets/imgs/webDesignFinalDelivery.png'
import {ReactComponent as EcommerceBuildiingIconFreeHosting} from 'assets/icons/EcommerceBuildiingIconFreeHosting.svg'
import {ReactComponent as EcommerceBuildiingIconUniqueLogo} from 'assets/icons/EcommerceBuildiingIconUniqueLogo.svg'
import {ReactComponent as EcommerceBuildiingIconMultipleOptions} from 'assets/icons/EcommerceBuildiingIconMultipleOptions.svg'
import {ReactComponent as EcommerceBuildiingIconInnovationDesign} from 'assets/icons/EcommerceBuildiingIconInnovationDesign.svg'
import {ReactComponent as EcommerceBuildiingIconStoreWith} from 'assets/icons/EcommerceBuildiingIconStoreWith.svg'
import {ReactComponent as EcommerceBuildiingIconTecnicalSupport} from 'assets/icons/EcommerceBuildiingIconTecnicalSupport.svg'
import {ReactComponent as EcommerceBuildiingIconMultilungual} from 'assets/icons/EcommerceBuildiingIconMultilungual.svg'
import {ReactComponent as EcommerceBuildiingIconSSLSertificate} from 'assets/icons/EcommerceBuildiingIconSSLSertificate.svg'
import {ReactComponent as EcommerceBuildiingIconEnterStore} from 'assets/icons/EcommerceBuildiingIconEnterStore.svg'
import {ReactComponent as EcommerceBuildiingIconApplicationFor} from 'assets/icons/EcommerceBuildiingIconApplicationFor.svg'

import ecommerceExperinceImage from 'assets/imgs/ecommerceExperinceImage.png'
import ecommerceNoTemplateImage from 'assets/imgs/ecommerceNoTemplateImage.png'
import ecommerceIntegratiyImage from 'assets/imgs/ecommerceIntegratiyImage.png'
import ecommerceNoTimeImage from 'assets/imgs/ecommerceNoTimeImage.png'
import ecommerceWeListenImage from 'assets/imgs/ecommerceWeListenImage.png'
import HeaderWithBG from 'components/Global/Elements/HeaderWithBG/HeaderWithBG'
import { HashLink } from 'react-router-hash-link'
function EcommerceWebDesign() {
  const {t} =useTranslation()
  const [isPageLoading,setIsPageLoading]=useState(true)
  const [isSuccessOpen,setIsSuccessOpen]=useState(false)

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
    <motion.div 
      init={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
        <MetaTags>
              <title>Pavilion</title>
        </MetaTags>
        {/* {isPageLoading&&<Loading/>} */}
        {
          !localStorage.getItem('popUpSubmitted')&&
          <RequestACallPopUp setIsSuccessOpen={setIsSuccessOpen}/>
        }
        {isSuccessOpen&&<SuccessRequestPopup/>}
        <section className={styles['seo']}>
          {/* <div className={styles['seo--bg']}></div> */}
          {/* <ServicesTransparentHeader title={`${t('eCommerce Web Design')}`} description={`${t('Utilize cutting-edge eCommerce web design to increase sales.')}`}/> */}

          <HeaderWithBG 
            title={`${t('E-commerce Building')}`} 
            description={`${t('Your online store is not just a place to sell products, it is a thriving arena for connecting with customers and achieving commercial success.')}`}
            image={EcommerceBuildingBG}
          />
          
          <section className={`${styles['seo-info-wrapper']} mt-5`}>
            <Container>
              <Row>
                <Col lg='6'>
                  <img src={EcommerceBuildingSectionBG1} alt='seo image' className={styles['seo-info-wrapper__image']}/>
                </Col>
                <Col lg='6'>
                  <div className={styles['seo-info-wrapper__content-wrapper']}>
                    {/* <h2 className={styles['seo-info-wrapper__title']}>{t('HAPPY CUSTOMERS, EVERYDAY')}</h2> */}
                    <p className={styles['seo-info-wrapper__description']}>
                      {t("We are proud to offer a professional online store design that is well organized and super easy to use. You will be able to display your products in multiple and attractive sections, and make the buying process easy for our valued customers. And because the convenience of your customers is important to us, we ensure that our platform is safe and secure for user data, which contributes to building trust and loyalty for your customers.")}
                    </p>
                    <p className={styles['seo-info-wrapper__description']}>
                      {t("We also provide full support for electronic payment in all available methods, allowing your customers to complete purchases easily and safely.And because the world is multilingual, we are interested in providing a multilingual store to expand your customer base and provide a personalized and convenient buying experience for each individual.")}
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          
          <section className={styles['seo-info-wrapper']}>
            <Container>
              <Row>
                <Col lg='6' className='order-lg-1 order-2'>
                  <div className={styles['seo-info-wrapper__content-wrapper']}>
                    {/* <h2 className={styles['seo-info-wrapper__title']}>{t('HAPPY CUSTOMERS, EVERYDAY')}</h2> */}
                    <p className={styles['seo-info-wrapper__description']}>
                      {t("Our service is not limited to only website design, but we also offer your online store applications on mobile phones to enable customers to shop with ease while on the go. In order to gain exposure and make the most of your online store, we offer premium marketing services to promote your products and increase your brand awareness.")}
                    </p>
                  </div>
                </Col>
                <Col lg='6' className='order-lg-2 order-1'>
                  <img src={EcommerceBuildingSectionBG2} alt='seo image' className={styles['seo-info-wrapper__image']}/>
                </Col>
              </Row>
            </Container>
          </section>

          <section className={styles['seo-details-wrapper']}>
            <Container>
                <h2 className={styles['seo-details-wrapper__title']}>{t('What we Offer')}</h2>
              <Row>
                <Col lg='3' className={`${styles['seo-details-wrapper__col-wrapper']} mx-auto`}>
                  <div className={styles['seo-details-wrapper__item']}>
                    <div className={styles['seo-details-wrapper__item-icon-wrapper']}>
                      <EcommerceBuildiingIconFreeHosting className={styles['seo-details-wrapper__item-icon']}/>
                    </div>
                    <p className={styles['seo-details-wrapper__item-info']}>{t('Free hosting for online stores for a full year.')}</p>
                  </div>
                </Col>
                <Col lg='3' className={`${styles['seo-details-wrapper__col-wrapper']} mx-auto`}>
                  <div className={styles['seo-details-wrapper__item']}>
                    <div className={styles['seo-details-wrapper__item-icon-wrapper']}>
                      <EcommerceBuildiingIconUniqueLogo className={styles['seo-details-wrapper__item-icon']}/>
                    </div>
                    <p className={styles['seo-details-wrapper__item-info']}>{t('Unique logo design for your online store.')}</p>
                  </div>
                </Col>
                <Col lg='3' className={`${styles['seo-details-wrapper__col-wrapper']} mx-auto`}>
                  <div className={styles['seo-details-wrapper__item']}>
                    <div className={styles['seo-details-wrapper__item-icon-wrapper']}>
                      <EcommerceBuildiingIconMultipleOptions className={styles['seo-details-wrapper__item-icon']}/>
                    </div>
                    <p className={styles['seo-details-wrapper__item-info']}>{t('Multiple options for the design of the online store.')}</p>
                  </div>
                </Col>
                <Col lg='3' className={`${styles['seo-details-wrapper__col-wrapper']} mx-auto`}>
                  <div className={styles['seo-details-wrapper__item']}>
                    <div className={styles['seo-details-wrapper__item-icon-wrapper']}>
                      <EcommerceBuildiingIconInnovationDesign className={styles['seo-details-wrapper__item-icon']}/>
                    </div>
                    <p className={styles['seo-details-wrapper__item-info']}>{t("Innovative design of all elements 'sections, lists and pages'.")}</p>
                  </div>
                </Col>
                <Col lg='3' className={`${styles['seo-details-wrapper__col-wrapper']} mx-auto`}>
                  <div className={styles['seo-details-wrapper__item']}>
                    <div className={styles['seo-details-wrapper__item-icon-wrapper']}>
                      <EcommerceBuildiingIconStoreWith className={styles['seo-details-wrapper__item-icon']}/>
                    </div>
                    <p className={styles['seo-details-wrapper__item-info']}>{t('Store with flexible and adjustable programming languages and development.')}</p>
                  </div>
                </Col>
                <Col lg='3' className={`${styles['seo-details-wrapper__col-wrapper']} mx-auto`}>
                  <div className={styles['seo-details-wrapper__item']}>
                    <div className={styles['seo-details-wrapper__item-icon-wrapper']}>
                      <EcommerceBuildiingIconTecnicalSupport className={styles['seo-details-wrapper__item-icon']}/>
                    </div>
                    <p className={styles['seo-details-wrapper__item-info']}>{t('Technical support and customer service for a full year.')}</p>
                  </div>
                </Col>
                <Col lg='3' className={`${styles['seo-details-wrapper__col-wrapper']} mx-auto`}>
                  <div className={styles['seo-details-wrapper__item']}>
                    <div className={styles['seo-details-wrapper__item-icon-wrapper']}>
                      <EcommerceBuildiingIconMultilungual className={styles['seo-details-wrapper__item-icon']}/>
                    </div>
                    <p className={styles['seo-details-wrapper__item-info']}>{t('Multilingual support.')}</p>
                  </div>
                </Col>
                <Col lg='3' className={`${styles['seo-details-wrapper__col-wrapper']} mx-auto`}>
                  <div className={styles['seo-details-wrapper__item']}>
                    <div className={styles['seo-details-wrapper__item-icon-wrapper']}>
                      <EcommerceBuildiingIconSSLSertificate className={styles['seo-details-wrapper__item-icon']}/>
                    </div>
                    <p className={styles['seo-details-wrapper__item-info']}>{t('SSL certificate if the online store is a website and not an application.')}</p>
                  </div>
                </Col>
                <Col lg='3' className={`${styles['seo-details-wrapper__col-wrapper']} mx-auto`}>
                  <div className={styles['seo-details-wrapper__item']}>
                    <div className={styles['seo-details-wrapper__item-icon-wrapper']}>
                      <EcommerceBuildiingIconEnterStore className={styles['seo-details-wrapper__item-icon']}/>
                    </div>
                    <p className={styles['seo-details-wrapper__item-info']}>{t('Enter store content, including departments, products, and their descriptions.')}</p>
                  </div>
                </Col>
                <Col lg='3' className={`${styles['seo-details-wrapper__col-wrapper']} mx-auto`}>
                  <div className={styles['seo-details-wrapper__item']}>
                    <div className={styles['seo-details-wrapper__item-icon-wrapper']}>
                      <EcommerceBuildiingIconApplicationFor className={styles['seo-details-wrapper__item-icon']}/>
                    </div>
                    <p className={styles['seo-details-wrapper__item-info']}>{t('Application for Android and iPhone or website upon request.')}</p>
                  </div>
                </Col>
              </Row>
              <HashLink to='/#contact-us' className={styles['header__feature-item']}>{t('Ask For Consultation')}</HashLink>
            </Container>
          </section>
          
          <section className={styles['seo-info-wrapper']}>
            <Container>
              <Row>
                <Col lg='8' className='order-lg-1 order-2'>
                  <div className={styles['seo-info-wrapper__content-wrapper']}>
                    <h2 className={styles['seo-info-wrapper__why-title']}>{t('Why contract with us to create your store?')}</h2>
                    <p className={styles['seo-info-wrapper__why-description']}>
                      {t("Contract with us to create your online store and enjoy many exclusive benefits")}:
                    </p>
                    <ul className={styles['seo-info-wrapper__list']}>
                      <li>
                        <p className={styles['seo-info-wrapper__list-info']}>
                          {t('Shipping and electronic payment support: We provide you with an integrated solution for the shipping and electronic payment process, providing your customers with a smooth and convenient experience.')}
                        </p>
                      </li>
                      <li>
                        <p className={styles['seo-info-wrapper__list-info']}>
                          {t('Free Hosting: Benefit from free hosting for your store for a full year, which brings you tangible savings in operating costs.')}
                        </p>
                      </li>
                      <li>
                        <p className={styles['seo-info-wrapper__list-info']}>
                          {t('Technical support throughout the year: Get specialized technical support for a full year, to ensure the safety and stability of your online store and to solve any technical problem you encounter.')}
                        </p>
                      </li>
                      <li>
                        <p className={styles['seo-info-wrapper__list-info']}>
                          {t("Performance follow-up and analysis: We monitor and analyze your store's performance throughout the year, which helps you make strategic decisions and improve your business performance.")}
                        </p>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col lg='4' className='order-lg-2 order-1'>
                  <img src={EcommerceBuildiingImageSectionImage1} alt='seo image' className={styles['seo-info-wrapper__image']}/>
                </Col>
              </Row>
            </Container>
          </section>
          
          <section className={styles['seo-info-wrapper']}>
            <Container>
              <Row>
                <Col lg='4'>
                  <img src={EcommerceBuildiingImageSectionImage2} alt='seo image' className={styles['seo-info-wrapper__image']}/>
                </Col>
                <Col lg='8'>
                  <div className={styles['seo-info-wrapper__content-wrapper']}>
                    {/* <h2 className={styles['seo-info-wrapper__why-title']}>{t('Why contract with us to create your store?')}</h2> */}
                    {/* <p className={styles['seo-info-wrapper__why-description']}>
                      {t("Contract with us to create your online store and enjoy many exclusive benefits")}:
                    </p> */}
                    <ul className={styles['seo-info-wrapper__list']}>
                      <li>
                        <p className={styles['seo-info-wrapper__list-info']}>
                          {t('Quick Response: We strive to provide a fast and efficient service, with all your requests and requirements being met accurately and in a timely manner.')}
                        </p>
                      </li>
                      <li>
                        <p className={styles['seo-info-wrapper__list-info']}>
                          {t('The possibility of adding improvements: We provide you with the ability to add improvements and modifications to your store after publication and delivery, to meet your changing needs and aspirations of your customers.')}
                        </p>
                      </li>
                      <li>
                        <p className={styles['seo-info-wrapper__list-info']}>
                          {t('E-marketing support: We help you enhance your digital presence and achieve tangible results through innovative e-marketing services.')}
                        </p>
                      </li>
                      <li>
                        <p className={styles['seo-info-wrapper__list-info']}>
                          {t("Performance follow-up and analysis: We monitor and analyze your store's performance throughout the year, which helps you make strategic decisions and improve your business performance.")}
                        </p>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
              
              <p className={styles['seo-info-wrapper__list-description']}>{t('Take advantage of our expertise and technical skills in designing your online store.We are here to achieve one goal: your complete satisfaction and success by meeting all your requirements in the way you aspire to.')}</p>
              <HashLink to='/#contact-us' className={styles['header__feature-item']}>{t('Ask For Consultation')}</HashLink>
            </Container>
          </section>
          
          <section className={styles['seo-info-wrapper']}>
            <Container>
              <Row>
                {/* <Col lg='4'>
                  <img src={EcommerceBuildiingImageSectionImage2} alt='seo image' className={styles['seo-info-wrapper__image']}/>
                </Col> */}
                <Col lg='12'>
                  <div className={styles['seo-info-wrapper__content-wrapper']}>
                    <h2 className={styles['seo-info-wrapper__why-title']}>{t('What is the duration of the implementation of the online store?')}</h2>
                    <p className={styles['seo-info-wrapper__why-description']}>
                      {t("The duration of an online store’s operation varies and depends on several factors, including:")}:
                    </p>
                    <ul className={styles['seo-info-wrapper__list']}>
                      <li>
                        <p className={styles['seo-info-wrapper__list-info']}>
                          {t('Size and complexity of the online store: If the required requirements are complex and include many custom features and functions, building the store may take longer.')}
                        </p>
                      </li>
                      <li>
                        <p className={styles['seo-info-wrapper__list-info']}>
                          {t('Collaboration and Communication: Effective collaboration between the design team and the client affects the speed of project completion and the achievement of expectations.')}
                        </p>
                      </li>
                      <li>
                        <p className={styles['seo-info-wrapper__list-info']}>
                          {t('Availability of content and materials: If all the necessary content and materials are available in advance, the process of developing the store will be easier and may take less time.')}
                        </p>
                      </li>
                      <li>
                        <p className={styles['seo-info-wrapper__list-info']}>
                          {t("Fixed schedule: If there is a set schedule for completing the project, it will be executed according to that schedule.")}
                        </p>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
              
              <p className={`${styles['seo-info-wrapper__list-description']} mt-5`}>{t("In general, the duration of an online store ranges from 30 to 60 days, depending on the complexity of the project and the amount of work required. However, it should be noted that this period may vary from case to case based on individual circumstances and customer requirements, bearing in mind that we guarantee you a high-quality online store at the best time.")}</p>
              {/* <HashLink to='/#contact-us' className={styles['header__feature-item']}>{t('Ask For Consultation')}</HashLink> */}
            </Container>
          </section>
          
          <section className={styles['seo-info-wrapper']}>
            <Container>
              <Row>
                <Col lg='7'>
                  <div className={styles['seo-info-wrapper__content-wrapper']}><p className={styles['seo-info-wrapper__why-description']}>
                      {t("Join us today and take advantage of our dedicated team to make your online store a success.Let's work together to build a strong brand identity and deliver an unforgettable shopping experience to your customers. Drive towards success and make your online store shine in today's e-commerce world!")}
                    </p>
                  </div>
                </Col>
                <Col lg='5'>
                  <img src={EcommerceBuildiingImageSectionJoinUs} alt='seo image' className={styles['seo-info-wrapper__image']}/>
                </Col>
              </Row>
              <HashLink to='/#contact-us' className={styles['header__feature-item']}>{t('Ask For Consultation')}</HashLink>
            </Container>
          </section>

        </section>
        
        <ContactUs/>
        <Footer/>
    </motion.div>
    </>
  )
}

export default EcommerceWebDesign