import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './PortfolioDetailsDesignScreens.module.css'

function PortfolioDetailsDesignScreens({item}) {
 
  return (
    <header className={styles['header']}>
        <Container className='h-100 container--header mb-3'>
          <Row>
            <div className={styles['header__imgs-wrapper']}>
              {
                item?.images && item?.images?.map((image,index)=>(
                  <img src={image} alt='logo' className={styles['header__imgs']} key={index}/>
                ))
              }
            </div>
          </Row>
        </Container>
    </header>
  )
}

export default PortfolioDetailsDesignScreens