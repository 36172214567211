import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './PortfolioDetailsHeader.module.css'
import {ReactComponent as AndroidIcon} from 'assets/icons/androidIcon.svg'
import {ReactComponent as AppleIcon} from 'assets/icons/appleIcon.svg'
import {ReactComponent as HomeHeaderWebsiteIcon} from 'assets/icons/homeHeaderWebsiteIcon.svg'
import { useTranslation } from 'react-i18next'

function PortfolioDetailsHeader({item}) {
  const {t} =useTranslation()
  return (
    <header className={styles['header']}>
        <Container className='h-100 container--header'>
          <Row>
            <Col lg='6'>
              <img src={item?.second_section_background_image} alt='logo' className={styles['header__logo']}/>
            </Col>
            <Col lg='6'>
              <h1 className={styles['header__title']}>{item?.title}</h1>
              <p className={styles['header__description']}>{item?.description}</p>
            </Col>
          </Row>
          <div className={styles['our-projects__project-links-wrapper']}>
            {
              item?.google_play_link&&
              <a href={item?.google_play_link} target='_blank' className={styles['our-projects__project-link']}><AndroidIcon/> {t('Google Play')}</a>
            }
            {
              item?.app_stote_link&&
              <a href={item?.app_stote_link} target='_blank' className={styles['our-projects__project-link']}><AppleIcon/> {t('Apple Store')}</a>
            }
            {
              item?.website_link&&
              <a href={item?.website_link} target='_blank' className={styles['our-projects__project-link']}><HomeHeaderWebsiteIcon/> {t('Website')}</a>
            }
          </div>
        </Container>
    </header>
  )
}

export default PortfolioDetailsHeader