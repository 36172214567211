import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import ContactUs from 'components/Home/ContactUs/ContactUs'
import Footer from 'components/Global/Layout/Footer/Footer'
import { useState } from 'react'
import RequestACallPopUp from 'components/Global/Elements/RequestACallPopUp/RequestACallPopUp'
import Loading from 'components/Global/Elements/Loading/Loading'
import SuccessRequestPopup from 'components/Global/Elements/SuccessRequestPopup/SuccessRequestPopup'
import ServicesTransparentHeader from 'components/Services/ServicesTransparentHeader/ServicesTransparentHeader'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './SocialMediaMarketing.module.css'
import socialMediaMarketingImage from 'assets/imgs/socialMediaMarketingImage.png'
import accordionTrueIcon from 'assets/imgs/accordionTrueIcon.png'
function SocialMediaMarketing() {
  const {t} =useTranslation()
  const [isPageLoading,setIsPageLoading]=useState(true)
  const [isSuccessOpen,setIsSuccessOpen]=useState(false)

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
    <motion.div 
      init={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
        <MetaTags>
              <title>Pavilion</title>
        </MetaTags>
        {/* {isPageLoading&&<Loading/>} */}
        {
          !localStorage.getItem('popUpSubmitted')&&
          <RequestACallPopUp setIsSuccessOpen={setIsSuccessOpen}/>
        }
        {isSuccessOpen&&<SuccessRequestPopup/>}
        <section className={styles['seo']}>
          <div className={styles['seo--bg']}></div>
          <ServicesTransparentHeader title={`${t('Social Media Marketing')}`} description={`${t('As one of the top social media agencies in Dubai today that started off as one of the advertising agencies in UAE.')}`}/>

          <section className={styles['seo-info-wrapper']}>
            <Container>
              <Row>
                <Col lg='6'>
                  <div className={styles['seo-info-wrapper__content-wrapper']}>
                    <img src={socialMediaMarketingImage} alt='seo image' className={styles['seo-info-wrapper__image']}/>
                  </div>
                </Col>
                <Col lg='6'>
                  {/* <div className={styles['seo-info-wrapper__content-wrapper']}> */}
                    <h2 className={styles['seo-info-wrapper__title']}>{t('Why Pavilion Technologies.!')}</h2>
                    {/* <p className={styles['seo-info-wrapper__description']}> */}
                      {/* {t("Schedule and automate SMS workflows to improve brand awareness and build customer relationships.")} */}
                    {/* </p>     */}
                  {/* </div> */}
                  <ul className={styles['seo-info__list']}>
                    <li className={styles['seo-info__list-item']}>
                      <h3 className={styles['seo-info__list-item-header']}>
                          <img src={accordionTrueIcon} alt='true icon' className={styles['seo-info__list-item-icon']}/>
                          {t('STRATEGY')}
                      </h3>
                      <p className={styles['seo-info__list-item-description']}>{t('Matching our plan to business goals and setting a target of success')}</p>
                    </li>
                    <li className={styles['seo-info__list-item']}>
                      <h3 className={styles['seo-info__list-item-header']}>
                          <img src={accordionTrueIcon} alt='true icon' className={styles['seo-info__list-item-icon']}/>
                          {t('LISTENING & REPORTING')}
                      </h3>
                      <p className={styles['seo-info__list-item-description']}>{t('Deep analysis of sentiment and perception – with reports that are rich but easy to understand')}</p>
                    </li>
                    <li className={styles['seo-info__list-item']}>
                      <h3 className={styles['seo-info__list-item-header']}>
                          <img src={accordionTrueIcon} alt='true icon' className={styles['seo-info__list-item-icon']}/>
                          {t('INFLUENCER MANAGEMENT')}
                      </h3>
                      <p className={styles['seo-info__list-item-description']}>{t('Matching our plan to business goals and setting a target of success')}</p>
                    </li>
                    <li className={styles['seo-info__list-item']}>
                      <h3 className={styles['seo-info__list-item-header']}>
                          <img src={accordionTrueIcon} alt='true icon' className={styles['seo-info__list-item-icon']}/>
                          {t('CONTENT')}
                      </h3>
                      <p className={styles['seo-info__list-item-description']}>{t('Deep analysis of sentiment and perception – with reports that are rich but easy to understand')}</p>
                    </li>
                    <li className={styles['seo-info__list-item']}>
                      <h3 className={styles['seo-info__list-item-header']}>
                          <img src={accordionTrueIcon} alt='true icon' className={styles['seo-info__list-item-icon']}/>
                          {t('CHANNEL & COMMUNITY MANAGEMENT')}
                      </h3>
                      <p className={styles['seo-info__list-item-description']}>{t('Scheduling and publishing with results in mind – and acting as the first line in customer service.')}</p>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </section>
        </section>

        <ContactUs/>
        <Footer/>
    </motion.div>
    </>
  )
}

export default SocialMediaMarketing