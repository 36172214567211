import React, { useEffect, useRef, useState } from 'react'
import styles from './ChatItem.module.css'
import {ReactComponent as WhatsAppIconFullColor} from 'assets/icons/whatsAppHeaderFullColor.svg'
import {ReactComponent as PhoneIcon} from 'assets/icons/phoneIcon.svg'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { whtsAppConnect } from 'utils/features'
function ChatItem() {
    const buttonRef= useRef(null)
    const {t} =useTranslation()
    const [settings,setSettings]=useState([])
    const selector=useSelector(data=>data.GlobalReducer)
    useEffect(()=>{
        setSettings(selector.generalSettings)
    },[selector])
  return (
    <div className={styles['chat']}>
        <button role='button' onClick={()=>{window.open(`tel:${settings?.contact_phone_number?settings?.contact_phone_number:'00971542172270'}`, '_self')}} className={styles['chat__button-phone']}>
            <span className={styles['navbar__link-more-dot']}></span>
            <PhoneIcon className={styles['chat__button-phone-icon']}/>
        </button>
        <a href={whtsAppConnect(settings?.contact_whats_app_number)} target='_blank' className={styles['chat__button']} ref={buttonRef}>
            <WhatsAppIconFullColor className={styles['chat__button-icon']}/>
        </a>
        {/* <a href={whtsAppConnect(settings?.contact_whats_app_number)} target='_blank' className={styles['chat__button']} ref={buttonRef}>
            <PhoneIcon className={styles['chat__button-icon']}/>
        </a> */}
        {/* <div className={`${styles['chat__content-wrapper']} ${styles['chat__content-wrapper--hidden']} js-chat__content-wrapper`} ref={bodyRef}>
            <div className={styles['chat__header-wrapper']}>
                <WhatsAppIcon/>
                <div className={`${styles['chat_header-content']}`}>
                    <h2 className={styles['chat_header-title']}>{t('start_conversation')}</h2>
                    <p className={styles['chat_header-desc']}>{t('hi_click_one')}</p>
                </div>
            </div>
            <div className={styles['chat__body-wrapper']}>
                <h3 className={styles['chat__body-title']}>{t('the_team_typically')}</h3>
                <div className={styles['chat__body-content-wrapper']}>
                    {
                        whatsAppUser&&whatsAppUser.map((user,index)=>(
                            <a className={styles['chat__body-item']} href={whtsAppConnect(user?.phone)} target='_blank' key={index}>
                                <WhatsAppIconFullColor className={styles['chat__body-icon']}/>
                                <div className={styles['chat__body-content']}>
                                    <h2 className={styles['chat__body-content-title']}>{user?.name}</h2>
                                    <p className={styles['chat__body-content-lang']}>{t('speaks')} : {user?.spoken_languages}</p>
                                </div>
                            </a>
                        ))
                    }
                </div>
            </div>
        </div> */}
    </div>
  )
}

export default ChatItem