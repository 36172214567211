import React from 'react'
import styles from './PortfolioProjectCard.module.css'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {ReactComponent as ProjctRightArrow} from 'assets/icons/projctRightArrow.svg'
import ReactLoading from "react-loading";

function PortfolioProjectCard({project}) {
    const {t} =useTranslation()
    console.log('dasdasdas',project)
  return (
    <div className={styles['card']}>
      <Link to={`/portfolio-details/${project?.id}`} className={styles['card--link']}></Link>
        <div className={styles['card__img']}>
          <div className={`${styles['loading__item']} ${styles['card__img']}`} id={`loading__item_Imgfg${project?.id}`}>
              <ReactLoading type={"spin"} color={'#ffffff'} height={45} width={45} />
          </div>
          <img 
            src={project?.background_image} 
            alt='card img' 
            className={styles['card__img']}
            onLoad={(e)=>document.getElementById(`loading__item_Imgfg${project?.id}`).classList.add('d-none')}
          />
        </div>
        <h3 className={styles['card__category-title']}>{project?.project_category_title}</h3>
        <h2 className={styles['card__title']}>{project?.title} </h2>
        {/* <p className={styles['card__description']}>Technologies used, Technologies usedTechnologies used</p> */}
        <Link to={`/portfolio-details/${project?.id}`} className={styles['card__link']}>
          {t('View Full Case study')}<ProjctRightArrow className={styles['card__icon']}/>
        </Link>
      {/* </Link> */}
    </div>
  )
}

export default PortfolioProjectCard