import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './HeaderWithBG.module.css'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { HeaderWithBGStyled } from './HeaderWithBGStyled'

import {ReactComponent as TwitterIcon} from 'assets/icons/twitterIcon.svg'
import {ReactComponent as InstagramDarkIcon} from 'assets/icons/instagramDarkIcon.svg'
import {ReactComponent as FacebookdarkIcon} from 'assets/icons/facebookdarkIcon.svg'
import {ReactComponent as BehanceDarkIcon} from 'assets/icons/behanceDarkIcon.svg'
import {ReactComponent as LinkedInDarkIcon} from 'assets/icons/linkedInDarkIcon.svg'
import {ReactComponent as InstaDarkIcon} from 'assets/icons/instaDarkIcon.svg'
import {ReactComponent as TiktokDarkIcon} from 'assets/icons/tiktokDarkIcon.svg'
import {ReactComponent as HeaderSliderSettingIcon} from 'assets/icons/headerSliderSettingIcon.svg'
import {ReactComponent as HeaderMessageIcon} from 'assets/icons/headerMessageIcon.svg'
import { useSelector } from 'react-redux'
function HeaderWithBG({title ,description ,image}) {
    const {t} =useTranslation()
    const [settings,setSettings]=useState([])
    const selector=useSelector(data=>data.GlobalReducer)
    useEffect(()=>{
        setSettings(selector.generalSettings)
    },[selector])
 
  return (
      <HeaderWithBGStyled bgSrc={image}>
        {/* <header className={styles['header']}> */}
        <Container className='h-100 container--header d-flex'>
            <Row className='h-100'>
                <Col xl='1' className='d-none d-xl-flex'>
                    <div className={styles['header__social-wrapper']}>
                        {
                            settings?.twitter_link&&
                            <a href={`${settings?.twitter_link}`} target='_blank' className={styles['header__social-button']} data-aos='fade-up' data-aos-once='true' data-aos-delay='100' data-aos-duration='800'>
                                <TwitterIcon className={styles['header__social-button-icon']}/>
                            </a>
                        }
                        {
                            settings?.instagram_link&&
                            <a href={`${settings?.instagram_link}`} target='_blank' className={styles['header__social-button']} data-aos='fade-up' data-aos-once='true' data-aos-delay='200' data-aos-duration='800' >
                                <InstagramDarkIcon className={styles['header__social-button-icon']}/>
                            </a>
                        }
                        {
                            settings?.facebook_link&&
                            <a href={`${settings?.facebook_link}`} target='_blank' className={styles['header__social-button']} data-aos='fade-up' data-aos-once='true' data-aos-delay='300' data-aos-duration='800'>
                                <FacebookdarkIcon className={styles['header__social-button-icon']}/>
                            </a>
                        }
                        {
                            settings?.behance_link&&
                            <a href={`${settings?.behance_link}`} target='_blank' className={styles['header__social-button']} data-aos='fade-up' data-aos-once='true' data-aos-delay='400' data-aos-duration='800'>
                                <BehanceDarkIcon className={styles['header__social-button-icon']}/>
                            </a>
                        }
                        {
                            settings?.linkedin_link&&
                            <a href={`${settings?.linkedin_link}`} target='_blank' className={styles['header__social-button']} data-aos='fade-up' data-aos-once='true' data-aos-delay='500' data-aos-duration='800'>
                                <LinkedInDarkIcon className={styles['header__social-button-icon']}/>
                            </a>
                        }
                        {
                            settings?.tiktok_link&&
                            <a href={`${settings?.tiktok_link}`} target='_blank' className={styles['header__social-button']} data-aos='fade-up' data-aos-once='true' data-aos-delay='600' data-aos-duration='800'>
                                <TiktokDarkIcon className={styles['header__social-button-icon']}/>
                            </a>
                        }
                    </div>
                </Col>
            </Row>
            <Col xl='11' className='h-100 d-flex'>
                <div className='d-flex flex-column justify-content-center align-items-center m-auto pt-5'>
                    <h1 className={styles['header__title']}>{title}</h1>
                    <p className={styles['header__description']}>{description}</p>
                    <Link to='/#contact-us' className={styles['header__contact-button']}>{t('Get free consultation')}</Link>
                </div>
            </Col>
        </Container>
      {/* </header> */}
      </HeaderWithBGStyled>
  )
}

export default HeaderWithBG