import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './Header.module.css'
import {ReactComponent as TwitterIcon} from 'assets/icons/twitterIcon.svg'
import {ReactComponent as InstagramDarkIcon} from 'assets/icons/instagramDarkIcon.svg'
import {ReactComponent as FacebookdarkIcon} from 'assets/icons/facebookdarkIcon.svg'
import {ReactComponent as BehanceDarkIcon} from 'assets/icons/behanceDarkIcon.svg'
import {ReactComponent as LinkedInDarkIcon} from 'assets/icons/linkedInDarkIcon.svg'
import {ReactComponent as InstaDarkIcon} from 'assets/icons/instaDarkIcon.svg'
import {ReactComponent as TiktokDarkIcon} from 'assets/icons/tiktokDarkIcon.svg'
import {ReactComponent as HeaderSliderSettingIcon} from 'assets/icons/headerSliderSettingIcon.svg'
import {ReactComponent as HomeHeaderEcommerceIcon} from 'assets/icons/homeHeaderEcommerceIcon.svg'
import {ReactComponent as HomeHeaderSocialMediaIcon} from 'assets/icons/homeHeaderSocialMediaIcon.svg'
import {ReactComponent as HomeHeaderBrandIdentityIcon} from 'assets/icons/homeHeaderBrandIdentityIcon.svg'
import {ReactComponent as HomeHeaderWebsiteIcon} from 'assets/icons/homeHeaderWebsiteIcon.svg'
import {ReactComponent as HomeHeaderMobileIcon} from 'assets/icons/homeHeaderMobileIcon.svg'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { whtsAppConnect } from 'utils/features'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper";
import "swiper/css/pagination";
import { HashLink } from 'react-router-hash-link'
function Header({categories ,slider}) {
    const {t} =useTranslation()
    const [settings,setSettings]=useState([])
    const selector=useSelector(data=>data.GlobalReducer)
    useEffect(()=>{
        setSettings(selector.generalSettings)
    },[selector])
    useEffect(()=>{
    let imgSliderInterval =setInterval(()=>{
        let imgs = document.querySelectorAll('.js-header__img')
        let changedIndex = 0
        let priviousIndex =1
        imgs.forEach((img,index)=>{
            if(img.classList.contains(styles['header__img--active'])){
                img.classList.remove(styles['header__img--right-position'])
                img.classList.remove(styles['header__img--active'])
                img.classList.add(styles['header__img--left-position'])
                changedIndex =imgs[index+1]?index+1:0
                priviousIndex =index
            }
        })
        imgs[changedIndex].classList.add(styles['header__img--active'])
        setTimeout(()=>{
            imgs[priviousIndex].classList.remove(styles['header__img--left-position'])
            imgs[priviousIndex].classList.add(styles['header__img--right-position'])
        },1000)
    },5000)
    return ()=>clearInterval(imgSliderInterval)
    },[])
  return (
    <header className={styles['header']}>
        <Container className='h-100 container--header'>
            <Row className='h-100 position-relative'>
                <Col xl='1' className='d-none d-xl-flex'>
                    <div className={styles['header__social-wrapper']}>
                        {
                            settings?.twitter_link&&
                            <a href={`${settings?.twitter_link}`} target='_blank' className={styles['header__social-button']} data-aos='fade-up' data-aos-once='true' data-aos-delay='100' data-aos-duration='800'>
                                <TwitterIcon className={styles['header__social-button-icon']}/>
                            </a>
                        }
                        {
                            settings?.instagram_link&&
                            <a href={`${settings?.instagram_link}`} target='_blank' className={styles['header__social-button']} data-aos='fade-up' data-aos-once='true' data-aos-delay='200' data-aos-duration='800' >
                                <InstagramDarkIcon className={styles['header__social-button-icon']}/>
                            </a>
                        }
                        {
                            settings?.facebook_link&&
                            <a href={`${settings?.facebook_link}`} target='_blank' className={styles['header__social-button']} data-aos='fade-up' data-aos-once='true' data-aos-delay='300' data-aos-duration='800'>
                                <FacebookdarkIcon className={styles['header__social-button-icon']}/>
                            </a>
                        }
                        {
                            settings?.behance_link&&
                            <a href={`${settings?.behance_link}`} target='_blank' className={styles['header__social-button']} data-aos='fade-up' data-aos-once='true' data-aos-delay='400' data-aos-duration='800'>
                                <BehanceDarkIcon className={styles['header__social-button-icon']}/>
                            </a>
                        }
                        {
                            settings?.linkedin_link&&
                            <a href={`${settings?.linkedin_link}`} target='_blank' className={styles['header__social-button']} data-aos='fade-up' data-aos-once='true' data-aos-delay='500' data-aos-duration='800'>
                                <LinkedInDarkIcon className={styles['header__social-button-icon']}/>
                            </a>
                        }
                        {
                            settings?.tiktok_link&&
                            <a href={`${settings?.tiktok_link}`} target='_blank' className={styles['header__social-button']} data-aos='fade-up' data-aos-once='true' data-aos-delay='600' data-aos-duration='800'>
                                <TiktokDarkIcon className={styles['header__social-button-icon']}/>
                            </a>
                        }
                    </div>
                </Col>
                <Col xl='6' lg='6' >
                    <div className={styles['header__content-wrapper']}>
                        <h1 className={styles['header__title']} data-aos='fade-up' data-aos-duration='800' data-aos-once='true'>{t('We Design And Innovate To Change The World With Our Ideas....')}</h1>
                        <h2 className={`${styles['header__sub-title']} d-none d-xl-flex`} data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'>{t('The first steps to success for new companies and StartUps, We are specialised to build your Digital world on Internet')}</h2>
                        <div className={styles['header__features-wrapper']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='200' data-aos-once='true'>
                            <button role='button' onClick={()=>{window.open(`tel:${settings?.contact_phone_number?settings?.contact_phone_number:'+971 54 217 2270'}`, '_self')}}  className={styles['header__feature-item']}>{t('Call Now')}</button>
                            <HashLink to='/#contact-us' className={styles['header__feature-item']}>{t('Ask For Consultation')}</HashLink>
                        </div>
                    </div>
                </Col>
                <Col xl='5' lg='6'>
                    <div className={styles['header__img-wrapper']} >
                        {
                            slider &&slider?.map((img,index)=>(
                                <img src={img?.icon} alt='header image' className={`${styles['header__img']} ${index!=0?styles['header__img--right-position']:''} ${index==0?styles['header__img--active']:""} js-header__img`} key={index} />

                            ))
                        }
                         {/* <img src={headerImg2Src} alt='header image' className={`${styles['header__img']} ${styles['header__img--right-position']} js-header__img`}/>
                         <img src={headerImg3Src} alt='header image' className={`${styles['header__img']} ${styles['header__img--right-position']} js-header__img`}/>
                         <img src={headerImg4Src} alt='header image' className={`${styles['header__img']} ${styles['header__img--right-position']} js-header__img`}/>
                         <img src={headerImg5Src} alt='header image' className={`${styles['header__img']} ${styles['header__img--right-position']} js-header__img`}/>
                         <img src={headerImg6Src} alt='header image' className={`${styles['header__img']} ${styles['header__img--right-position']} js-header__img`}/> */}
                    </div>
                </Col>
            <div className={styles['header__contact-icons-wrapper']}>
            {/* <Swiper
                spaceBetween={50}
                slidesPerView={3}
                loop={true}
                autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                }}
                disableOnInteraction= {true} 
                breakpoints={{
                        0: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        426:{
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 40,
                        },
                        1200: {
                            slidesPerView: 2,
                            spaceBetween: 40,
                        },
                        1400: {
                            slidesPerView: 3,
                            spaceBetween: 40,
                        },
                }}
                className={styles['header__contact-icons-slider']}
                data-aos='fade-up' data-aos-duration='800' data-aos-once='true'
                modules={[Autoplay]}

            >
                {
                    categories&&categories.map((category,index)=>(
                        <SwiperSlide  className={styles['swiper-slide']} key={index}>
                            <div className={styles['slider__wrapper']}>
                                <div className={styles['slider__icon-wrapper']}>
                                    <img src={category?.icon} alt='category icon' className={styles['slider__icon']} />
                                    <h2 className={styles['slider__icon-title']}>{category?.title}</h2>
                                </div>
                                <div className={styles['slider__content-wrapper']}>
                                    <h2 className={styles['slider__content-title']}>{category?.title}</h2>
                                    <p className={styles['slider__content-description']}>{category?.description}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))
                }
            </Swiper> */}
            </div>
            </Row>
            <Row className='mb-5'>
                <Col xl='2' lg='3' md='4' className='mx-auto'>
                    <div className={styles['slider__content-service-wrapper']}>
                        <Link to='/services/e-commerce-web-design' className={styles['slider__content-service-wrapper--link']}></Link>
                        <div className={styles['slider__content-service-icon-wrapper']}>
                            <HomeHeaderEcommerceIcon/>
                        </div>
                        <h5 className={styles['slider__content-service-title']}>{t('E-Commerce')}</h5>
                    </div>
                </Col>
                <Col xl='2' lg='3' md='4' className='mx-auto'>
                    <div className={styles['slider__content-service-wrapper']}>
                        <Link to='/services/digital-marketing' className={styles['slider__content-service-wrapper--link']}></Link>
                        <div className={styles['slider__content-service-icon-wrapper']}>
                            <HomeHeaderSocialMediaIcon/>
                        </div>
                        <h5 className={styles['slider__content-service-title']}>{t('Social Media Marketing')}</h5>
                    </div>
                </Col>
                <Col xl='2' lg='3' md='4' className='mx-auto'>
                    <div className={styles['slider__content-service-wrapper']}>
                        <Link to='/services/brand-identity' className={styles['slider__content-service-wrapper--link']}></Link>
                        <div className={styles['slider__content-service-icon-wrapper']}>
                            <HomeHeaderBrandIdentityIcon/>
                        </div>
                        <h5 className={styles['slider__content-service-title']}>{t('Brand Identity')}</h5>
                    </div>
                </Col>
                <Col xl='2' lg='3' md='4' className='mx-auto'>
                    <div className={styles['slider__content-service-wrapper']}>
                        <Link to='/services/web-design' className={styles['slider__content-service-wrapper--link']}></Link>
                        <div className={styles['slider__content-service-icon-wrapper']}>
                            <HomeHeaderWebsiteIcon/>
                        </div>
                        <h5 className={styles['slider__content-service-title']}>{t('Website-Design')}</h5>
                    </div>
                </Col>
                <Col xl='2' lg='3' md='4' className='mx-auto'>
                    <div className={styles['slider__content-service-wrapper']}>
                        <Link to='/services/mobile-applications' className={styles['slider__content-service-wrapper--link']}></Link>
                        <div className={styles['slider__content-service-icon-wrapper']}>
                            <HomeHeaderMobileIcon/>
                        </div>
                        <h5 className={styles['slider__content-service-title']}>{t('Mobile Apps')}</h5>
                    </div>
                </Col>
            </Row>
        </Container>
    </header>
  )
}

export default Header