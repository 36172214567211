import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import ContactUs from 'components/Home/ContactUs/ContactUs'
import Footer from 'components/Global/Layout/Footer/Footer'
import { useState } from 'react'
import RequestACallPopUp from 'components/Global/Elements/RequestACallPopUp/RequestACallPopUp'
import Loading from 'components/Global/Elements/Loading/Loading'
import SuccessRequestPopup from 'components/Global/Elements/SuccessRequestPopup/SuccessRequestPopup'
import ServicesTransparentHeader from 'components/Services/ServicesTransparentHeader/ServicesTransparentHeader'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './WebDesign.module.css'
import webDesignConsultayionIcon from 'assets/imgs/webDesignConsultayionIcon.png'
import webDesignCollabration from 'assets/imgs/webDesignCollabration.png'
import webDesignFinalDelivery from 'assets/imgs/webDesignFinalDelivery.png'

import AttractingTargetIcon from 'assets/imgs/AttractingTargetIcon.png'
import AttractiveUserExperinceIcon from 'assets/imgs/AttractiveUserExperinceIcon.png'
import createBrandIdentityIcon2 from 'assets/imgs/createBrandIdentityIcon2.png'
import createBrandIdentityIcon from 'assets/imgs/createBrandIdentityIcon.png'
import websiteDevelopmentBG from 'assets/imgs/websiteDevelopmentBG.jpg'
import webDesignImage from 'assets/imgs/webDesignImage.png'
import webDesignImage2 from 'assets/imgs/webDesignImage2.png'
import seoLensImage from 'assets/imgs/seoLensImage.png'
import ContactUsHeader from 'components/ContactUs/ContactUsHeader/ContactUsHeader'
import HeaderWithBG from 'components/Global/Elements/HeaderWithBG/HeaderWithBG'
import OurProjects from 'components/Home/OurProjects/OurProjects'
function WebDesign() {
  const {t} =useTranslation()
  const [isPageLoading,setIsPageLoading]=useState(true)
  const [isSuccessOpen,setIsSuccessOpen]=useState(false)
  const [activeApproachItem,setActiveApproachItem]=useState(1)
  const [websiteApproachsSteps,setWebsiteApproachsSteps]=useState([
    {id:1,title:t('Research and discovery'),description:t('We conduct comprehensive research to understand your needs, the market and the competition, which helps us determine the optimal design direction.')},
    {id:2,title:t('Deigning UI UX'),description:t("We focus designing good user experience in  order to guarantee simple and attractive browsing journey, which mark your website on the competitor's .")},
    {id:3,title:t('Content Creation'),description:t('We write the content that meets your needs and SEO, with special interest of key words.')},
    {id:4,title:t('Front End & Back End'),description:t('We build your website carefully, with caring of technical sides and guarantee that there are no problems with user interfaces')},
    {id:5,title:t('Testing'),description:t('We test the project with going through all the processes to make sure there are no issues before going life')},
    {id:6,title:t('Delivering'),description:t('After the website is done and it passes all the tests, we deliver it to the client and upload it to be online after we take client approval.')},
  ])

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
    <motion.div 
      init={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
        <MetaTags>
              <title>Pavilion</title>
        </MetaTags>
        {/* {isPageLoading&&<Loading/>} */}
        {
          !localStorage.getItem('popUpSubmitted')&&
          <RequestACallPopUp setIsSuccessOpen={setIsSuccessOpen}/>
        }
        {isSuccessOpen&&<SuccessRequestPopup/>}
        
        <section className={styles['seo']}>
          {/* <div className={styles['seo--bg']}></div> */}

          <HeaderWithBG 
            title={`${t('Website Development')}`} 
            description={`${t('Get ready for your digital success with an exceptional website design that inspires visitors and converts them into loyal customers.')}`}
            image={websiteDevelopmentBG}
          />

          <section className={styles['seo-info-wrapper']}>
            <Container >
              <Row className='mb-5'>
                <Col lg='6' className='d-flex aling-items-center '>
                  <h2 className={styles['seo-info-wrapper__title']}>{t('Why is website design important?')}</h2>
                </Col>
                <Col lg='6'>
                  <p className={styles['seo-info-wrapper__description']}>
                    {t("Have you ever visited a website and left it immediately due to its bad design and uncomfortable browsing experience? In fact, the user needs less than 5 seconds to form his opinion about the site and make his decision to continue browsing or leave. You must make sure that your site looks modern and up-to-date as this reflects the credibility of your business. In addition, if the user does not find your contact details and social media links, he may doubt the credibility of your site.")}
                  </p>
                </Col>
              </Row>
              <p className={styles['seo-info-wrapper__description']}>
                {t("So, website design is crucial to maintaining a user presence within the website. Website design elements that achieve this goal include attractive graphic design, smooth user experience, search engine optimization, as well as quality content creation.We are here to help you design an exceptional website that combines aesthetics, functionality, and outstanding user experience. Our experienced team will work hard to understand your vision and achieve your goals through innovative and attractive website design. Contact us today and let's get started building your digital presence and success online.")}
              </p>
            </Container>
          </section>

          <section className={styles['seo-details-wrapper']}>
            <Container>
                <h2 className={styles['seo-details-wrapper__title']}>{t('The importance of good website design')}</h2>
              <Row>
                <Col lg='3' md='6' className='mb-3'>
                  <img src={AttractingTargetIcon} className={styles['seo-details-wrapper__item-image']} alt='mobile apps'/>
                  <h3 className={styles['seo-details-wrapper__item-title']}>{t('Attracting the target audience')}</h3>
                  <p className={styles['seo-details-wrapper__item-description']}>{t('The attractive and innovative design of the website helps attract the attention and interest of the target audience, which increases the chances of interaction and conversion into actual customers.')}</p>
                </Col>
                <Col lg='3' md='6' className='mb-3'>
                  <img src={AttractiveUserExperinceIcon} className={styles['seo-details-wrapper__item-image']} alt='mobile apps'/>
                  <h3 className={styles['seo-details-wrapper__item-title']}>{t('Attractive user experience')}</h3>
                  <p className={styles['seo-details-wrapper__item-description']}>{t('The excellent design creates a comfortable and enjoyable user journey, as it facilitates browsing and finding the required information easily. This helps improve user experience and increases chances of conversion and engagement.')}</p>
                </Col>
                <Col lg='3' md='6' className='mb-3'>
                  <img src={createBrandIdentityIcon} className={styles['seo-details-wrapper__item-image']} alt='mobile apps'/>
                  <h3 className={styles['seo-details-wrapper__item-title']}>{t('Create a brand identity')}</h3>
                  <p className={styles['seo-details-wrapper__item-description']}>{t('Your site contributes to strengthening the brand identity and conveying a strong and professional message. The website can highlight unique selling points and brand values, which enhances interaction and trust among visitors and customers.')}</p>
                </Col>
                <Col lg='3' md='6' className='mb-3'>
                  <img src={createBrandIdentityIcon2} className={styles['seo-details-wrapper__item-image']} alt='mobile apps'/>
                  <h3 className={styles['seo-details-wrapper__item-title']}>{t('Create a brand identity')}</h3>
                  <p className={styles['seo-details-wrapper__item-description']}>{t('Your site contributes to strengthening the brand identity and conveying a strong and professional message. The website can highlight unique selling points and brand values, which enhances interaction and trust among visitors and customers.')}</p>
                </Col>
              </Row>
            </Container>
          </section>

          <section className={styles['website__approach']}>
            <Container>
              <h2 className={styles['website__approach-title']}>{t('We take a unique approach to designing your website that includes the following steps:')}</h2>
              <div className={styles['website__approach-list-wrapper']}>
                <ul className={styles['website__approach-list']}>
                  <li className={styles['website__approach-list-item']}>
                    <button className={`${styles['website__approach-list-button']} ${activeApproachItem==1&& styles['website__approach-list-button--active']}`}
                    onClick={()=>setActiveApproachItem(1)}>
                      01
                      <span className={styles['website__approach-list-button-bullet']}></span>
                    </button>
                  </li>
                  <li className={styles['website__approach-list-item']}>
                    <button className={`${styles['website__approach-list-button']} ${activeApproachItem==2&& styles['website__approach-list-button--active']}`}
                    onClick={()=>setActiveApproachItem(2)}>
                      02
                      <span className={styles['website__approach-list-button-bullet']}></span>
                    </button>
                  </li>
                  <li className={styles['website__approach-list-item']}>
                    <button className={`${styles['website__approach-list-button']} ${activeApproachItem==3&& styles['website__approach-list-button--active']}`}
                    onClick={()=>setActiveApproachItem(3)}>
                      03
                      <span className={styles['website__approach-list-button-bullet']}></span>
                    </button>
                  </li>
                  <li className={styles['website__approach-list-item']}>
                    <button className={`${styles['website__approach-list-button']} ${activeApproachItem==4&& styles['website__approach-list-button--active']}`}
                    onClick={()=>setActiveApproachItem(4)}>
                      04
                      <span className={styles['website__approach-list-button-bullet']}></span>
                    </button>
                  </li>
                  <li className={styles['website__approach-list-item']}>
                    <button className={`${styles['website__approach-list-button']} ${activeApproachItem==5&& styles['website__approach-list-button--active']}`}
                    onClick={()=>setActiveApproachItem(5)}>
                      05
                      <span className={styles['website__approach-list-button-bullet']}></span>
                    </button>
                  </li>
                  <li className={styles['website__approach-list-item']}>
                    <button className={`${styles['website__approach-list-button']} ${activeApproachItem==6&& styles['website__approach-list-button--active']}`}
                    onClick={()=>setActiveApproachItem(6)}>
                      06
                      <span className={styles['website__approach-list-button-bullet']}></span>
                    </button>
                  </li>
                </ul>
                <div>
                  {
                    websiteApproachsSteps && websiteApproachsSteps?.map((step,index)=>(
                    <p className={`${styles['website__approach-description']} ${activeApproachItem==index+1 &&styles['website__approach-description--active']}`} key={step?.id}>
                      <span className={styles['website__approach-description--title']}>{step?.title}</span>
                      {step?.description}
                    </p>
                    ))
                  }
                </div>
              </div>
            </Container>
          </section>
          {/* <section className={styles['seo-details-wrapper']}>
            <Container>
                <h2 className={styles['seo-details-wrapper__image-title']}>{t('For What?')}</h2>
              <Row>
                <Col lg='4' className='mb-3'>
                  <h3 className={styles['seo-details-wrapper__item-image-title']}>{t('Mobile apps')}</h3>
                </Col>
                <Col lg='4' className='mb-3'>
                  <img src={contentCreationSocialApps} className={styles['seo-details-wrapper__item-image']} alt='mobile apps'/>
                  <h3 className={styles['seo-details-wrapper__item-image-title']}>{t('Social Media')}</h3>
                </Col>
                <Col lg='4' className='mb-3'>
                  <img src={contentCreationWebDesign} className={styles['seo-details-wrapper__item-image']} alt='mobile apps'/>
                  <h3 className={styles['seo-details-wrapper__item-image-title']}>{t('Web Design')}</h3>
                </Col>
              </Row>
            </Container>
          </section> */}
        </section>
        <OurProjects/>

        <ContactUs/>
        <Footer/>
    </motion.div>
    </>
  )
}

export default WebDesign