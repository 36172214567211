import React, { useState } from 'react';
import { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './SuccessRequestPopup.module.css'
import RequestSendHero from 'assets/imgs/requestSendHero.png'
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
function SuccessRequestPopup() {
  const {t} =useTranslation()
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(()=>{
    if (show) {
        document.querySelector('html').classList.add('overflow-hidden')
    } else {
        document.querySelector('html').classList.remove('overflow-hidden')
    }
  },[show])

  useEffect(()=>{
    document.querySelector('html').classList.add('overflow-hidden')
    handleShow()
  },[])
  return (
    <>
      <Modal show={show} onHide={handleClose} size='xl' centered>
        <div className={styles['modal__request']}>
            <Container >
                <button onClick={handleClose} className={styles['modal__close-button']}>
                    {t('Close')}
                    {/* <ModalCloseButton className={styles['modal__close-button-icon']}/> */}
                </button>
                <img src={RequestSendHero} className={styles['modal__img']} alt='hero image'/>
                <div>
                    <h1 className={styles['modal__title']}>
                        {t('Thank You')}
                    </h1>
                    <p className={styles['modal__description']}>{t('Your Request Has Been Submitted Successfully, We Will Contact You As Soon As possible')}</p>
                </div>
                
            </Container>
        </div>
        </Modal>
    </>
  )
}

export default SuccessRequestPopup