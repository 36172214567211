import React, { useState } from 'react';
import styles from './OurProjectCard.module.css'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
function OurProjectCard({category}) {
  const {t} =useTranslation()

  return (
    <>
        <Link to={`${category?.link}`} data-aos='fade-up' data-aos-once='true' data-aos-delay='100' data-aos-duration='800'>
            <div className={styles['what-we-offer__card']} >
                <div className={styles['what-we-offer__card-icon-wrapper']}>
                    <img src={category?.icon} alt='category icon' className={styles['what-we-offer__card-icon']} />
                </div>
                <div>
                    <h3 className={styles['what-we-offer__card-title']}>{category?.title}</h3>
                    <ul>
                        {
                            category?.features&&category?.features.map((feature,indexx)=>(
                                // <li className={styles['what-we-offer__card-description']} key={indexx}>- {feature?.feature}</li>
                                <li className={styles['what-we-offer__card-description']} key={indexx}>- {feature}</li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </Link>
    </>
  )
}

export default OurProjectCard