import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './BlogsHeader.module.css'
import { useTranslation } from 'react-i18next'
function BlogsHeader() {
    const {t} =useTranslation()
   
  return (
    <header className={styles['header']}>
        <Container className='h-100 container--header d-flex align-items-center justify-content-center'>
            <h1 className={styles['header__title']}>{t('Blogs')}</h1>
        </Container>
    </header>
  )
}

export default BlogsHeader