import React from 'react'
import { Container, Row } from 'react-bootstrap'
import styles from './ServicesTransparentHeader.module.css'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

function ServicesTransparentHeader({title , description}) {
    const {t} =useTranslation()
 
  return (
    <header className={styles['header']}>
        <Container className='h-100 container--header'>
            <div className='d-flex flex-column justify-content-center align-items-center'>
                <h1 className={styles['header__title']}>{title}</h1>
                <p className={styles['header__description']}>{description}</p>
                <Link to='/contact-us' className={styles['header__contact-button']}>{t('Get free consultation')}</Link>
            </div>
        </Container>
    </header>
  )
}

export default ServicesTransparentHeader