import React, { useEffect, useRef, useState } from 'react'
import { Container } from 'react-bootstrap'
import styles from './OurProjects.module.css'
import {ReactComponent as MoreAboutIcon} from 'assets/icons/MoreAboutIcon.svg'
import {ReactComponent as AndroidIcon} from 'assets/icons/androidIcon.svg'
import {ReactComponent as AppleIcon} from 'assets/icons/appleIcon.svg'
import project1 from 'assets/imgs/project1.jpg'
import './OurProjects.css'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination ,Autoplay} from "swiper";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import { ProjectDiv } from './style'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css';
import ReactLoading from "react-loading";
import { useSelector } from 'react-redux'
function OurProjects() {
    const {t} =useTranslation()
    const projectsRef = useRef(null)
    const selector=useSelector(data=>data.GlobalReducer)
    const [projects ,setProjects] =useState([])
    const [projectCategories ,setProjectCategories] =useState([])
    const [filteredProjects ,setFilteredProjects] = useState([])
    const [activeCategory ,setActiveCategory] =useState('all')
    function filterProjects(id){
        setActiveCategory(id)
        projectsRef.current.classList.add(styles['our-projects--filter-active'])
        setTimeout(()=>{
            projectsRef.current.classList.remove(styles['our-projects--filter-active'])
        },1000)
        let filteredPojectsData=[]
        projects?.forEach(project=>{
            if(project?.project_category_id == id){
                filteredPojectsData.push(project)
            }
        })
        setFilteredProjects(filteredPojectsData)
    }
    function filterAllProjects(){
        setActiveCategory('all')
        projectsRef.current.classList.add(styles['our-projects--filter-active'])
        setTimeout(()=>{
            projectsRef.current.classList.remove(styles['our-projects--filter-active'])
        },1000)
        setFilteredProjects(projects);
    }
    function filterMobileDevice(event){
        if(event.target.value=='all'){
            filterAllProjects()
        }else{
            filterProjects(event.target.value)
        }
    }
    useEffect(()=>{
        setFilteredProjects(selector?.projects)
        setProjects(selector?.projects)
        setProjectCategories(selector?.projectCategories)
    },[selector])
  return (
    <section className={styles['our-projects']} id='portfolio'>
        <Container>
            <h2 className={styles['our-projects__title']} data-aos='fade-up' data-aos-once='true' data-aos-delay='100' data-aos-duration='800'>{t('Our Projects')}</h2>
            <ul className={styles['our-projects__list']} data-aos='fade-up' data-aos-once='true' data-aos-delay='200' data-aos-duration='800'>
                <li className={styles['our-projects__item']}>
                    <button onClick={()=>{filterAllProjects()}}
                    className={`${styles['our-projects__item-button']} 
                    ${activeCategory =='all'?styles['our-projects__item-button--active']:''}`}>{t('All')}</button>
                </li>
                {
                    projectCategories &&projectCategories?.map(category=>(
                        <li className={styles['our-projects__item']} key={category?.id}>
                            <button onClick={()=>filterProjects(category?.id)}
                                className={`${styles['our-projects__item-button']} 
                                ${activeCategory ==category?.id?styles['our-projects__item-button--active']:''}`}>{category?.title}</button>
                        </li>
                    ))
                }
            </ul>
            <select className={styles['our-projects__list-select']} onChange={(e)=>{filterMobileDevice(e)}}>
                <option value={'all'}>{t('All')}</option>
                {
                    projectCategories &&projectCategories?.map(category=>(
                        <option value={category?.id}
                        
                        key={category?.id}>
                            {category?.title}
                            {/* <button onClick={()=>filterProjects(category?.id)}
                                className={`${styles['our-projects__item-button']} 
                                ${activeCategory ==category?.id?styles['our-projects__item-button--active']:''}`}>{category?.title}</button> */}
                        </option>
                    ))
                }
            </select>
            <div ref={projectsRef} className={styles['our-projects__filter']}>
                <Swiper
                    spaceBetween={50}
                    slidesPerView={4}
                    // ref={swiperRef}
                    loop={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true,
                    }}
                    pagination={{
                        dynamicBullets: true,
                    }}
                    breakpoints={{
                        0: {
                            slidesPerView:1,
                            spaceBetween: 20,
                        },
                        450:{
                            slidesPerView:2,
                            spaceBetween: 20,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 40,
                        },
                        1200: {
                            slidesPerView: 4,
                            spaceBetween: 50,
                        },
                }}
                    modules={[Autoplay, Pagination]}
                    className={`${styles['our-projects__swiper']} our-projects__swiper`}
                    data-aos='fade-up' data-aos-duration='800' data-aos-delay='300' data-aos-once='true'
                >
                    {
                        filteredProjects&&filteredProjects.map((project,index)=>(
                            <SwiperSlide key={index}>
                                {/* <ProjectDiv className={'header'} bg={headerImg}></ProjectDiv> */}
                                <ProjectDiv className={'our-projects__project-wrapper'} bg={`${project?.color}`}>
                                    <LazyLoadImage
                                        className={`our-projects__project-img loading-img`}
                                        src={project?.background_image}
                                        effect="normal"
                                        alt='category item'
                                        />
                                        <div className='loading-div'>
                                            <ReactLoading type={"spin"} color={'#ffffff'} height={45} width={45} />
                                        </div>
                                    {/* <img src={project?.background_image} alt ='project' className={'our-projects__project-img'}/> */}
                                    <div className={'our-projects__project-content-wrapper'}>
                                        <div className={'our-projects__project-links-wrapper'}>
                                            {
                                                project?.google_play_link&&
                                                <a href={project?.google_play_link} target='_blank' className={'our-projects__project-link'}><AndroidIcon/> {t('Google Play')}</a>
                                            }
                                            {
                                                project?.app_stote_link&&
                                                <a href={project?.app_stote_link} target='_blank'  className={'our-projects__project-link'}><AppleIcon/> {t('Apple Store')}</a>
                                            }
                                            {/* <Link to={project?.website_link} className={'our-projects__project-link'}><AndroidIcon/> Google Play</Link> */}
                                            {/* <Link to={project?.website_link} className={'our-projects__project-link'}><AndroidIcon/> Google Play</Link> */}
                                            {/* <Link to={project?.ss}>Website</Link> */}
                                            {/* <Link to={project?.ss}>Website</Link> */}
                                        </div>
                                        <h3 className={'our-projects__project-content-title'}>{project?.title}</h3>
                                        {project?.website_link && <a href={project?.website_link} target='_blank' className={'our-projects__project-content-more'}>{t('More About')} <MoreAboutIcon/></a>}
                                    </div>
                                </ProjectDiv>
                            </SwiperSlide>
                        ))
                    }
                </Swiper> 
            </div>
        </Container>
    </section>
  )
}

export default OurProjects