import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import ContactUs from 'components/Home/ContactUs/ContactUs'
import Footer from 'components/Global/Layout/Footer/Footer'
import { useState } from 'react'
import RequestACallPopUp from 'components/Global/Elements/RequestACallPopUp/RequestACallPopUp'
import Loading from 'components/Global/Elements/Loading/Loading'
import SuccessRequestPopup from 'components/Global/Elements/SuccessRequestPopup/SuccessRequestPopup'
import ServicesTransparentHeader from 'components/Services/ServicesTransparentHeader/ServicesTransparentHeader'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './SMS.module.css'
import smsImage from 'assets/imgs/smsImage.png'
import seoLocalImage from 'assets/imgs/seoLocalImage.png'
import seoAnalysisImage from 'assets/imgs/seoAnalysisImage.png'
import seoBackLine from 'assets/imgs/seoBackLine.png'
import seoLensImage from 'assets/imgs/seoLensImage.png'
function SMS() {
  const {t} =useTranslation()
  const [isPageLoading,setIsPageLoading]=useState(true)
  const [isSuccessOpen,setIsSuccessOpen]=useState(false)

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
    <motion.div 
      init={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
        <MetaTags>
              <title>Pavilion</title>
        </MetaTags>
        {/* {isPageLoading&&<Loading/>} */}
        {
          !localStorage.getItem('popUpSubmitted')&&
          <RequestACallPopUp setIsSuccessOpen={setIsSuccessOpen}/>
        }
        {isSuccessOpen&&<SuccessRequestPopup/>}
        <section className={styles['seo']}>
          <div className={styles['seo--bg']}></div>
          <ServicesTransparentHeader title={`${t('SMS')}`} description={`${t('Do you want to level up your marketing with animation? Elevate your creative content with the most affordable motion graphics solution on the market.')}`}/>

          <section className={styles['seo-info-wrapper']}>
            <Container>
              <Row>
                <Col lg='6'>
                  <img src={smsImage} alt='seo image' className={styles['seo-info-wrapper__image']}/>
                </Col>
                <Col lg='6'>
                  <div className={styles['seo-info-wrapper__content-wrapper']}>
                    <h2 className={styles['seo-info-wrapper__title']}>{t('SMS for customer communication..')}</h2>
                    <p className={styles['seo-info-wrapper__description']}>
                      {t("Schedule and automate SMS workflows to improve brand awareness and build customer relationships.")}
                    </p>    
                  </div>
                  <ul className={styles['seo-info__list']}>
                    <li className={styles['seo-info__list-item']}>
                      {t('SMS marketing and staggered campaigns')}
                    </li>
                    <li className={styles['seo-info__list-item']}>
                      {t('Automated SMS and keyword triggers')}
                    </li>
                    <li className={styles['seo-info__list-item']}>
                      {t('Scheduled text campaigns')}
                    </li>
                    <li className={styles['seo-info__list-item']}>
                      {t('Contact lists updated with opt-ins')}
                    </li>
                    <li className={styles['seo-info__list-item']}>
                      {t('Opt-out manager for compliance')}
                    </li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </section>

          {/* <section className={styles['seo-solution-wrapper']}>
            <Container>
              <h3 className={styles['seo-solution__title']}>{t('Which SEO solution is fit for your business?')}</h3>
              <div className={styles['seo-solution__items-wrapper']}>
                <img src={seoLensImage} alt='seo lens image' className={styles['seo-solution__lens-image']}/>
                <div className={styles['seo-solution__item-wrapper']}>
                  <img src={seoLocalImage} alt='seo local image' className={styles['seo-solution__item-image']}/>
                  <h4 className={styles['seo-solution__item-title']}>{t('Local SEO')}</h4>
                </div>
                <div className={styles['seo-solution__item-wrapper']}>
                  <img src={seoAnalysisImage} alt='seo local image' className={styles['seo-solution__item-image']}/>
                  <h4 className={styles['seo-solution__item-title']}>{t('SEO Analytics')}</h4>
                </div>
                <div className={styles['seo-solution__item-wrapper']}>
                  <img src={seoBackLine} alt='seo local image' className={styles['seo-solution__item-image']}/>
                  <h4 className={styles['seo-solution__item-title']}>{t('BackLinks')}</h4>
                </div>
              </div>
            </Container>
          </section> */}
        </section>

        <ContactUs/>
        <Footer/>
    </motion.div>
    </>
  )
}

export default SMS