import React from 'react'
import { useTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import ContactUs from 'components/Home/ContactUs/ContactUs'
import Footer from 'components/Global/Layout/Footer/Footer'
import { useState } from 'react'
import RequestACallPopUp from 'components/Global/Elements/RequestACallPopUp/RequestACallPopUp'
import Loading from 'components/Global/Elements/Loading/Loading'
import SuccessRequestPopup from 'components/Global/Elements/SuccessRequestPopup/SuccessRequestPopup'
import HeaderAboutUs from 'components/AboutUs/HeaderAboutUs/HeaderAboutUs'
import WhatOurClientsSay from 'components/Home/WhatOurClientsSay/WhatOurClientsSay'
import AboutUsSections from 'components/AboutUs/AboutUsSections/AboutUsSections'
function AboutUsPage() {
  const {t} =useTranslation()
  const [isSuccessOpen,setIsSuccessOpen]=useState(false)

  return (
    <>
    <motion.div 
      init={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
        <MetaTags>
              <title>Pavilion</title>
        </MetaTags>
        {/* {isPageLoading&&<Loading/>} */}
        {
          !localStorage.getItem('popUpSubmitted')&&
          <RequestACallPopUp setIsSuccessOpen={setIsSuccessOpen}/>
        }
        {isSuccessOpen&&<SuccessRequestPopup/>}
        <HeaderAboutUs/>
        <WhatOurClientsSay/>
        <AboutUsSections  />
        <ContactUs/>
        <Footer/>
    </motion.div>
    </>
  )
}

export default AboutUsPage