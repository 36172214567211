import React,{ useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence } from "framer-motion";
import { ToastContainer } from 'react-toastify';
import Home from "views/Home/Home";
import NavBar from 'components/Global/Layout/NavBar/NavBar';
import SideBar from 'components/Global/Layout/SideBar/SideBar';
import ErrorPage from 'views/ErrorPage/ErrorPage';
import { axiosConfig } from 'utils/axiosConfig';
import NavigateToTop from 'components/Global/Elements/NavigateToTop/NavigateToTop';
import { changeCurrentCountryCodeAction, changeGeneralSettingsAction, changeOurClientsAction, changeProjectCategoriessAction, changeProjectsAction, changeServicesAction } from 'reduxStore/Global/GlobalActions';
import { useDispatch } from 'react-redux';
import ChatItem from 'components/Global/Elements/ChatItem/ChatItem';
import AboutUsPage from 'views/AboutUs/AboutUs';
import Services from 'views/Services/Services';
import Portfolio from 'views/Portfolio/Portfolio';
import PortfolioDetails from 'views/PortfolioDetails/PortfolioDetails';
import ContactUsPage from 'views/ContactUs/ContactUs';
import Blogs from 'views/Blogs/Blogs';
import Blog from 'views/Blog/Blog';
import SEO from 'views/ServicesPages/SEO/SEO';
import Cookies from 'js-cookie';
import axios from 'axios';
import SMS from 'views/ServicesPages/SMS/SMS';
import VideoCreation from 'views/ServicesPages/VideoCreation/VideoCreation';
import MotionGraphic from 'views/ServicesPages/MotionGraphic/MotionGraphic';
import ContentCreation from 'views/ServicesPages/ContentCreation/ContentCreation';
import ManageAllYourAds from 'views/ServicesPages/ManageAllYourAds/ManageAllYourAds';
import WebDesign from 'views/ServicesPages/WebDesign/WebDesign';
import MobileApplications from 'views/ServicesPages/MobileApplications/MobileApplications';
import SocialMediaMarketing from 'views/ServicesPages/SocialMediaMarketing/SocialMediaMarketing';
import BrandIdentity from 'views/ServicesPages/BrandIdentity/BrandIdentity';
import EcommerceWebDesign from 'views/ServicesPages/EcommerceWebDesign/EcommerceWebDesign';
import WebHosting from 'views/ServicesPages/WebHosting/WebHosting';
import GraphicDesign from 'views/ServicesPages/GraphicDesign/GraphicDesign';
import SuccessPage from 'views/SuccessPage/SuccessPage';
import DigitalMarketing from 'views/ServicesPages/DigitalMarketing/DigitalMarketing';
function AnimatedRoutes() {
    const [isSideBarVisible , setIsSideBarVisible] =useState(false)
    const [isMainNavBarWillApear,setIsMainNavBarWillApear] =useState(false)
    const [isRouteExist,setIsRouteExist] =useState(false)
    const [isPageHeaerWithPhoto,setIsPageHeaerWithPhoto] =useState(false)
    const [isNavbarHide,setIsNavbarHide] =useState(false)
    const [isNavbarFixed,setIsNavbarFixed] =useState(false)
    const [isLoadingVisible,setIsLoadingVisible]=useState(false)

    const dispatch= useDispatch()
    const location = useLocation()
    let toggleSideNavBar =(type)=>[
      setIsSideBarVisible(type==='open')
    ]
      const pagesHeaderWithPhoto = ['contact-us']
      const websiteRoutes = ['blogs','contact-us','digital-marketing','e-commerce-web-design','web-design','mobile-applications','brand-identity']
      useEffect(()=>{
        // setIsMainNavBarWillApear( location.pathname.split('/').some(path=>pagesWithoutNavbar.includes(path)))
        // if(location.pathname=='/'){
        //   setIsRouteExist( true )
        // }else{
        //   setIsRouteExist( location.pathname.split('/').some(path=>websiteRoutes.includes(path)) )
        // }
        setIsPageHeaerWithPhoto( location.pathname.split('/').some(path=>pagesHeaderWithPhoto.includes(path)) )
        setIsRouteExist( location.pathname.split('/').some(path=>websiteRoutes.includes(path)) )
      },[location]) 

      function getGeneralSettings(){
          axiosConfig.get(`/settings`,{
            headers: {
                  "Accept-Language": `${Cookies.get('i18next')=='br'?'en':'ar'}`
              }
          }).then(res=>{
            localStorage.setItem('settings',JSON.stringify(res.data?.data))
            dispatch(changeGeneralSettingsAction(res.data.data))
          }).catch(err=>{
              console.log(err)
          })
      }
      async function getAllServices(){
        await axiosConfig.get(`/services/all-services`,{
          headers: {
              "Accept-Language": `${Cookies.get('i18next')=='br'?'en':'ar'}`
            }
        }).then(res=>{
            localStorage.setItem('services',JSON.stringify(res.data?.data))
            dispatch(changeServicesAction(res.data.data))
          }).catch(err=>{
              console.log(err)
          })
      }
      function getIp(){
        axios.get(`https://api.ipify.org?format=json`).then(res=>{
          axios.get(`https://ipapi.co/${res.data.ip}/country_calling_code`).then(resss=>{
            // setCurrentCountryCode(resss.data)
            localStorage.setItem('currentCountryCode',resss.data)
            dispatch(changeCurrentCountryCodeAction(resss.data))
          })
        }).catch(err=>{
            console.log(err)
        })
      }
      async function getAllProjects(){
          await axiosConfig.get(`/projects/all-projects`,{
            headers: {
                  "Accept-Language": `${Cookies.get('i18next')=='br'?'en':'ar'}`
              }
          }).then(res=>{
            localStorage.setItem('projects',JSON.stringify(res.data?.data))
            dispatch(changeProjectsAction(res.data.data))
            // setProjects(res.data.data)
          }).catch(err=>{
              console.log(err)
          })
      }
      async function getAllClients(){
          await axiosConfig.get(`/our-clients/all-clients`,{
            headers: {
                  "Accept-Language": `${Cookies.get('i18next')=='br'?'en':'ar'}`
              }
          }).then(res=>{
            localStorage.setItem('clients',JSON.stringify(res.data?.data))
            dispatch(changeOurClientsAction(res.data.data))
            // setProjects(res.data.data)
          }).catch(err=>{
              console.log(err)
          })
      }
      async function getAllProjectCategories(){
          await axiosConfig.get(`/project-category/all-project-categories`,{
            headers: {
                  "Accept-Language": `${Cookies.get('i18next')=='br'?'en':'ar'}`
              }
          }).then(res=>{
          localStorage.setItem('projectCategories',JSON.stringify(res.data?.data))
            dispatch(changeProjectCategoriessAction(res.data.data))
            // setProjectCategories(res.data.data)
          }).catch(err=>{
              console.log(err)
          })
      }
      useEffect(()=>{
        window.scrollTo(0,0)
        async function promiseAll(){
          await Promise.all([
            getGeneralSettings(),
            getAllServices(),
            getAllProjectCategories(),
            getAllProjects(),
            getAllClients(),
            getIp()
          ])
        }
        promiseAll()
      },[])
      useEffect(()=>{
        window.scrollTo(0,0)
      },[location])


      useEffect(()=>{
        let load = document.addEventListener('load',()=>{
          setIsLoadingVisible(true)
        })
        return ()=>document.removeEventListener('load',load)
      },[])
      useEffect(()=>{
        toggleSideNavBar('close')
      },[location])
  return (
    <>
      {/* {isLoadingVisible&&<Loading/>} */}
      <NavigateToTop/>
      <NavBar 
        toggleSideNavBar={()=>{toggleSideNavBar('open')}} 
        // isSidePage={isMainNavBarWillApear} 
        isRouteExist={isRouteExist}
        // isPageHeaerWithPhoto={isPageHeaerWithPhoto}
        // isNavbarFixed={isNavbarFixed} 
        // isNavbarHide={isNavbarHide}
        // brands={brands}
        // types={types}
      />
      <SideBar 
        isSideBarVisible={isSideBarVisible} 
        toggleSideNavBar={()=>{toggleSideNavBar('close')}}
        // types={types}
        // brands={brands}
      />
      <ChatItem/>
      <div className="App">
        <ToastContainer />
        <AnimatePresence>
          <Routes location={location} key={location.pathname}>
              <Route path='/' exact element={<Home/>}></Route>
              <Route path='/success-message' element={<SuccessPage/>}></Route>
              <Route path='/about-us' element={<AboutUsPage/>}></Route>
              <Route path='/services' element={<Services/>}></Route>
              <Route path='/contact-us' element={<ContactUsPage/>}></Route>
              <Route path='/blogs' element={<Blogs/>}></Route>
              <Route path='/blog/:slug' element={<Blog/>}></Route>
              <Route path='/portfolio' element={<Portfolio/>}></Route>
              <Route path='/portfolio-details/:id' element={<PortfolioDetails/>}></Route>
              
              <Route path="/services" >
                <Route end index element={<Services />} />
                <Route path='e-commerce-web-design' element={<EcommerceWebDesign/>}></Route>
                <Route path='digital-marketing' element={<DigitalMarketing/>}></Route>
                <Route path='brand-identity' element={<BrandIdentity/>}></Route>
                <Route path='web-design' element={<WebDesign/>}></Route>
                <Route path='mobile-applications' element={<MobileApplications/>}></Route>
                <Route path='seo' element={<SEO/>}></Route>
                <Route path='sms' element={<SMS/>}></Route>
                <Route path='motion-graphic' element={<MotionGraphic/>}></Route>
                <Route path='video-creation' element={<VideoCreation/>}></Route>
                <Route path='content-creation' element={<ContentCreation/>}></Route>
                <Route path='manage-all-your-ads' element={<ManageAllYourAds/>}></Route>
                <Route path='web-hosting' element={<WebHosting/>}></Route>
                <Route path='graphics-design' element={<GraphicDesign/>}></Route>
                <Route path='social-media-marketing' element={<SocialMediaMarketing/>}></Route>
              </Route>

              <Route path='*' element={<ErrorPage/>}></Route>
          </Routes>
        </AnimatePresence>
        </div>
    </>
  )
}

export default AnimatedRoutes