import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import ContactUs from 'components/Home/ContactUs/ContactUs'
import Footer from 'components/Global/Layout/Footer/Footer'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { axiosConfig } from 'utils/axiosConfig'
import RequestACallPopUp from 'components/Global/Elements/RequestACallPopUp/RequestACallPopUp'
import axios from 'axios'
import Loading from 'components/Global/Elements/Loading/Loading'
import SuccessRequestPopup from 'components/Global/Elements/SuccessRequestPopup/SuccessRequestPopup'
import HeaderAboutUs from 'components/AboutUs/HeaderAboutUs/HeaderAboutUs'
import styles from './Blog.module.css'
import { useParams } from 'react-router-dom'
import { Container } from 'react-bootstrap'
function Blog() {
  const params = useParams()
  const [blog,setBlog]=useState([])
  const [currentCountryCode,setCurrentCountryCode]=useState('+961')
  const [isPageLoading,setIsPageLoading]=useState(true)
  const [isSuccessOpen,setIsSuccessOpen]=useState(false)

 async function getBlog(){
      await axiosConfig.get(`/blogs/single-slug-blog/${params?.slug}`).then(res=>{
        setBlog(res.data.data)
      }).catch(err=>{
          console.log(err)
      })
  }
  useEffect(()=>{
    window.scrollTo(0,0)
    async function promiseAll(){
      await Promise.all([
        getBlog(),
      ])
      setIsPageLoading(false)
    }
    promiseAll()
  },[])
  return (
    <>
    <motion.div 
      init={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
        <MetaTags>
              <title>Pavilion</title>
        </MetaTags>
        {isPageLoading&&<Loading/>}
        {
          !localStorage.getItem('popUpSubmitted')&&
          <RequestACallPopUp setIsSuccessOpen={setIsSuccessOpen}/>
        }
        {isSuccessOpen&&<SuccessRequestPopup/>}
        <section className={styles['blog']}>
          <Container>
            <div>
              <img src={blog?.image} className={styles['blog__image']}/>
            </div>
            <h1 className={styles['blog__title']}>{blog?.title}</h1>
            <div className={styles['blog__description']}
              dangerouslySetInnerHTML={{__html: blog?.description}}
            />
          </Container>
        </section>
        <Footer/>
    </motion.div>
    </>
  )
}

export default Blog