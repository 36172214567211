import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './ContactUs.module.css'
import { useTranslation } from 'react-i18next'
import {ReactComponent as PavilionSmallLogo} from 'assets/icons/pavilionSmallLogo.svg'
import EmirateIcon from 'assets/icons/emirateIcon.svg'
import EgyptIcon from 'assets/icons/egyptIcon.svg'
import Kuwaitcon from 'assets/icons/kuwaitcon.svg'
import OmanIcon from 'assets/icons/omanIcon.svg'
import QatarIcon from 'assets/icons/qatarIcon.svg'
import BahrainIcon from 'assets/icons/bahrainIcon.svg'
import IraqIcon from 'assets/icons/iraqIcon.svg'
import KSAIcon from 'assets/icons/KSAIcon.svg'
import  PinImg from 'assets/imgs/pinImg.png'
import PhoneiImg from 'assets/imgs/phoneImg.png'
import MessageImg from 'assets/imgs/messageImg.png'
import { emailConnect, emailPattern } from 'utils/features'
import { useSelector } from 'react-redux'
import { axiosConfig } from 'utils/axiosConfig'
import { toast } from 'react-toastify'
import ReactLoading from "react-loading";
import SpecialOffer from 'components/Global/Elements/SpecialOffer/SpecialOffer'
import SuccessRequestPopup from 'components/Global/Elements/SuccessRequestPopup/SuccessRequestPopup'
import { useNavigate } from 'react-router-dom'
function ContactUs() {
    const {t} =useTranslation()
    const formRef = useRef(null)
    const countryListRef = useRef(null)
    const [isLoading,setIsLoading]=useState(false)
    const [isSuccessOpen,setIsSuccessOpen]=useState(false)
    const [message,setMessage]=useState('')
    const [countryCode,setCoutryCode]=useState('+971')
    const [countryIcon,setCoutryIcon]=useState(EmirateIcon)
    const [servicess,setServices]=useState([])
    const [isSubmittedBefore,setIsSubmittedBefore] = useState(false)
    const [data,setData]=useState({name:'',email:'',message:'',phone:'',requiredServices:[]})
    const [errors,setErros]=useState({})
    const navigate = useNavigate()

  const [countries,setCountries]=useState([
    {name:t('Emirates'),code:'+971',flag:EmirateIcon},
    {name:t('Kuwait'),code:'+965',flag:Kuwaitcon},
    {name:t('Egypt'),code:'+20',flag:EgyptIcon},
    {name:t('Oman'),code:'+968',flag:OmanIcon},
    {name:t('Qatar'),code:'+973',flag:QatarIcon},
    {name:t('Bahrain'),code:'+974',flag:BahrainIcon},
    {name:t('Iraq'),code:'+964',flag:IraqIcon},
    {name:t('Saudia Arabia'),code:'+966',flag:KSAIcon},
])
    const [settings,setSettings]=useState([])
    const selector=useSelector(data=>data.GlobalReducer)
    useEffect(()=>{
        setSettings(selector.generalSettings)
        setCoutryCode(selector.currentCountryCode)
        let modifiedServices=[]
        selector?.services?.forEach(service=>{
            modifiedServices.push({isSpecialOfferOpen:false,service:service})
        })
        setServices(modifiedServices)
    },[selector])
    
    useEffect(()=>{
        console.log('selector?.currentCountryCodeselector?.currentCountryCode',selector)
        let cout=countries.find(coutry=>{
            return coutry?.code==selector?.currentCountryCode
        })
        if(cout){
            setCoutryCode(cout?.code)
            setCoutryIcon(cout?.flag)
        }
    },[countryCode])

    // useEffect(()=>{
    //     let modifiedServices=[]
    //     services?.forEach(service=>{
    //         modifiedServices.push({isSpecialOfferOpen:false,service:service})
    //     })
    //     setServices(modifiedServices)
    // },[services])

    
    function validateInputs(modififedData){
        let status =true
        let errors ={}
        if(!modififedData?.phone){
            errors = {...errors,phone:t('Phone Is Required')}
            status=false
        }
        if(!emailPattern.test(modififedData?.email)){
            errors = {...errors,email:t('Email Must Be valid Email')}
            status=false
        }
        if(modififedData?.requiredServices.length==0){
            errors = {...errors,requiredServices:t('Service Is Required')}
            status=false
        }
        setErros(errors)
        return status
    }

    function handleChange(value , name){
        let modififedData = {...data}
        modififedData[name] = value
        if(name=='phone' &&isNaN(value)){
            return
        }
        if(isSubmittedBefore){
            validateInputs(modififedData)
        }
        setData(modififedData)
    }

    function sendRequest(){
        setIsSuccessOpen(false)
        setIsSubmittedBefore(true)
        if(!validateInputs(data)){
            return
        }
        let formData = new FormData()
        setIsLoading(true)
        formData.append('name',data?.name)
        formData.append('email',data?.email)
        formData.append('message',data?.message)
        formData.append('phone',`${countryCode}${data?.phone}`)
        data?.requiredServices.forEach((service,index)=>{
            formData.append(`required_service[${index}]`,service)
        })
        axiosConfig.post('/contact-us/create-contact-us',formData).then(res=>{
            setIsLoading(false)
            setData({name:'',email:'',message:'',phone:'',requiredServices:[]})
            setIsSuccessOpen(true)
            formRef.current.reset()
            navigate('/success-message')

        }).catch(err=>{
            setIsLoading(false)
            toast.error('Something Went Wrong')
        })
    }

    function handleAddService(value){
        let modififiedServiceValues = {...data}
        if(modififiedServiceValues?.requiredServices.includes(value)){
            modififiedServiceValues['requiredServices'] =modififiedServiceValues?.requiredServices?.filter(service=>{return service!=value})
        }else{
            modififiedServiceValues?.requiredServices.push(value)
        }
        setData(modififiedServiceValues)
    }

    function openCountryList(){
        countryListRef.current.classList.remove(styles['contact-us__content-country-list--hidden'])
    }
    
    function changeCountryCode(code,icon){
        setCoutryCode(code)
        setCoutryIcon(icon)
        countryListRef.current.classList.add(styles['contact-us__content-country-list--hidden'])
    }

    function changeOfferStatus(index){
        let modifiedServices=[...servicess]
        modifiedServices[index].isSpecialOfferOpen=true
        setServices(modifiedServices)
    }
  return (
    <section className={styles['contact-us']} id='contact-us'> 
    {/* {isSuccessOpen&&<SuccessRequestPopup/>} */}
        <Container>
            <Row>
                <Col className='p-0' lg='6' md='12'>
                    <div>
                        <h2 className={styles['contact-us__title']}
                        data-aos='fade-up' data-aos-once='true' data-aos-anchor="#contact-us" data-aos-duration='800'><PavilionSmallLogo className={styles['contact-us__title-logo']}/> {t('Contact With Us')}</h2>
                        <a href={"https://www.google.com/maps?ll=25.184924,55.276903&z=15&t=m&hl=en-US&gl=US&mapclient=embed&q=The+Metropolis+Tower+Business+Bay+-+Dubai"} target='_blank' className={styles['contact-us__social-wrapper']} data-aos='fade-up' data-aos-once='true' data-aos-anchor="#contact-us" data-aos-duration='800'>
                            <div className={styles['contact-us__social-icon-wrapper']}><img src={PinImg}/></div>
                            <p className={styles['contact-us__social-text']}>{settings?.location?settings?.location:'1606 The Metropolis Tower - Business Bay- Dubai'}</p>
                        </a>
                        <button role='button' onClick={()=>{window.open(`tel:${settings?.contact_phone_number?settings?.contact_phone_number:'00971542172270'}`, '_self')}} className={styles['contact-us__social-wrapper']} data-aos='fade-up' data-aos-once='true' data-aos-anchor="#contact-us" data-aos-duration='800'>
                            <div className={styles['contact-us__social-icon-wrapper']}><img src={PhoneiImg}/></div>
                            <p className={`${styles['contact-us__social-text']} ${styles['contact-us__social-text--phone']}`}>{settings?.contact_phone_number?settings?.contact_phone_number:'00971542172270'}</p>
                        </button>
                        <a href={emailConnect(settings?.contact_email?settings?.contact_email:'Info@pavilion-teck.com')}  className={styles['contact-us__social-wrapper']} data-aos='fade-up' data-aos-once='true' data-aos-anchor="#contact-us" data-aos-duration='800'>
                            <div className={styles['contact-us__social-icon-wrapper']}><img src={MessageImg}/></div>
                            <p className={styles['contact-us__social-text']}>{settings?.contact_email?settings?.contact_email:'Info@pavilion-teck.com'}</p>
                        </a>
                    </div>
                </Col>
                <Col className='p-0' lg='6' md='12'>
                    <div className={styles['contact-us__content-wrapper']}>
                        <h3 className={styles['contact-us__content-title']} data-aos='fade-up' data-aos-once='true' data-aos-anchor="#contact-us" data-aos-duration='800'>{t('Get In Touch')}</h3>
                        <form ref={formRef}>
                            <Row>
                                <Col className={styles['contact-us__content-input-wrapper']} xs='12' data-aos='fade-up' data-aos-once='true' data-aos-anchor="#contact-us" data-aos-duration='800'>
                                    <input 
                                    type='text' 
                                    className={styles['contact-us__content-input']} 
                                    placeholder={t('Your Name')}
                                    value={data?.name}
                                    name='name'
                                    onChange={(e)=>{handleChange(e.target.value,e.target.name)}}/>
                                </Col>
                                <Col md='6' className={`${styles['contact-us__content-input--phone-wrapper']} ${styles['contact-us__content-input-wrapper']}`} data-aos='fade-up' data-aos-once='true' data-aos-anchor="#contact-us" data-aos-duration='800'>
                                    <div className='position-relative'>
                                        <input 
                                        type='text' 
                                        className={`${styles['contact-us__content-input']} ${styles['contact-us__content-input--phone']}`} 
                                        placeholder={t('*********')}
                                        value={data?.phone}
                                        name='phone'
                                        onChange={(e)=>{handleChange(e.target.value,e.target.name)}}/>
                                        <button type='button' className={styles['contact-us__content-country-button']} onClick={openCountryList}>
                                            <img src={countryIcon} className={styles['contact-us__content-phone-icon']}/>{countryCode}
                                        </button>
                                        <div ref={countryListRef} className={`${styles['contact-us__content-country-list']} ${styles['contact-us__content-country-list--hidden']}`}>
                                            {
                                                countries&&countries.map((country,index)=>(
                                                    <button type='button' className={styles['contact-us__content-country-list-button']} onClick={()=>{changeCountryCode(country?.code,country?.flag)}} key={index}>
                                                        <img src={country?.flag} className={styles['contact-us__content-phone-list-icon']}/> {country?.code}
                                                        <span className={styles['contact-us__content-phone-list-span']}>{country?.name}</span>
                                                    </button>
                                                ))
                                            }
                                        </div>
                                        {errors?.phone&& <p className={styles['contact-us__content-input--error']}>{errors?.phone}</p>}
                                    </div>
                                </Col>
                                <Col className={styles['contact-us__content-input-wrapper']} md='6' data-aos='fade-up' data-aos-once='true' data-aos-anchor="#contact-us" data-aos-duration='800'>
                                    <input 
                                    type='email' 
                                    className={styles['contact-us__content-input']} 
                                    placeholder={t('Your Email')}
                                    value={data?.email}
                                    name='email'
                                    onChange={(e)=>{handleChange(e.target.value,e.target.name)}}/>
                                    {errors?.email&& <p className={styles['contact-us__content-input--error']}>{errors?.email}</p>}
                                </Col>
                                <Col className={styles['contact-us__content-input-wrapper']} md='12' data-aos='fade-up' data-aos-once='true' data-aos-anchor="#contact-us" data-aos-duration='800'>
                                    <h4 className={`${styles['contact-us__category-title']}`}>{t('Select the required services')}</h4>
                                    <div className={styles['contact-us__categories']}>
                                        {
                                            servicess&&servicess.map((service,index)=>(
                                                <div className={styles['contact-us__category-wrapper']} key={index}> 
                                                    <input 
                                                    type='checkbox' 
                                                    name='services' 
                                                    className={styles['contact-us__category-input']} 
                                                    id={`catgeoryMobile${index}`}
                                                    value={service?.service?.id}
                                                    onChange={(e)=>{handleAddService(e.target.value);changeOfferStatus(index)}}/>
                                                    <label className={styles['contact-us__category-text']} htmlFor={`catgeoryMobile${index}`}>{service?.service?.name}</label>
                                                    {(service?.isSpecialOfferOpen &&service?.service?.has_offer=='2')&&<SpecialOffer service={service?.service}/>}
                                                </div>
                                            ))
                                        }
                                    </div>
                                    {errors?.requiredServices&& <p className={styles['contact-us__content-input--error']}>{errors?.requiredServices}</p>}
                                </Col>
                                <Col className={styles['contact-us__content-input-wrapper']} lg='12' >
                                    <textarea 
                                        placeholder={t('Your Message')} data-aos='fade-up' data-aos-once='true' data-aos-anchor="#contact-us" data-aos-duration='800'
                                        className={`${styles['contact-us__content-input']} ${styles['contact-us__content-input--textarea']}`}
                                        value={data?.message}
                                        name='message'
                                        onChange={(e)=>{handleChange(e.target.value,e.target.name)}}></textarea>
                                </Col>
                            </Row>
                        </form>
                        <button className={styles['contact-us__content-button']} onClick={sendRequest} data-aos='fade-up' data-aos-once='true' data-aos-anchor="#contact-us" data-aos-duration='800'>
                            {
                            !isLoading?t('Send')
                            :<ReactLoading type={"spin"} color={'#ffffff'} height={40} width={40} />
                            }
                        </button>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
  )
}

export default ContactUs