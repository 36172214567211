import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import ContactUs from 'components/Home/ContactUs/ContactUs'
import Footer from 'components/Global/Layout/Footer/Footer'
import { useState } from 'react'
import RequestACallPopUp from 'components/Global/Elements/RequestACallPopUp/RequestACallPopUp'
import Loading from 'components/Global/Elements/Loading/Loading'
import SuccessRequestPopup from 'components/Global/Elements/SuccessRequestPopup/SuccessRequestPopup'
import ServicesTransparentHeader from 'components/Services/ServicesTransparentHeader/ServicesTransparentHeader'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './ContentCreation.module.css'
import contentCreationMobileApps from 'assets/imgs/contentCreationMobileApps.png'
import contentCreationSocialApps from 'assets/imgs/contentCreationSocialApps.png'
import contentCreationWebDesign from 'assets/imgs/contentCreationWebDesign.png'
import seoBackLine from 'assets/imgs/seoBackLine.png'
import seoLensImage from 'assets/imgs/seoLensImage.png'
function ContentCreation() {
  const {t} =useTranslation()
  const [isPageLoading,setIsPageLoading]=useState(true)
  const [isSuccessOpen,setIsSuccessOpen]=useState(false)

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
    <motion.div 
      init={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
        <MetaTags>
              <title>Pavilion</title>
        </MetaTags>
        {/* {isPageLoading&&<Loading/>} */}
        {
          !localStorage.getItem('popUpSubmitted')&&
          <RequestACallPopUp setIsSuccessOpen={setIsSuccessOpen}/>
        }
        {isSuccessOpen&&<SuccessRequestPopup/>}
        <section className={styles['seo']}>
          <div className={styles['seo--bg']}></div>
          <ServicesTransparentHeader title={`${t('Content Creation')}`} description={`${t('Do you want to level up your marketing with animation? Elevate your creative content with the most affordable motion graphics solution on the market.')}`}/>

          <section className={styles['seo-details-wrapper']}>
            <Container>
                <h2 className={styles['seo-details-wrapper__title']}>{t('How it works.?')}</h2>
                <p className={styles['seo-details-wrapper__description']}>{t('Save hours on writing contentand get out of the idea jam')}</p>
              <Row>
                <Col lg='4' className='mb-3'>
                  <h3 className={styles['seo-details-wrapper__item-title']}>{t('Listening to you')}</h3>
                  <p className={styles['seo-details-wrapper__item-description']}>{t('Help inform your customers and explore your value position with downloadable guides and Ebooks. Showcase your products, share detailed knowledge, and entertain your customers with branded digital assets.')}</p>
                </Col>
                <Col lg='4' className='mb-3'>
                  <h3 className={styles['seo-details-wrapper__item-title']}>{t('Brainstorming')}</h3>
                  <p className={styles['seo-details-wrapper__item-description']}>{t('A logo is the first building block in your brand identity. Whether you are a start-up company ready to start creating your identity or looking to refresh or modernize your current look, our designers can create something unique just for you.')}</p>
                </Col>
                <Col lg='4' className='mb-3'>
                  <h3 className={styles['seo-details-wrapper__item-title']}>{t('Creativity process')}</h3>
                  <p className={styles['seo-details-wrapper__item-description']}>{t('Our team of designers and content specialists can create unique, informative branded print collateral. From business cards to signage, we can help promote your business and share branded documents with your team.')}</p>
                </Col>
              </Row>
            </Container>
          </section>
          <section className={styles['seo-details-wrapper']}>
            <Container>
                <h2 className={styles['seo-details-wrapper__image-title']}>{t('For What?')}</h2>
              <Row>
                <Col lg='4' className='mb-3'>
                  <img src={contentCreationMobileApps} className={styles['seo-details-wrapper__item-image']} alt='mobile apps'/>
                  <h3 className={styles['seo-details-wrapper__item-image-title']}>{t('Mobile apps')}</h3>
                </Col>
                <Col lg='4' className='mb-3'>
                  <img src={contentCreationSocialApps} className={styles['seo-details-wrapper__item-image']} alt='mobile apps'/>
                  <h3 className={styles['seo-details-wrapper__item-image-title']}>{t('Social Media')}</h3>
                </Col>
                <Col lg='4' className='mb-3'>
                  <img src={contentCreationWebDesign} className={styles['seo-details-wrapper__item-image']} alt='mobile apps'/>
                  <h3 className={styles['seo-details-wrapper__item-image-title']}>{t('Web Design')}</h3>
                </Col>
              </Row>
            </Container>
          </section>


        </section>

        <ContactUs/>
        <Footer/>
    </motion.div>
    </>
  )
}

export default ContentCreation