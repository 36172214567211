import styled from "styled-components";

export const ProjectDiv = styled.div
`       
        position:relative;
        border-radius: 44.75px;
        &:hover .our-projects__project-content-wrapper{
                transform: translateY(0%);
                opacity: 1;
                z-index: 10; 
            }

        .our-projects{
                margin-top: 100px;
                margin-bottom: 160px;
            }
            .our-projects__title{
                font-size:50px;
                margin-bottom: 20px;
                color: #484848;
                font-family: 'InterSemiBold';
                text-align: center;
            }
            .our-projects__list{
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 56px;
                margin-bottom: 55px;
            }
            .our-projects__item-button{
                font-size:25px;
                color: #919191;
                background-color: transparent;
            }
            .our-projects__item-button--active{
                font-size:25px;
                color: #414141;
                font-family: 'InterMedium';
            }
            .our-projects__project-wrapper{
                position:relative;
                border-radius: 44.75px;
            }
            .our-projects__project-img{
                width: -webkit-fill-available;
                aspect-ratio: 1/1;
                border-radius: 44.75px;
            }
            .our-projects__project-content-wrapper{
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 20px;
                background-color: ${props=>props.bg? props.bg : ''} ;
                position:absolute;
                top:0;
                left:0;
                bottom:0;
                right:0;
                width: 100%;
                height: 100%;
                transition: var(--transition);
                transform: translateY(100%);
                border-radius: 44.75px;
                opacity: 0;
                z-index: -1;
                justify-content:center; 

            }
            .our-projects__project-wrapper:hover .our-projects__project-content-wrapper{
                transform: translateY(0%);
                opacity: 1;
                z-index: 10;  
            }
            .our-projects__project-content-title{
                color: white;
                font-size:24.75px;
                font-family: "InterSemiBold";
                margin-top:20px;
                // margin: auto 0;
                // position: absolute;
                // top: 50%;
                // transform: translateY(-50%);
                text-align:center;
            }
            .our-projects__project-content-more{
                color: white;
                font-size:23.97px;
                font-family: "InterMedium";
                /* margin-right: 15px; */
                // margin-top: auto;
            }
            .our-projects__project-content-more:hover{
                color: White;
            }
            .our-projects__project-content-more svg{
                width:30px;
                height:30px;
            }
            .our-projects__swiper{
                padding: 40px 10px 80px;
            }
            .our-projects__project-links-wrapper{
                display: flex;
                flex-direction: column;
                gap: 10.28px;
            }
            .our-projects__project-link{
                color: White;
                font-size:14.51px;
                font-family:'TajawalMedium';
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 15px;
                border: 1.71px solid #FFFFFF;
                width: 191.14px;
                height:41.81px;
                border-radius:8px;
            }
            @media (max-width:769px) {
                .our-projects__list{
                    display: none;
                }
                .our-projects__title{
                    font-size: 40px;
                    margin-bottom: 0px;
                }
            }
            @media (max-width:500px) {
                .our-projects{
                    margin-top: 50px;
                    margin-bottom: 50px;
                }
            }
`
