import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import imgSrc from 'assets/imgs/404.png'
import styles from './ErrorPage.module.css'
function ErrorPage() {
  const {t} =useTranslation()
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
    <motion.div 
        init={{width:0}}
        animate={{width:"100%"}}
        exit={{x:window.innerWidth ,transition:{duration:.3}}}
        > 
        <MetaTags>
            <title>Pavilion</title>
        </MetaTags>
        <section className={styles['error']}>
            <Container>
                <img src={imgSrc} className={styles['error__img']}/>
                <Link to='/' className={styles['error__return-button']}>{t('To Homepage')}</Link>
            </Container>
        </section>
        
    </motion.div>
    </>
  )
}

export default ErrorPage