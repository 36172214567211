import React, { useState } from 'react';
import { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './SpecialOffer.module.css'
import TrueIconSrc from 'assets/icons/trueIcon.svg'
import dicontOfferAr from 'assets/imgs/dicontOfferAr.gif'
import dicontOfferEn from 'assets/imgs/dicontOfferEn.gif'
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
function SpecialOffer({service}) {
  const {t} =useTranslation()
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(()=>{
   
    if (show) {
        document.querySelector('html').classList.add('overflow-hidden')
    } else {
        document.querySelector('html').classList.remove('overflow-hidden')
    }
  },[show])
  useEffect(()=>{
    document.querySelector('html').classList.add('overflow-hidden')
    handleShow()
  },[])
  return (
    <>
      <Modal show={show} onHide={handleClose} size='xl'>
        <div className={styles['modal__request']}>
            <Container >
                <button onClick={handleClose} className={styles['modal__close-button']}>
                    {t('Close')}
                    {/* <ModalCloseButton className={styles['modal__close-button-icon']}/> */}
                </button>
                <Row>
                    <Col lg='6'>
                        <img src={dicontOfferEn} className={styles['modal__img']} alt='modal image'/>
                        <img src={dicontOfferAr} className={styles['modal__img--arabic']} alt='modal image'/>
                        {/* <img src={dicontOfferEn} className={styles['modal__img--responsive']} alt='modal image'/> */}
                        <div className={`${styles['arrow-wrapper']} d-flex`}>
                            {/* <img src={specialArrow} className={styles['modal__img-arrow']} alt='modal image'/> */}
                        </div>
                    </Col>
                    <Col lg='6'>
                        <div>
                            <h1 className={styles['modal__title']}>
                                {service?.name}
                            </h1>
                            <h2 className={styles['modal__sub-title']}>{t('Custom')} {service?.name} {t('Package')}</h2>
                            <p className={styles['modal__price']}>{service?.price_after_discount} {t('AED')}</p>
                            <p className={styles['modal__discount']}><p className={styles['modal__discount-span']}>{service?.price_before_discount} {t('AED')}</p></p>
                            <ul className={styles['modal__list']}>
                                {
                                    service?.offer_features&&service?.offer_features.map((featuree,index)=>(
                                        <li className={styles['modal__list-item']} key={index}>
                                            <img src={TrueIconSrc} className={styles['modal__icon']}/>{featuree?.feature}
                                            {/* <TrueIcon className={styles['modal__icon']}/> {featuree?.feature} */}
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        </Modal>
    </>
  )
}

export default SpecialOffer