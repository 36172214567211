import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styles from './WhatWeOffer.module.css'
import HomeWhatWeOffeMobilerIcon from 'assets/icons/HomeWhatWeOffeMobilerIcon.svg'
import HomeWhatWeOffeEcommercerIcon from 'assets/icons/HomeWhatWeOffeEcommercerIcon.svg'
import HomeWhatWeOffeWebsiterIcon from 'assets/icons/HomeWhatWeOffeWebsiterIcon.svg'
import HomeWhatWeOffeSocialMediarIcon from 'assets/icons/HomeWhatWeOffeSocialMediarIcon.svg'
import HomeWhatWeOffeBrandIdentityrIcon from 'assets/icons/HomeWhatWeOffeBrandIdentityrIcon.svg'
import { useTranslation } from 'react-i18next'
import OurProjectCard from 'components/Global/Elements/OurProjectCard/OurProjectCard'
// function WhatWeOffer({categories}) {
function WhatWeOffer() {
    const {t} =useTranslation()
    const categories =[
        {
            icon:HomeWhatWeOffeMobilerIcon,
            title:t('Mobile Apps') ,
            link:'/services/mobile-applications',
            features:[t('Creative App Design'),t('Consistent and reliable development'),t('Smooth User Experience'),t('Ongoing support and maintenance')]
        },
        {
            icon:HomeWhatWeOffeEcommercerIcon,
            title:t('E-Commerce') ,
            link:'/services/e-commerce-web-design',
            features:[t('Attractive and responsive design'),t('Advanced e-commerce functionality'),t('Easy management system'),t('Secure integration of payment methods')]
        },
        {
            icon:HomeWhatWeOffeWebsiterIcon,
            title:t('Website-Design') ,
            link:'/services/web-design',
            features:[t('Creative and attractive design'),t('Outstanding user experience'),t('Search engine improvements'),t('Effective content coordination and organization')]
        },
        {
            icon:HomeWhatWeOffeSocialMediarIcon,
            title:t('Social Media Marketing') ,
            link:'/services/digital-marketing',
            features:[t('Creative and attractive design'),t('Outstanding user experience'),t('Search engine improvements'),t('Effective content coordination and organization')]
        },
        {
            icon:HomeWhatWeOffeBrandIdentityrIcon,
            title:t('Brand Identity') ,
            link:'/services/brand-identity',
            features:[t('Creative App Design'),t('Consistent and reliable development'),t('Smooth User Experience'),t('Ongoing support and maintenance')]
        },
    ]
    return (
        <section className={styles['what-we-offer']} id='services'>
            <div className={styles['what-we-offer__background']}></div>
            <Container className='h-100'>
                <h1 className={styles['what-we-offer__title']} data-aos='fade-up' data-aos-once='true' data-aos-delay='100' data-aos-duration='800'>{t('What we offer')}</h1>
                <Row className='h-100'>
                    {/* <Col lg='5' className='my-auto'>
                        <div>
                            <h2 className={styles['what-we-offer__title']} data-aos='fade-up' data-aos-once='true' data-aos-delay='100' data-aos-duration='800'>{t('What we offer')}</h2>
                            <p className={styles['what-we-offer__description']} data-aos='fade-up' data-aos-once='true' data-aos-delay='200' data-aos-duration='800'>{t('We help you in building your brand, using the best marketing solutions.We Offer The services your business To Raise and to be the top of the field keeping pace with market requirements')}</p>
                            //<Link to='/' className={styles['what-we-offer__link']} data-aos='fade-up' data-aos-once='true' data-aos-delay='300' data-aos-duration='800'>{t('Our Services')}</Link>
                        </div>
                    </Col> */}
                    <Col lg='12'>
                        <div className={styles['what-we-offer__cards-wrapper']}>
                            {
                                categories&&categories.map((category,index)=>(
                                    index<5&&
                                    <OurProjectCard category={category}/>
                                ))
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default WhatWeOffer