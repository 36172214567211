import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './ContactUsInfo.module.css'
import pavilionLogo from 'assets/imgs/pavilionLogo.png'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {ReactComponent as MessageIconn} from 'assets/icons/contactUsMessageIcon.svg'
import {ReactComponent as PhoneIconn} from 'assets/icons/contactUsPhoneIcon.svg'
import { emailConnect } from 'utils/features'
import contactUsMessageIcon from 'assets/imgs/contactUsMessageIcon.png'


function ContactUsInfo({project}) {
    const {t} = useTranslation()
    const [settings,setSettings] = useState([])
    const selector = useSelector(data=>data.GlobalReducer)
    useEffect(()=>{
      setSettings(selector.generalSettings)
    },[selector])
  return (
    <div className={styles['contact-us__info']}>
      <Container className='container--header'>
        <div className={styles['contact-us__info-social-wrapper']}>
          <button role='button' onClick={()=>{window.open(`tel:${settings?.contact_phone_number?settings?.contact_phone_number:'00971542172270'}`, '_self')}}  className={styles['contact-us__social-wrapper']}  data-aos-once='true' data-aos-delay='300' data-aos-duration='800'>
            <PhoneIconn className={styles['contact-us__social-icon']}/>
            {/* <div className={styles['contact-us__social-icon-wrapper']}><img src={PhoneiImg}/></div> */}
          </button>
          <a href={emailConnect(settings?.contact_email?settings?.contact_email:'Info@pavilion-teck.com')}  className={styles['contact-us__social-wrapper']}  data-aos-once='true' data-aos-delay='400' data-aos-duration='800'>
            <img src={contactUsMessageIcon} alt='message icon' className={styles['contact-us__social-icon']}/>
            {/* <div className={styles['contact-us__social-icon-wrapper']}><img src={MessageImg}/></div> */}
          </a>
        </div>
        <h2 className={styles['contact-us__info-title']}>{t('Let’s start conversation')}</h2>
        <img src={pavilionLogo} alt='logo' className={styles['contact-us__info-image']}/>
      </Container>
    </div>
  )
}

export default ContactUsInfo