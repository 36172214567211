import { CHANGE_CURRENT_COUNTRY_CODE, CHANGE_GENERAL_SETTINGS, CHANGE_LANGUAGE, CHANGE_PROJECTS,CHANGE_OUR_CLIENTS,
    CHANGE_PROJECT_CATEGORIES, CHANGE_SERVICES } from "./GlobalActionsTypes"

const initialState = {
    lang:'en',
    currentCountryCode: localStorage.getItem('currentCountryCode')?localStorage.getItem('currentCountryCode') :'+971',
    generalSettings: localStorage.getItem('slider')?JSON.parse(localStorage.getItem('slider')) :[],
    services:localStorage.getItem('services')?JSON.parse(localStorage.getItem('services')) :[],
    projects:localStorage.getItem('projects')?JSON.parse(localStorage.getItem('projects')) :[],
    clients:localStorage.getItem('clients')?JSON.parse(localStorage.getItem('clients')) :[],
    projectCategories:localStorage.getItem('projectCategories')?JSON.parse(localStorage.getItem('projectCategories')) :[],

}

const GlobalReducer = (state = initialState ,action)=>{
    switch (action.type){
        case CHANGE_LANGUAGE :{
            return {
                ...state,
                lang:action.lang
            }
        }
        case CHANGE_SERVICES :{
            return {
                ...state,
                services:action.services
            }
        }
        case CHANGE_PROJECTS :{
            return {
                ...state,
                projects:action.projects
            }
        }
        case CHANGE_OUR_CLIENTS :{
            return {
                ...state,
                clients:action.clients
            }
        }
        case CHANGE_PROJECT_CATEGORIES :{
            return {
                ...state,
                projectCategories:action.projectCategories
            }
        }
        case CHANGE_CURRENT_COUNTRY_CODE :{
            return {
                ...state,
                currentCountryCode:action.currentCountryCode
            }
        }

        case CHANGE_GENERAL_SETTINGS :{
            return {
                ...state,
                generalSettings:action.generalSettings
            }
        }
        default: return state
    }
}

export default GlobalReducer