import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './BlogsCardsWrapper.module.css'
import { useTranslation } from 'react-i18next'
import BlogsCard from 'components/Global/Elements/BlogsCard/BlogsCard'
function BlogsCardsWrapper({blogs}) {
    const {t} =useTranslation()
   
  return (
    <section className={styles['blogs-wrapper']}>
        <Container className='h-100 container--header d-flex align-items-center justify-content-center'>
            <Row>
              <Col lg='12'>
                <Row>
                  {
                    blogs && blogs?.map(blog=>(
                      <Col xl='4' lg='6' md='6' xs='12' key={blog?.id}>
                        <BlogsCard blog={blog}/>
                      </Col>
                    ))
                  }
                  {/* <Col xl='4' lg='6' md='6' xs='12'>
                    <BlogsCard/>
                  </Col>
                  <Col xl='4' lg='6' md='6' xs='12'>
                    <BlogsCard/>
                  </Col>
                  <Col xl='4' lg='6' md='6' xs='12'>
                    <BlogsCard/>
                  </Col> */}
                </Row>
              </Col>
              {/* <Col lg='3'></Col> */}
            </Row>
        </Container>
    </section>
  )
}

export default BlogsCardsWrapper