import { CHANGE_SERVICES, CHANGE_LANGUAGE, CHANGE_GENERAL_SETTINGS,CHANGE_OUR_CLIENTS, CHANGE_CURRENT_COUNTRY_CODE, CHANGE_PROJECTS, CHANGE_PROJECT_CATEGORIES  } from './GlobalActionsTypes' 


export const changeLanguageAction = (code)=>{
    return{
        type:CHANGE_LANGUAGE,
        lang:code
    }
}
export const changeServicesAction = (services)=>{
    return{
        type:CHANGE_SERVICES,
        services:services
    }
}
export const changeCurrentCountryCodeAction = (currentCountryCode)=>{
    return{
        type:CHANGE_CURRENT_COUNTRY_CODE,
        currentCountryCode:currentCountryCode
    }
}
export const changeProjectsAction = (projects)=>{
    return{
        type:CHANGE_PROJECTS,
        projects:projects
    }
}
export const changeOurClientsAction = (clients)=>{
    return{
        type:CHANGE_OUR_CLIENTS,
        clients:clients
    }
}
export const changeProjectCategoriessAction = (projectCategories)=>{
    return{
        type:CHANGE_PROJECT_CATEGORIES,
        projectCategories:projectCategories
    }
}

export const changeGeneralSettingsAction = (generalSettings)=>{
    return{
        type:CHANGE_GENERAL_SETTINGS,
        generalSettings:generalSettings
    }
}