import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import ContactUs from 'components/Home/ContactUs/ContactUs'
import Footer from 'components/Global/Layout/Footer/Footer'
import { useState } from 'react'
import RequestACallPopUp from 'components/Global/Elements/RequestACallPopUp/RequestACallPopUp'
import Loading from 'components/Global/Elements/Loading/Loading'
import SuccessRequestPopup from 'components/Global/Elements/SuccessRequestPopup/SuccessRequestPopup'
import ServicesTransparentHeader from 'components/Services/ServicesTransparentHeader/ServicesTransparentHeader'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './VideoCreation.module.css'
import motiongraphicImage from 'assets/imgs/motiongraphicImage.png'
import seoLocalImage from 'assets/imgs/seoLocalImage.png'
import seoAnalysisImage from 'assets/imgs/seoAnalysisImage.png'
import seoBackLine from 'assets/imgs/seoBackLine.png'
import seoLensImage from 'assets/imgs/seoLensImage.png'

import {ReactComponent as VideoCreationFacebookIcon} from 'assets/icons/videoCreationFacebookIcon.svg'
import {ReactComponent as VideoCreationInstagramIcon} from 'assets/icons/videoCreationInstagramIcon.svg'
import {ReactComponent as VideoCreationVideoAdsIcon} from 'assets/icons/videoCreationVideoAdsIcon.svg'
function VideoCreation() {
  const {t} =useTranslation()
  const [isPageLoading,setIsPageLoading]=useState(true)
  const [isSuccessOpen,setIsSuccessOpen]=useState(false)

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
    <motion.div 
      init={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
        <MetaTags>
              <title>Pavilion</title>
        </MetaTags>
        {/* {isPageLoading&&<Loading/>} */}
        {
          !localStorage.getItem('popUpSubmitted')&&
          <RequestACallPopUp setIsSuccessOpen={setIsSuccessOpen}/>
        }
        {isSuccessOpen&&<SuccessRequestPopup/>}
        <section className={styles['seo']}>
          <div className={styles['seo--bg']}></div>
          <ServicesTransparentHeader title={`${t('Videos Creation')}`} description={`${t('Animated video production for B2B, healthcare, SaaS,education, and more.')}`}/>

          <section className={styles['seo-info-wrapper']}>
            <Container>
              <h1 className={styles['seo-info-wrapper__title']}>{t('Animated video production for B2B, healthcare, SaaS,education, and more.')}</h1>
            </Container>
            <div className={styles['seo-info-wrapper__mastering-wrapper']}>
              <p className={styles['seo-info-wrapper__mastering-title']}>
                {t('That is what we are ')}
                <span className={styles['seo-info-wrapper__mastering-title--span']}>{t('Mastering')}</span>
              </p>
              <Container> 
                <div className={styles['seo-info-wrapper__mastering']}>

                  <div className={styles['seo-info-wrapper__mastering-item']}>
                    <div className={styles['seo-info-wrapper__mastering-item-icons-wrapper']}>
                      <div className={`${styles['seo-info-wrapper__mastering-item-icon-wrapper']} ${styles['seo-info-wrapper__mastering-item-icon-wrapper--small']}`}>
                        <VideoCreationInstagramIcon className={styles['seo-info-wrapper__mastering-item-icon']}/>
                      </div>
                      <div className={`${styles['seo-info-wrapper__mastering-item-icon-wrapper']} ${styles['seo-info-wrapper__mastering-item-icon-wrapper--absolute']}`}>
                        <VideoCreationFacebookIcon className={styles['seo-info-wrapper__mastering-item-icon']}/>
                      </div>
                    </div>
                      <h4 className={styles['seo-info-wrapper__mastering-item-title']}>{t('SOCIAL MEDIA STORIES')}</h4>
                  </div>

                  <div className={styles['seo-info-wrapper__mastering-item']}>
                    <div className={styles['seo-info-wrapper__mastering-item-icon-wrapper']}>
                      <VideoCreationVideoAdsIcon className={styles['seo-info-wrapper__mastering-item-icon']}/>
                    </div>
                      <h4 className={styles['seo-info-wrapper__mastering-item-title']}>{t('Video ads')}</h4>
                  </div>
                </div>
              </Container>
            </div>
          </section>
        </section>

        <ContactUs/>
        <Footer/>
    </motion.div>
    </>
  )
}

export default VideoCreation