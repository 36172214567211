import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import ContactUs from 'components/Home/ContactUs/ContactUs'
import Footer from 'components/Global/Layout/Footer/Footer'
import { useState } from 'react'
import RequestACallPopUp from 'components/Global/Elements/RequestACallPopUp/RequestACallPopUp'
import Loading from 'components/Global/Elements/Loading/Loading'
import SuccessRequestPopup from 'components/Global/Elements/SuccessRequestPopup/SuccessRequestPopup'
import ServicesTransparentHeader from 'components/Services/ServicesTransparentHeader/ServicesTransparentHeader'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './MobileApplications.module.css'

import mobileApplicationIPavilionmage from 'assets/imgs/mobileApplicationIPavilionmage.png'
import mobileApplicationImage from 'assets/imgs/mobileApplicationImage.png'
import mobileApplicationHuaweiImage from 'assets/imgs/mobileApplicationHuaweiImage.png'
import mobileApplicationAppleImage from 'assets/imgs/mobileApplicationAppleImage.png'
import mobileApplicationAndroidImage from 'assets/imgs/mobileApplicationAndroidImage.png'
import MobileApplicationBG from 'assets/imgs/MobileApplicationBG.png'
import MobileApplicationImage3 from 'assets/imgs/MobileApplicationImage3.png'
import MobileApplicationImage2 from 'assets/imgs/MobileApplicationImage2.png'

import mobileApplicationUIUXImage from 'assets/imgs/mobileApplicationUIUXImage.png'
import mobileApplicationSecurityImage from 'assets/imgs/mobileApplicationSecurityImage.png'
import mobileApplicationAPIIntegrationImage from 'assets/imgs/mobileApplicationAPIIntegrationImage.png'
import mobileApplicationDataMigrationImage from 'assets/imgs/mobileApplicationDataMigrationImage.png'
import mobileApplicationTechnicalSupportImage from 'assets/imgs/mobileApplicationTechnicalSupportImage.png'
import ServiceAccordion from 'components/Global/Elements/ServiceAccordion/ServiceAccordion'
import HeaderWithBG from 'components/Global/Elements/HeaderWithBG/HeaderWithBG'
import { HashLink } from 'react-router-hash-link'
function MobileApplications() {
  const {t} =useTranslation()
  const [isPageLoading,setIsPageLoading]=useState(true)
  const [isSuccessOpen,setIsSuccessOpen]=useState(false)
  const [activeAccordionIndex,setActiveAccordionIndex]=useState(null)
  function changeActiveAccordionIndex(index){
    setActiveAccordionIndex(index)
  }
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
    <motion.div 
      init={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
        <MetaTags>
              <title>Pavilion</title>
        </MetaTags>
        {/* {isPageLoading&&<Loading/>} */}
        {
          !localStorage.getItem('popUpSubmitted')&&
          <RequestACallPopUp setIsSuccessOpen={setIsSuccessOpen}/>
        }
        {isSuccessOpen&&<SuccessRequestPopup/>}
        <section className={styles['seo']}>
          {/* <div className={styles['seo--bg']}></div> */}
          {/* <ServicesTransparentHeader title={`${t('Mobile Applications')}`} description={`${t('Chetu develops applications that are built with a robust set of features that are custom-tailored')}`}/> */}

          <HeaderWithBG 
            title={`${t('Mobile Applications')}`} 
            description={`${t('Get an innovative smart app that makes your business successful and marketable, with the professional smart app design service from Pavilion Technology!')}`}
            image={MobileApplicationBG}
          />
          
          <section className={`${styles['seo-info-wrapper']} ${styles['seo-info-wrapper--bg-image']}`}>
            <Container>
              <Row>
                <Col lg='6'>
                  <div className={styles['seo-info-wrapper__content-wrapper']}>
                    <h2 className={styles['seo-info-wrapper__title']}>{t('What do we offer you in application design service?')}</h2>
                  </div>
                </Col>
                <Col lg='6' className='d-flex align-items-center'>
                  <p className={styles['seo-info-wrapper__description']}>
                    {t("Our team of creative designers and skilled developers has extensive experience in designing and developing smart applications on various platforms such as iOS and Android.")}
                  </p>
                </Col>
              </Row>
              <div>
                <h2 className={styles['seo-info-wrapper__title4']}>{t('We offer comprehensive services that include:')}</h2>
                <p className={styles['seo-info-wrapper__description']}>
                  {t("Smartphone Application Design: We develop and design innovative and effective applications that run on various operating systems such as iOS and Android. Our services analyze customer needs, design attractive and easy-to-use user interfaces, and program smart applications that are compatible with various smart devices. Our smart phone application design service provides opportunities for companies and individuals to reach a wide audience of users through multi-platform applications that work perfectly and meet the diverse needs of users, and give customers the advantage of being present in the world of advanced and competitive smart phones.")}
                </p>
              </div>
            </Container>
          </section>
          
          <section className={styles['seo-info-wrapper']}>
            <Container>
              <Row>
                <Col lg='8'>
                  <div className={styles['seo-info-wrapper__content-wrapper']}>
                    <h2 className={styles['seo-info-wrapper__title']}>{t('Design IOS Apps')}</h2>
                    <p className={styles['seo-info-wrapper__description']}>
                      {t("We aim to develop and design innovative and distinctive applications that run on the iOS operating system used in iPhones and iPads.This service includes:")}
                    </p>
                    <ul className={styles['seo-info-wrapper__list']}>
                      <li className={styles['seo-info-wrapper__list-info']}>{t('Customized consulting')}</li>
                      <li className={styles['seo-info-wrapper__list-info']}>{t('Analysis of customer needs')}</li>
                      <li className={styles['seo-info-wrapper__list-info']}>{t('Unique and attractive user interface design')}</li>
                      <li className={styles['seo-info-wrapper__list-info']}>{t('Develop smart applications that are consistent with the requirements of the iOS platform')}</li>
                    </ul>
                    <p className={styles['seo-info-wrapper__description']}>
                      {t("The iPhone application design service allows companies and individuals to achieve their goals and reach a wide audience of users through innovative and easy-to-use applications that enhance the user experience and achieve success in the competitive market.")}
                    </p>
                  </div>
                </Col>
                <Col lg='4'>
                  <img src={MobileApplicationImage3} alt='seo image' className={styles['seo-info-wrapper__image']}/>
                </Col>
              </Row>
            </Container>
          </section>
          
          <section className={`${styles['seo-info-wrapper']} ${styles['seo-info-wrapper--bg']}`}>
            <Container>
              <Row>
                <Col lg='4' className='order-lg-1 order-2'>
                  <img src={MobileApplicationImage2} alt='seo image' className={styles['seo-info-wrapper__image']}/>
                </Col>
                <Col lg='8' className='order-lg-2 order-1'>
                  <div className={styles['seo-info-wrapper__content-wrapper']}>
                    <h2 className={styles['seo-info-wrapper__title']}>{t('Design Android Apps')}</h2>
                    <p className={styles['seo-info-wrapper__description']}>
                      {t("Aim to provide an innovative and attractive design for smart phone applications running on the Android operating system.This service includes")}
                    </p>
                    <ul className={styles['seo-info-wrapper__list']}>
                      <li className={styles['seo-info-wrapper__list-info']}>{t('Android apps are designed with great care and attention')}</li>
                      <li className={styles['seo-info-wrapper__list-info']}>{t('Focus on smooth user experience and ease of use')}</li>
                      <li className={styles['seo-info-wrapper__list-info']}>{t('Innovative user interface design consisttent with the design of Android system')}</li>
                      <li className={styles['seo-info-wrapper__list-info']}>{t('Improving interaction and user experience by utilizing the latest technologies and trends in application design')}</li>
                    </ul>
                    <p className={styles['seo-info-wrapper__description']}>
                      {t("Improving interaction and user experience by utilizing the latest technologies and trends in application design")}
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          
          <section className={`${styles['seo-info-wrapper']}`}>
            <Container>
              <Row>
                <Col lg='12'>
                  <div className={styles['seo-info-wrapper__content-wrapper']}>
                    <h2 className={styles['seo-info-wrapper__title2']}>{t('Integrated services to serve your project and business:')}</h2>
                    
                    <ul className={styles['seo-info-wrapper__list2']}>
                      <li className={styles['seo-info-wrapper__list2-info']}>{t('Designing a distinctive and easy-to-use mobile application that accurately meets your needs and provides an outstanding user experience.')}</li>
                      <li className={styles['seo-info-wrapper__list2-info']}>{t('Program the application and adjust the options according to your needs and requirements, with the ability to easily add and modify sections of the application.')}</li>
                      <li className={styles['seo-info-wrapper__list2-info']}>{t('Improve and develop your existing applications, to meet the latest changes and aspirations in the market and the needs of users.')}</li>
                      <li className={styles['seo-info-wrapper__list2-info']}>{t('Create a unique shopping cart with specific specifications that matches your online store, which contributes to achieving sales and improving customer experience.')}</li>
                      <li className={styles['seo-info-wrapper__list2-info']}>{t('sing professional programming languages ​​to ensure the stability and performance of the application at the highest levels of quality and security.')}</li>
                      <li className={styles['seo-info-wrapper__list2-info']}>{t('The possibility of commission marketing and expansion in the areas of e-commerce such as the Amazon market and the integration of your store in it.')}</li>
                      <li className={styles['seo-info-wrapper__list2-info']}>{t('Integration of multiple electronic payment gateways such as HyperPay, PayFort, PayTabs, Checkout, GoCoin, PayPal, and Moyasar to facilitate payment processes and enhance the user experience in the application.')}</li>
                      <li className={styles['seo-info-wrapper__list2-info']}>{t("The ability to add images, videos, advertising and illustration banners for products and services in the application, to attract users' attention and boost your sales.")}</li>
                      <li className={styles['seo-info-wrapper__list2-info']}>{t('Create integrated websites to provide support and additional information about your applications, providing an additional channel to communicate with customers and increase awareness of your services.')}</li>
                    </ul>
                    <p className={styles['seo-info-wrapper__description3']}>
                      {t("Start your journey with us today and get a smart application that meets your aspirations and satisfies your users. Contact us now for a free consultation and an offer tailored to your needs.")}
                    </p>
                  </div>
                </Col>
              </Row>
              <HashLink to='/#contact-us' className={styles['header__feature-item']}>{t('Ask For Consultation')}</HashLink>
            </Container>
          </section>

          {/* 
          <section className={styles['seo-details-wrapper']}>
            <Container>
              <Row>
                <Col lg='4' className='mb-3'>
                  <img src={mobileApplicationHuaweiImage} className={styles['seo-details-wrapper__item-image']} alt='mobile apps'/>
                  <h3 className={styles['seo-details-wrapper__item-title']}>{t('Huawei')}</h3>
                </Col>
                <Col lg='4' className='mb-3'>
                  <img src={mobileApplicationAppleImage} className={styles['seo-details-wrapper__item-image']} alt='mobile apps'/>
                  <h3 className={styles['seo-details-wrapper__item-title']}>{t('IOS')}</h3>
                </Col>
                <Col lg='4' className='mb-3'>
                  <img src={mobileApplicationAndroidImage} className={styles['seo-details-wrapper__item-image']} alt='mobile apps'/>
                  <h3 className={styles['seo-details-wrapper__item-title']}>{t('Android')}</h3>
                </Col>
              </Row>
            </Container>
          </section>
          <section className={styles['seo-info-wrapper']}>
            <Container>
              <Row>
                <Col lg='6' className='order-2 order-lg-1 mb-2'>
                  <div className={styles['seo-info-wrapper__content-wrapper']}>
                    <ServiceAccordion changeActiveAccordionIndex={changeActiveAccordionIndex}/>
                  </div>
                </Col>
                <Col lg='6' className='order-1 order-lg-2 mb-2'>
                  <div className={styles['seo-info-wrapper__image-wrapper']}>
                    <img src={mobileApplicationIPavilionmage} alt='seo image' 
                    className={`${styles['seo-info-wrapper__image']} 
                    ${styles['seo-info-wrapper__image--hidden']} ${activeAccordionIndex==null&&styles['seo-info-wrapper__image--active']}`}/>
                    <img src={mobileApplicationUIUXImage} alt='seo image' 
                    className={`${styles['seo-info-wrapper__image']} 
                    ${styles['seo-info-wrapper__image--hidden']} ${activeAccordionIndex==0&&styles['seo-info-wrapper__image--active']}`}/>
                    <img src={mobileApplicationSecurityImage} alt='seo image' 
                    className={`${styles['seo-info-wrapper__image']} 
                    ${styles['seo-info-wrapper__image--hidden']} ${activeAccordionIndex==1&&styles['seo-info-wrapper__image--active']}`}/>
                    <img src={mobileApplicationAPIIntegrationImage} alt='seo image' 
                    className={`${styles['seo-info-wrapper__image']} 
                    ${styles['seo-info-wrapper__image--hidden']} ${activeAccordionIndex==2&&styles['seo-info-wrapper__image--active']}`}/>
                    <img src={mobileApplicationDataMigrationImage} alt='seo image' 
                    className={`${styles['seo-info-wrapper__image']} 
                    ${styles['seo-info-wrapper__image--hidden']} ${activeAccordionIndex==3&&styles['seo-info-wrapper__image--active']}`}/>
                    <img src={mobileApplicationTechnicalSupportImage} alt='seo image' 
                    className={`${styles['seo-info-wrapper__image']} 
                    ${styles['seo-info-wrapper__image--hidden']} ${activeAccordionIndex==4&&styles['seo-info-wrapper__image--active']}`}/>

                  </div>
                
                </Col>
              </Row>
            </Container>
          </section> */}


        </section>

        <ContactUs/>
        <Footer/>
    </motion.div>
    </>
  )
}

export default MobileApplications