import React from 'react'
import { useTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import ContactUs from 'components/Home/ContactUs/ContactUs'
import Footer from 'components/Global/Layout/Footer/Footer'
import { useState } from 'react'
import RequestACallPopUp from 'components/Global/Elements/RequestACallPopUp/RequestACallPopUp'
import Loading from 'components/Global/Elements/Loading/Loading'
import SuccessRequestPopup from 'components/Global/Elements/SuccessRequestPopup/SuccessRequestPopup'
import styles from './Services.module.css'
import { Col, Container, Row } from 'react-bootstrap'

import HomeWhatWeOffeMobilerIcon from 'assets/icons/HomeWhatWeOffeMobilerIcon.svg'
import HomeWhatWeOffeEcommercerIcon from 'assets/icons/HomeWhatWeOffeEcommercerIcon.svg'
import HomeWhatWeOffeWebsiterIcon from 'assets/icons/HomeWhatWeOffeWebsiterIcon.svg'
import HomeWhatWeOffeSocialMediarIcon from 'assets/icons/HomeWhatWeOffeSocialMediarIcon.svg'
import HomeWhatWeOffeBrandIdentityrIcon from 'assets/icons/HomeWhatWeOffeBrandIdentityrIcon.svg'

import MobileAppsServiceIcon from 'assets/icons/MobileAppsServiceIcon.svg'
import AdsServiceIcon from 'assets/icons/AdsServiceIcon.svg'
import EcommerceServiceIcon from 'assets/icons/E-commerceServiceIcon.svg'
import BrandIdentityServiceIcon from 'assets/icons/BrandIdentityServiceIcon.svg'
import SmsServiceIcon from 'assets/icons/SmsServiceIcon.svg'
import VideosServiceIcon from 'assets/icons/VideosServiceIcon.svg'
import WebServiceIcon from 'assets/icons/WebServiceIcon.svg'
import SocialMediaServiceIcon from 'assets/icons/SocialMediaServiceIcon.svg'
import WebHostingServiceIcon from 'assets/icons/WebHostingServiceIcon.svg'
import GraphicsServiceIcon from 'assets/icons/GraphicsServiceIcon.svg'
import MotiongraphicsServiceIcon from 'assets/icons/MotiongraphicsServiceIcon.svg'
import SeoServiceIcon from 'assets/icons/SeoServiceIcon.svg'
import ContentServiceIcon from 'assets/icons/ContentServiceIcon.svg'
import ServicesCard from 'components/Global/Elements/ServicesCard/ServicesCard'
function Services() {
  const {t} =useTranslation()
  const [isPageLoading,setIsPageLoading]=useState(true)
  const [isSuccessOpen,setIsSuccessOpen]=useState(false)
  const categories =[
    {
        icon:HomeWhatWeOffeMobilerIcon,
        title:t('Mobile Apps') ,
        link:'/services/mobile-applications',
        features:[t('Creative App Design'),t('Consistent and reliable development'),t('Smooth User Experience'),t('Ongoing support and maintenance')]
    },
    {
        icon:HomeWhatWeOffeEcommercerIcon,
        title:t('E-Commerce') ,
        link:'/services/e-commerce-web-design',
        features:[t('Attractive and responsive design'),t('Advanced e-commerce functionality'),t('Easy management system'),t('Secure integration of payment methods')]
    },
    {
        icon:HomeWhatWeOffeWebsiterIcon,
        title:t('Website-Design') ,
        link:'/services/web-design',
        features:[t('Creative and attractive design'),t('Outstanding user experience'),t('Search engine improvements'),t('Effective content coordination and organization')]
    },
    {
        icon:HomeWhatWeOffeSocialMediarIcon,
        title:t('Social Media Marketing') ,
        link:'/services/digital-marketing',
        features:[t('Creative and attractive design'),t('Outstanding user experience'),t('Search engine improvements'),t('Effective content coordination and organization')]
    },
    {
        icon:HomeWhatWeOffeBrandIdentityrIcon,
        title:t('Brand Identity') ,
        link:'/services/brand-identity',
        features:[t('Creative App Design'),t('Consistent and reliable development'),t('Smooth User Experience'),t('Ongoing support and maintenance')]
    },
]

  return (
    <>
    <motion.div 
      init={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
        <MetaTags>
              <title>Pavilion</title>
        </MetaTags>
        {/* {isPageLoading&&<Loading/>} */}
        {
          !localStorage.getItem('popUpSubmitted')&&
          <RequestACallPopUp setIsSuccessOpen={setIsSuccessOpen}/>
        }
        {isSuccessOpen&&<SuccessRequestPopup/>}
        <section className={styles['services']}>
          <Container>
            <div className={styles['services__background']}></div>
            <h1 className={styles['services__title']} data-aos="new-animation" >{t('What we offer')}</h1>
            <Row>
              {
                categories&&categories?.map(category=>(
                  <Col lg='4' md='6' xs='12' className={styles['services__col']} key={category?.id}>
                    <ServicesCard 
                    title={category?.title}
                    description={``}
                    features={category?.features}
                    image={category?.icon}
                    link={category?.link}
                    />
                  </Col>
                ))
              }
              {/* <Col lg='4' md='6' xs='12' className={styles['services__col']}>
                <ServicesCard 
                title={`${t('Mobile Apps')}`}
                description={`${t("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,")}`}
                image={MobileAppsServiceIcon}
                link='/services/mobile-applications'
                />
              </Col>
              <Col lg='4' md='6' xs='12' className={styles['services__col']}>
                <ServicesCard 
                title={`${t('Ads')}`}
                description={`${t("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,")}`}
                image={AdsServiceIcon}
                link='/services/manage-all-your-ads'
                />
              </Col>
              <Col lg='4' md='6' xs='12' className={styles['services__col']}>
                <ServicesCard 
                title={`${t('E-Commerce')}`}
                description={`${t("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,")}`}
                image={EcommerceServiceIcon}
                link='/services/e-commerce-web-design'
                />
              </Col>
              <Col lg='4' md='6' xs='12' className={styles['services__col']}>
                <ServicesCard 
                title={`${t('Brand Identity')}`}
                description={`${t("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,")}`}
                image={BrandIdentityServiceIcon}
                link='/services/brand-identity'
                />
              </Col>
              <Col lg='4' md='6' xs='12' className={styles['services__col']}>
                <ServicesCard 
                title={`${t('SMS')}`}
                description={`${t("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,")}`}
                image={SmsServiceIcon}
                link='/services/sms'
                />
              </Col>
              <Col lg='4' md='6' xs='12' className={styles['services__col']}>
                <ServicesCard 
                title={`${t('Videos')}`}
                description={`${t("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,")}`}
                image={VideosServiceIcon}
                link='/services/video-creation'
                />
              </Col>
              <Col lg='4' md='6' xs='12' className={styles['services__col']}>
                <ServicesCard 
                title={`${t('Web Design')}`}
                description={`${t("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,")}`}
                image={WebServiceIcon}
                link='/services/web-design'
                />
              </Col>
              <Col lg='4' md='6' xs='12' className={styles['services__col']}>
                <ServicesCard 
                title={`${t('Social Media')}`}
                description={`${t("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,")}`}
                image={SocialMediaServiceIcon}
                link='/services/social-media-marketing'
                />
              </Col>
              <Col lg='4' md='6' xs='12' className={styles['services__col']}>
                <ServicesCard 
                title={`${t('Web Hosting')}`}
                description={`${t("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,")}`}
                image={WebHostingServiceIcon}
                link='/services/web-hosting'
                />
              </Col>
              <Col lg='4' md='6' xs='12' className={styles['services__col']}>
                <ServicesCard 
                title={`${t('Graphics Design')}`}
                description={`${t("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,")}`}
                image={GraphicsServiceIcon}
                link='/services/graphics-design'
                />
              </Col>
              <Col lg='4' md='6' xs='12' className={styles['services__col']}>
                <ServicesCard 
                title={`${t('Motion Graphics')}`}
                description={`${t("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,")}`}
                image={MotiongraphicsServiceIcon}
                link='/services/motion-graphic'
                />
              </Col>
              <Col lg='4' md='6' xs='12' className={styles['services__col']}>
                <ServicesCard 
                title={`${t('SEO')}`}
                description={`${t("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,")}`}
                image={SeoServiceIcon}
                link='/services/seo'
                />
              </Col>
              <Col lg='4' md='6' xs='12' className={styles['services__col']}>
                <ServicesCard 
                title={`${t('Content Creation')}`}
                description={`${t("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,")}`}
                image={ContentServiceIcon}
                link='/services/content-creation'
                />
              </Col> */}
            </Row>
          </Container>
        </section>
        <ContactUs/>
        <Footer/>
    </motion.div>
    </>
  )
}

export default Services