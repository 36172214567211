import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './BlogsCard.module.css'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
function BlogsCard({blog}) {
    const {t} =useTranslation()
   
  return (
    <div className={styles['blog-card']}>
        <img src={blog?.image} alt='blog card image' className={styles['blog-card__img']}/>
        <div className={styles['blog-card__content-wrapper']}>
            <h3 className={styles['blog-card__title']}>{blog?.title}</h3>
            {/* <p className={styles['blog-card__description']}>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
            </p> */}
            <Link to={`/blog/${blog?.slug}`} className={styles['blog-card__link']}>{t('Read More')}</Link>
        </div>
    </div>
  )
}

export default BlogsCard