import styled from 'styled-components'

export const HeaderWithBGStyled = styled.header`
    background: url(${(props)=>props?.bgSrc});
    background-size: 100% 100%;
    height:100vh;
    /* position: absolute; */
    /* inset: 0; */
    /* z-index: -1; */
    /* filter: blur(10px); */
`