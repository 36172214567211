import React, { useEffect, useRef, useState } from 'react'
import { Container } from 'react-bootstrap'
import styles from './OurClients.module.css'
import {ReactComponent as MoreAboutIcon} from 'assets/icons/MoreAboutIcon.svg'
import {ReactComponent as AndroidIcon} from 'assets/icons/androidIcon.svg'
import {ReactComponent as AppleIcon} from 'assets/icons/appleIcon.svg'
import project1 from 'assets/imgs/project1.jpg'
import './OurClients.css'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination ,Autoplay} from "swiper";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import { ProjectDiv } from './style'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css';
import ReactLoading from "react-loading";
import { useSelector } from 'react-redux'
function OurClients() {
    const {t} =useTranslation()
    const projectsRef = useRef(null)
    const selector=useSelector(data=>data.GlobalReducer)
    const [projects ,setProjects] =useState([])
    
    useEffect(()=>{
        setProjects(selector?.clients)
    },[selector])
  return (
    <section className={styles['our-projects']} id='portfolio'>
        <Container>
            <h2 className={styles['our-projects__title']} data-aos='fade-up' data-aos-once='true' data-aos-delay='100' data-aos-duration='800'>{t('Our Clients')}</h2>
            <div ref={projectsRef} className={styles['our-projects__filter']}>
                <Swiper
                    spaceBetween={50}
                    slidesPerView={4}
                    // ref={swiperRef}
                    loop={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true,
                    }}
                    pagination={{
                        dynamicBullets: true,
                    }}
                    breakpoints={{
                        0: {
                            slidesPerView:2,
                            spaceBetween: 20,
                        },
                        450:{
                            slidesPerView:3,
                            spaceBetween: 20,
                        },
                        768:{
                            slidesPerView:4,
                            spaceBetween: 20,
                        },
                        1024: {
                            slidesPerView: 6,
                            spaceBetween: 40,
                        },
                        1200: {
                            slidesPerView: 8,
                            spaceBetween: 50,
                        },
                }}
                    modules={[Autoplay, Pagination]}
                    className={`${styles['our-projects__swiper']} our-projects__swiper`}
                    data-aos='fade-up' data-aos-duration='800' data-aos-delay='300' data-aos-once='true'
                >
                    {
                        projects&&projects.map((project,index)=>(
                            <SwiperSlide key={index}>
                                {/* <ProjectDiv className={'header'} bg={headerImg}></ProjectDiv> */}
                                    <img
                                        className={`our-projects__project-imgg loading-img`}
                                        src={project?.image}
                                        // effect="normal"
                                        alt='category item'
                                        />
                                {/* <div className={'our-projects__project-wrapper'}>
                                        <div className='loading-div'>
                                            <ReactLoading type={"spin"} color={'#ffffff'} height={45} width={45} />
                                        </div>
                                </div> */}
                            </SwiperSlide>
                        ))
                    }
                </Swiper> 
            </div>
        </Container>
    </section>
  )
}

export default OurClients