import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import Header from 'components/Home/Header/Header'
import ContactUs from 'components/Home/ContactUs/ContactUs'
import Footer from 'components/Global/Layout/Footer/Footer'
import WhatOurClientsSay from 'components/Home/WhatOurClientsSay/WhatOurClientsSay'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { axiosConfig } from 'utils/axiosConfig'
import AboutUs from 'components/Home/AboutUs/AboutUs'
import WhatWeOffer from 'components/Home/WhatWeOffer/WhatWeOffer'
import OurProjects from 'components/Home/OurProjects/OurProjects'
import OurClients from 'components/Home/OurClients/OurClients'
import MeetOurTeam from 'components/Home/MeetOurTeam/MeetOurTeam'
import RequestACallPopUp from 'components/Global/Elements/RequestACallPopUp/RequestACallPopUp'
import SpecialOffer from 'components/Global/Elements/SpecialOffer/SpecialOffer'
import axios from 'axios'
import Loading from 'components/Global/Elements/Loading/Loading'
import SuccessRequestPopup from 'components/Global/Elements/SuccessRequestPopup/SuccessRequestPopup'
import LocationMap from 'components/Home/LocationMap/LocationMap'
import Cookies from 'js-cookie';
function Home() {
  const {t} =useTranslation()
  const selector=useSelector(data=>data.GlobalReducer)
  const [projectCategories,setProjectCategories]=useState(localStorage.getItem('projectCategories')?JSON.parse(localStorage.getItem('projectCategories') ) :[])
  const [projects,setProjects]=useState(localStorage.getItem('projects')?JSON.parse(localStorage.getItem('projects') ) :[])
  const [categories,setCategories]=useState(localStorage.getItem('categories')?JSON.parse(localStorage.getItem('categories') ) :[])
  const [teamMembers,setTeamMembers]=useState(localStorage.getItem('teamMembers')?JSON.parse(localStorage.getItem('teamMembers') ) :[])
  const [isPageLoading,setIsPageLoading]=useState(true)
  const [isSuccessOpen,setIsSuccessOpen]=useState(false)
  const [slider,setSlider]=useState(localStorage.getItem('slider')?JSON.parse(localStorage.getItem('slider') ) :[])
  
  async function getAllCategories(){
  await axiosConfig.get(`/category/all-categories`,{
    headers: {
          "Accept-Language": `${Cookies.get('i18next')=='br'?'en':'ar'}`
      }
  }).then(res=>{
        localStorage.setItem('categories',JSON.stringify(res.data?.data))
        setCategories(res.data.data)
      }).catch(err=>{
          console.log(err)
      })
  }
  async function getAllTeamMembers(){
    await axiosConfig.get(`/member/all-members`,{
      headers: {
            "Accept-Language": `${Cookies.get('i18next')=='br'?'en':'ar'}`
        }
    }).then(res=>{
        localStorage.setItem('teamMembers',JSON.stringify(res.data?.data))
        setTeamMembers(res.data.data)
      }).catch(err=>{
          console.log(err)
      })
  }
  function getAllSlider(){
    axiosConfig.get(`/slider/all-sliders`,{
      headers: {
            "Accept-Language": `${Cookies.get('i18next')=='br'?'en':'ar'}`
        }
    }).then(res=>{
      localStorage.setItem('slider',JSON.stringify(res.data?.data))
      setSlider(res?.data?.data)
    }).catch(err=>{
        console.log(err)
    })
}
useEffect(()=>{
  window.scrollTo(0,0)
  if(localStorage.getItem('projects') &&localStorage.getItem('categories') &&localStorage.getItem('slider')
    &&localStorage.getItem('services')&&localStorage.getItem('teamMembers')){
    setIsPageLoading(false)
  }
  async function promiseAll(){
    await Promise.all([
      getAllCategories(),
      // getAllTeamMembers(),
      getAllSlider(),
    ])
    setIsPageLoading(false)
  }
  promiseAll()
},[])
  return (
    <>
    <motion.div 
      init={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
        <MetaTags>
              <title>Pavilion</title>
        </MetaTags>
        {isPageLoading&&<Loading/>}
        {/* <Loading/> */}
        {
          !localStorage.getItem('popUpSubmitted')&&
          <RequestACallPopUp setIsSuccessOpen={setIsSuccessOpen}/>
        }
        {/* {isSuccessOpen&&<SuccessRequestPopup/>} */}
        <Header categories={categories} slider={slider}/>
        <AboutUs/>
        <WhatWeOffer categories={categories} />
        <OurProjects/>
        <OurClients/>
        <WhatOurClientsSay/>
        {/* <MeetOurTeam teamMembers={teamMembers}/> */}
        <ContactUs/>
        <LocationMap/>
        <Footer/>
    </motion.div>
    </>
  )
}

export default Home