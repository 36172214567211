import React, { useState } from 'react';
import styles from './ServicesCard.module.css'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
function ServicesCard({title ,description ,image,link,features}) {
    const {t} =useTranslation()

    return (
        <>
            <Link to={link} data-aos='fade-up' data-aos-once='true' data-aos-delay='100' data-aos-duration='800'>
                <div className={styles['what-we-offer__card']} >
                    <div className={styles['what-we-offer__card-icon-wrapper']}>
                        <img src={image} alt='Services icon' className={styles['what-we-offer__card-icon']} />
                    </div>
                    <div>
                        <h3 className={styles['what-we-offer__card-title']}>{title}</h3>
                        <p className={styles['what-we-offer__card-description']}>{description}</p>
                        <ul>
                            {
                                features&&features.map((feature,indexx)=>(
                                    // <li className={styles['what-we-offer__card-description']} key={indexx}>- {feature?.feature}</li>
                                    <li className={styles['what-we-offer__card-description']} key={indexx}>- {feature}</li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </Link>
        </>
    )
}

export default ServicesCard