import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import styles from './LocationMap.module.css'
function LocationMap() {
  const {t} =useTranslation()
  return (
    <section className={styles['location']}>
        <Container>
            {/* <h2 className={styles['why-us__title']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'>{t('why_us')}</h2> */}
            {/* <p className={styles['why-us__description']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='200' data-aos-once='true'>{t('few_good_reasons')}</p> */}
            <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.5424339514766!2d55.2769027!3d25.184923500000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x82aa2650ea1602f9%3A0xc69edd6fba89ca4e!2zUGF2aWxpb24gVGVjaG5vbG9naWVzIHwg2KjYp9mB2YrZhNmK2YjZhiDYqtmD2YbZiNmE2YjYrNmK!5e0!3m2!1sen!2sae!4v1681119184601!5m2!1sen!2sae" 
            allowfullscreen="" 
            loading="lazy" 
            referrerpolicy="no-referrer-when-downgrade"
            className={styles['location__map']}
            />
        </Container>
    </section>
  )
}

export default LocationMap