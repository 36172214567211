import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './AboutUsSections.module.css'
import {ReactComponent as TwitterIcon} from 'assets/icons/twitterIcon.svg'
import {ReactComponent as InstagramDarkIcon} from 'assets/icons/instagramDarkIcon.svg'
import {ReactComponent as FacebookdarkIcon} from 'assets/icons/facebookdarkIcon.svg'
import {ReactComponent as BehanceDarkIcon} from 'assets/icons/behanceDarkIcon.svg'
import {ReactComponent as LinkedInDarkIcon} from 'assets/icons/linkedInDarkIcon.svg'
import {ReactComponent as TiktokDarkIcon} from 'assets/icons/tiktokDarkIcon.svg'
import aboutUsHeaderImg from 'assets/imgs/aboutUsHeaderImg.png'

import pavilionLogo from 'assets/imgs/pavilionLogo.png'
import {ReactComponent as PlaneIcon} from 'assets/icons/planeIcon.svg'
import {ReactComponent as CloudIcon} from 'assets/icons/cloudIcon.svg'
import {ReactComponent as CirclesIcon} from 'assets/icons/circlesIcon.svg'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
function AboutUsSections() {
    const {t} =useTranslation()
    const [settings,setSettings]=useState([])
    const selector=useSelector(data=>data.GlobalReducer)
    useEffect(()=>{
        setSettings(selector.generalSettings)
    },[selector])
 
  return (
    <section className={styles['about-us-sections']}>
        <Container className='h-100 container--header'>
            <img src={pavilionLogo} className={styles['about-us-sections__image']} alt='logo'/>
            <Row className='h-100 position-relative'>
                <Col lg='4' className='mb-3'>
                    <div className={styles['about-us-sections__section-wrapper']}>
                        <div className={styles['about-us-sections__section-icon-wrapper']}>
                            <CloudIcon className={styles['about-us-sections__section-icon']}/>
                        </div>
                        <div className={styles['about-us-sections__section-content-wrapper']}>
                            <h2 className={styles['about-us-sections__section-content-title']}>{t('Our Vision')}</h2>
                            <p className={styles['about-us-sections__section-content-description']}>{t("To make changes that the world deserves in the software world, as all of you deserve to get high-quality and professional digital services.")}</p>
                        </div>
                    </div>
                </Col>
                <Col lg='4' className='mb-3'>
                    <div className={styles['about-us-sections__section-wrapper']}>
                        <div className={styles['about-us-sections__section-icon-wrapper']}>
                            <PlaneIcon className={styles['about-us-sections__section-icon']}/>
                        </div>
                        <div className={styles['about-us-sections__section-content-wrapper']}>
                            <h2 className={styles['about-us-sections__section-content-title']}>{t('Our Message')}</h2>
                            <p className={styles['about-us-sections__section-content-description']}>{t("We believe that we are partners to our clients in success, which means their success is ours so we work hard to develop our skills and our services according to our clients’ demands.")}</p>
                        </div>
                    </div>
                </Col>
                <Col lg='4' className='mb-3'>
                    <div className={styles['about-us-sections__section-wrapper']}>
                        <div className={styles['about-us-sections__section-icon-wrapper']}>
                            <CirclesIcon className={styles['about-us-sections__section-icon']}/>
                        </div>
                        <div className={styles['about-us-sections__section-content-wrapper']}>
                            <h2 className={styles['about-us-sections__section-content-title']}>{t('Our Values')}</h2>
                            <p className={styles['about-us-sections__section-content-description']}>{t("We seek excellence so we always create innovative solutions to achieve success, in addition, we are known for our honesty and mastery.")}</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
  )
}

export default AboutUsSections