import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import imgSrc from 'assets/imgs/404.png'
import styles from './SuccessPage.module.css'
import RequestSendHero from 'assets/imgs/requestSendHero.png'
function SuccessPage() {
  const {t} =useTranslation()
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
    <motion.div 
        init={{width:0}}
        animate={{width:"100%"}}
        exit={{x:window.innerWidth ,transition:{duration:.3}}}
        > 
        <MetaTags>
            <title>Pavilion</title>
        </MetaTags>
        <section className={styles['success']}>
            <Container>
            <img src={RequestSendHero} className={styles['success__img']} alt='hero image'/>
              <div>
                  <h1 className={styles['success__title']}>
                      {t('Thank You')}
                  </h1>
                  <p className={styles['success__description']}>{t('Your Request Has Been Submitted Successfully, We Will Contact You As Soon As possible')}</p>
              </div>
            </Container>
        </section>
        
    </motion.div>
    </>
  )
}

export default SuccessPage