import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import ContactUs from 'components/Home/ContactUs/ContactUs'
import Footer from 'components/Global/Layout/Footer/Footer'
import { useState } from 'react'
import RequestACallPopUp from 'components/Global/Elements/RequestACallPopUp/RequestACallPopUp'
import Loading from 'components/Global/Elements/Loading/Loading'
import SuccessRequestPopup from 'components/Global/Elements/SuccessRequestPopup/SuccessRequestPopup'
import ServicesTransparentHeader from 'components/Services/ServicesTransparentHeader/ServicesTransparentHeader'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './WebHosting.module.css'
import webDesignConsultayionIcon from 'assets/imgs/webDesignConsultayionIcon.png'
import webDesignCollabration from 'assets/imgs/webDesignCollabration.png'
import webDesignFinalDelivery from 'assets/imgs/webDesignFinalDelivery.png'

import webDesignImage from 'assets/imgs/webDesignImage.png'
import webDesignImage2 from 'assets/imgs/webDesignImage2.png'
import brandIdentityImage from 'assets/imgs/brandIdentityImage.png'
function WebHosting() {
  const {t} =useTranslation()
  const [isPageLoading,setIsPageLoading]=useState(true)
  const [isSuccessOpen,setIsSuccessOpen]=useState(false)

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
    <motion.div 
      init={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
        <MetaTags>
              <title>Pavilion</title>
        </MetaTags>
        {/* {isPageLoading&&<Loading/>} */}
        {
          !localStorage.getItem('popUpSubmitted')&&
          <RequestACallPopUp setIsSuccessOpen={setIsSuccessOpen}/>
        }
        {isSuccessOpen&&<SuccessRequestPopup/>}
        <section className={styles['seo']}>
          <div className={styles['seo--bg']}></div>
          <ServicesTransparentHeader title={`${t('Web Hosting')}`} description={`${t('Your brand identity goes beyond a logo and colors. Brand identity is the way you communicate your brand’s value and mission. ')}`}/>

          <section className={styles['seo-details-wrapper']}>
            <Container>
                <h2 className={styles['seo-details-wrapper__title']}>{t("Hosting features you'll love.")}</h2>
                {/* <p className={styles['seo-details-wrapper__description']}>{t('A tree without roots cannot survive. That’s why we’ll take the time to induce to understand all the ins and outs of your business before writing any code. we wish to understand your customer. the issues you’re facing. The steps needed to assist you discover and retain success.')}</p> */}
              <Row>
                <Col lg='4' md='6'  className='mb-5'>
                  <h3 className={styles['seo-details-wrapper__item-title']}>{t('Free SSL certificate')}</h3>
                  <p className={styles['seo-details-wrapper__item-description']}>{t('Increase visitor trust by protecting personal and financial info and other sensitive data with encrypted connections.')}</p>
                </Col>
                <Col lg='4' md='6'  className='mb-5'>
                  <h3 className={styles['seo-details-wrapper__item-title']}>{t('Real reliability')}</h3>
                  <p className={styles['seo-details-wrapper__item-description']}>{t("We offer a 99.9% uptime guarantee. And if we don't uphold it, you may be eligible for a discount.")}</p>
                </Col>
                <Col lg='4' md='6'  className='mb-5'>
                  <h3 className={styles['seo-details-wrapper__item-title']}>{t('Easy-to-use control panel')}</h3>
                  <p className={styles['seo-details-wrapper__item-description']}>{t('Includes industry-standard cPanel to install apps and manage backups and security.')}</p>
                </Col>
                <Col lg='4' md='6'  className='mb-5'>
                  <h3 className={styles['seo-details-wrapper__item-title']}>{t('1-click domain name setup. 1-click to over 150 free apps.')}</h3>
                  <p className={styles['seo-details-wrapper__item-description']}>{t('Link your site to your domain name. Access free apps to create CMS sites (WordPress, Joomla), forums and blogs.')}</p>
                </Col>
                <Col lg='4' md='6'  className='mb-5'>
                  <h3 className={styles['seo-details-wrapper__item-title']}>{t('24/7 network security')}</h3>
                  <p className={styles['seo-details-wrapper__item-description']}>{t('A logo is the first building block in your brand identity. Whether you are a start-up company ready to start creating your identity or looking to refresh or modernize your current look, our designers can create something unique just for you.')}</p>
                </Col>
                <Col lg='4' md='6'  className='mb-5'>
                  <h3 className={styles['seo-details-wrapper__item-title']}>{t('Resources on demand')}</h3>
                  <p className={styles['seo-details-wrapper__item-description']}>{t('If you need extra power, seamlessly increase your CPU/RAM, I/O and storage with a 1-click purchase.')}</p>
                </Col>
                <Col lg='4' md='6'  className='mb-5'>
                  <h3 className={styles['seo-details-wrapper__item-title']}>{t('1 GB database storage')}</h3>
                  <p className={styles['seo-details-wrapper__item-description']}>{t('Get unlimited MySQL databases with every Linux-based web hosting plan.')}</p>
                </Col>
                <Col lg='4' md='6'  className='mb-5'>
                  <h3 className={styles['seo-details-wrapper__item-title']}>{t('Room to grow')}</h3>
                  <p className={styles['seo-details-wrapper__item-description']}>{t('We have CPU, memory, entry processes and I/O ready when you need them. Moving up is 1 click away.')}</p>
                </Col>
                <Col lg='4' md='6'  className='mb-5'>
                  <h3 className={styles['seo-details-wrapper__item-title']}>{t('Award-winning support')}</h3>
                  <p className={styles['seo-details-wrapper__item-description']}>{t('Our friendly hosting experts are standing by to take you from question to answer.')}</p>
                </Col>
              </Row>
            </Container>
          </section>

        </section>

        <ContactUs/>
        <Footer/>
    </motion.div>
    </>
  )
}

export default WebHosting