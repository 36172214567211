import OurClientsCard from 'components/Global/Elements/OurClientsCard/OurClientsCard'
import React, { useEffect, useRef, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import styles from './WhatOurClientsSay.module.css'
import './WhatOurClientsSay.css'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {ReactComponent as NextArrowIcon} from 'assets/icons/nextArrowIcon.svg'
import GoogleSearchBoxWithMap from './GoogleSearchBoxWithMap'
import { Loader } from "@googlemaps/js-api-loader";

import { Autoplay, Pagination, Navigation } from "swiper";
function WhatOurClientsSay() {
  const {t} =useTranslation()
  const swiperRef=useRef(null)
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const map = new window.google.maps.Map(document.getElementById('map'), {
        center: { lat: '25.1849235', lng: '55.2769027' },
        zoom: 15,
        mapTypeId: 'roadmap',
    });

    const placesService = new window.google.maps.places.PlacesService(map);
    // placesService.getDetails({ placeId: 'ChIJ-QIW6lAmqoIRTsqJum_dnsY' }, (place, status) => {
    placesService.getDetails({ placeId: 'ChIJ-QIW6lAmqoIRTsqJum_dnsY',fields: ['reviews'] }, (place, status) => {
      console.log('placeplaceplace',place)
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setReviews(place.reviews);
        }
    });
  }, []);
  
  return (
    <section className={`${styles['our-clients']} our-clients`} id='our-clients'>
        <Container>
            <div id="map"></div>
            {/* <div className={styles['our-clients__title-wrapper']}>
                <h2 className={styles['our-clients__title']} data-aos='fade-up' data-aos-duration='800' data-aos-once='true'>{t('see_what_our_clients')}</h2>
            </div> */}
            {/* <GoogleSearchBoxWithMap/> */}


            <Swiper
                spaceBetween={50}
                slidesPerView={1.2}
                ref={swiperRef}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
                data-aos='fade-up' data-aos-duration='800' data-aos-once='true'
            >
                {reviews.map((review) => (
                    <SwiperSlide key={review?.time}><OurClientsCard review={review}/></SwiperSlide>
                ))}
            </Swiper>
            
        </Container>
    </section>
  )
}

export default WhatOurClientsSay