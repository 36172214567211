import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import ContactUs from 'components/Home/ContactUs/ContactUs'
import Footer from 'components/Global/Layout/Footer/Footer'
import { useState } from 'react'
import RequestACallPopUp from 'components/Global/Elements/RequestACallPopUp/RequestACallPopUp'
import Loading from 'components/Global/Elements/Loading/Loading'
import SuccessRequestPopup from 'components/Global/Elements/SuccessRequestPopup/SuccessRequestPopup'
import ServicesTransparentHeader from 'components/Services/ServicesTransparentHeader/ServicesTransparentHeader'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './BrandIdentity.module.css'
import BrandIdentityBG from 'assets/imgs/BrandIdentityBG.jpg'
import brandIdentityIcon from 'assets/imgs/brandIdentityIcon.png'
import brandIdentityProjectImage1 from 'assets/imgs/brandIdentityProjectImage1.jpg'
import brandIdentityProjectImage2 from 'assets/imgs/brandIdentityProjectImage2.jpg'
import brandIdentityProjectImage3 from 'assets/imgs/brandIdentityProjectImage3.jpg'
import brandIdentityProjectImage4 from 'assets/imgs/brandIdentityProjectImage4.jpg'
import brandIdentityProjectImage5 from 'assets/imgs/brandIdentityProjectImage5.jpg'
import brandIdentityProjectImage6 from 'assets/imgs/brandIdentityProjectImage6.jpg'
import brandIdentityProjectImage7 from 'assets/imgs/brandIdentityProjectImage7.jpg'
import brandIdentityProjectImage8 from 'assets/imgs/brandIdentityProjectImage8.jpg'
import webDesignFinalDelivery from 'assets/imgs/webDesignFinalDelivery.png'

import webDesignImage from 'assets/imgs/webDesignImage.png'
import webDesignImage2 from 'assets/imgs/webDesignImage2.png'
import brandIdentitysectionImage from 'assets/imgs/brandIdentitysectionImage.png'
import HeaderWithBG from 'components/Global/Elements/HeaderWithBG/HeaderWithBG'
import { HashLink } from 'react-router-hash-link'
function BrandIdentity() {
  const {t} =useTranslation()
  const [isPageLoading,setIsPageLoading]=useState(true)
  const [isSuccessOpen,setIsSuccessOpen]=useState(false)

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
    <motion.div 
      init={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
        <MetaTags>
              <title>Pavilion</title>
        </MetaTags>
        {/* {isPageLoading&&<Loading/>} */}
        {
          !localStorage.getItem('popUpSubmitted')&&
          <RequestACallPopUp setIsSuccessOpen={setIsSuccessOpen}/>
        }
        {isSuccessOpen&&<SuccessRequestPopup/>}
        <section className={styles['seo']}>
          {/* <div className={styles['seo--bg']}></div> */}
          {/* <ServicesTransparentHeader title={`${t('Branding Identity')}`} description={`${t('Your brand identity goes beyond a logo and colors. Brand identity is the way you communicate your brand’s value and mission. ')}`}/> */}

          <HeaderWithBG 
            title={`${t('Brand Identity')}`} 
            description={`${t('We believe that design is not just an aesthetic idea, but effective communication in achieving your goal of making your brand distinctive and with it winning among competitors in the market.')}`}
            image={BrandIdentityBG}
          />
          <section className={styles['brand-identity__content-wrapper']}>
            <Container>
              <Row className='mb-4'>
                <Col lg='7' className='order-lg-1 order-2'>
                  <h2 className={styles['brand-identity__content-title']}>{t('What Is Brand Identity?')}</h2>
                  <p className={styles['brand-identity__content-description']}>
                    {t('Visual identity is the visual appearance that represents your company, organization or brand. It is the visual aspect of your public identity and includes a set of elements that are used to differentiate you and enhance your identity in the minds of customers and the public.The important elements of the visual identity include the logo, approved colors, fonts used, symbolic elements, icons, distinctive images, visual patterns, website design, printed materials such as business cards, flyers and posters, uniforms if applicable, and other elements. Visual representation of your brand.')}
                  </p>
                </Col>
                <Col lg='5' className='d-flex order-lg-2 order-1'>
                  <img 
                    src={brandIdentityIcon}
                    className={styles['brand-identity__content-image']}
                    alt='brand identity icon'
                  />
                </Col>
              </Row>
              <p className={styles['brand-identity__content-description']}>
                {t("Designing these elements professionally and consistently contributes to building a strong and professional image for your company or brand. Visual identity helps build trust, identify your brand, and set you apart from competitors. It acts as your business's identity card and helps enhance your first impression and lasting recognition.With the right visual identity elements, customers and audiences can easily recognize and remember your brand. It is one of the main tools for marketing and brand building, and it contributes to building a unified and strong image that reflects your values, vision, and distinction in the competitive market.")}
              </p>
            </Container>
          </section>
          <section className={styles['seo-details-wrapper']}>
            <Container>
                <h2 className={styles['seo-details-wrapper__title']}>{t('Why choose Pavilion Technology to create your brand identity?')}</h2>
                <p className={styles['seo-details-wrapper__description']}>{t('For Many reasons')}</p>
              <Row>
                <Col xl='4' md='6' className='m-0 p-0'>
                  <div className={`${styles['seo-details-wrapper__item-wrapper']} ${styles['seo-details-wrapper__item-wrapper--green']}`}>
                    <h3 className={styles['seo-details-wrapper__item-title']}>{t('Experience and professionalism:')}</h3>
                    <p className={styles['seo-details-wrapper__item-description']}>{t('We have a team of experienced designers and marketers skilled in creating unique and attractive visual identities. We understand the importance of visual identity in influencing the audience and leaving a strong impression. We will work with you to understand your vision and goals and turn them into a tangible and distinctive design.')}</p>
                  </div>
                </Col>
                <Col xl='4' md='6' className='m-0 p-0'>
                  <div className={`${styles['seo-details-wrapper__item-wrapper']} ${styles['seo-details-wrapper__item-wrapper--red']}`}>
                    <h3 className={styles['seo-details-wrapper__item-title']}>{t('Personalize and individualize')}:</h3>
                    <p className={styles['seo-details-wrapper__item-description']}>{t('We believe in the importance of your brand identity being unique and distinctive. We will work with you to design your visual identity elements based on the character and personality of your business. You will get a design that reflects the uniqueness of your brand and distinguishes you from competitors.')}</p>
                  </div>
                </Col>
                <Col xl='4' md='6' className='m-0 p-0'>
                  <div className={`${styles['seo-details-wrapper__item-wrapper']} ${styles['seo-details-wrapper__item-wrapper--blue-light']}`}>
                    <h3 className={styles['seo-details-wrapper__item-title']}>{t('Integration of elements')}:</h3>
                    <p className={styles['seo-details-wrapper__item-description']}>{t("We will ensure the integration and coordination of all elements of the visual identity. We'll choose the right colors, fonts, icons and graphics, and guide you in their consistent use across all marketing materials and publications. We will work to provide a distinct and unified visual identity that enhances your distinction in the business market.")}</p>
                  </div>
                </Col>
                <Col xl='4' md='6' className='m-0 p-0'>
                  <div className={`${styles['seo-details-wrapper__item-wrapper']} ${styles['seo-details-wrapper__item-wrapper--brown']}`}>
                    <h3 className={styles['seo-details-wrapper__item-title']}>{t('Attention to Detail')}:</h3>
                    <p className={styles['seo-details-wrapper__item-description']}>{t('At Pavilion Technology, we pay great attention to detail. We will ensure that every element of your visual identity reflects your vision and solidifies your brand identity. We will work on an elaborate and brilliant design that reflects your professionalism and professionalism in your field of work.')}</p>
                  </div>
                </Col>
                <Col xl='4' md='6' className='m-0 p-0'>
                  <div className={`${styles['seo-details-wrapper__item-wrapper']} ${styles['seo-details-wrapper__item-wrapper--green-light']}`}>
                    <h3 className={styles['seo-details-wrapper__item-title']}>{t('Commitment to quality and deadlines:')}</h3>
                    <p className={styles['seo-details-wrapper__item-description']}>{t('We are committed to providing high quality in all the services that we provide. We work hard to ensure your complete satisfaction and to meet your expectations. We will adhere to deadlines and ensure that work is delivered on time.')}</p>
                  </div>
                </Col>
                <Col xl='4' md='6' className='m-0 p-0'>
                  <div className={`${styles['seo-details-wrapper__item-wrapper']} ${styles['seo-details-wrapper__item-wrapper--blue']}`}>
                    {/* <h3 className={styles['seo-details-wrapper__item-title']}>{t('Experience and professionalism')}:</h3> */}
                    <p className={styles['seo-details-wrapper__item-description']}>{t('Choosing Pavilion Technology to create your commercial identity means taking advantage of our experience and professionalism in the field of design and marketing to create an exceptional visual identity that distinguishes you in the business market and reflects your vision in a strong and attractive way.')}</p>
                  </div>
                </Col>
              </Row>
              <HashLink to='/#contact-us' className={styles['header__feature-item']}>{t('Ask For Consultation')}</HashLink>
            </Container>
          </section>
          
          <section className={styles['seo-details-wrapper']}>
            <Container>
                <h2 className={styles['seo-details-wrapper__list-title']}>{t('The elements of the commercial identity that we are working on creating with high professionalism to serve your business')}</h2>
                <p className={styles['seo-details-wrapper__list-description']}>{t('We work with high professionalism to create the elements of the commercial identity that will contribute to the success of your business. We offer you the following items:')}</p>
                <ul className={styles['seo-details-wrapper__list']}>
                  <li>
                    <p className={styles['seo-details-wrapper__list-info']}>
                      <span className={styles['seo-details-wrapper__list-info--bold']}>{t('Logo')}</span>:
                      {t("We design a unique logo for you that reflects your brand identity and values, and contributes to the audience's recognition of your brand.")}
                    </p>
                  </li>
                  <li>
                    <p className={styles['seo-details-wrapper__list-info']}>
                      <span className={styles['seo-details-wrapper__list-info--bold']}>{t('Visual style')}</span>:
                      {t("We will define an integrated visual style that includes lines, shapes, engravings, and visual designs used in your commercial designs. You will have a unique style that sets you apart from your competitors.")}
                    </p>
                  </li>
                  <li>
                    <p className={styles['seo-details-wrapper__list-info']}>
                      <span className={styles['seo-details-wrapper__list-info--bold']}>{t('Tokens')}</span>:
                      {t("We will create tokens and tokens that represent your business identity and are used in branding, signatures, and other marketing materials.")}
                    </p>
                  </li>
                  <li>
                    <p className={styles['seo-details-wrapper__list-info']}>
                      <span className={styles['seo-details-wrapper__list-info--bold']}>{t('Writing style and fonts')}</span>:
                      {t("We will choose the writing style and fonts that suit your business identity and add a distinctive touch to your designs.")}
                    </p>
                  </li>
                  <li>
                    <p className={styles['seo-details-wrapper__list-info']}>
                      <span className={styles['seo-details-wrapper__list-info--bold']}>{t('Profile Design')}</span>:
                      {t("We will help you create a unique profile that presents your business and services in an innovative and attractive way. We will design consistent and attractive pages that highlight the strength and uniqueness of your business.")}
                    </p>
                  </li>
                  <li>
                    <p className={styles['seo-details-wrapper__list-info']}>
                      <span className={styles['seo-details-wrapper__list-info--bold']}>{t('Brochure Design')}</span>:
                      {t("We will design unique brochures that reflect your message and explain the benefits and features of your products or services. We will choose the appropriate design and ensure that important information is provided in an attractive manner to attract the attention of potential customers.")}
                    </p>
                  </li>
                  <li>
                    <p className={styles['seo-details-wrapper__list-info']}>
                      <span className={styles['seo-details-wrapper__list-info--bold']}>{t('Business card design')}</span>:
                      {t("We will design unique and distinctive business cards for you that express your business identity and contribute to leaving a strong impression on customers and partners. We will take care of an attractive design that highlights your contact information in a professional way.")}
                    </p>
                  </li>
                </ul>
            </Container>
          </section>

          <section className={styles['seo-info-wrapper']}>
            <Container>
              <Row>
                <Col lg='6'>
                  <img src={brandIdentitysectionImage} alt='seo image' className={styles['seo-info-wrapper__image']}/>
                </Col>
                <Col lg='6'>
                  <div className={styles['seo-info-wrapper__content-wrapper']}>
                    {/* <h2 className={styles['seo-info-wrapper__title']}>{t('HAPPY CUSTOMERS, EVERYDAY')}</h2> */}
                    <p className={styles['seo-info-wrapper__description']}>
                      {t("By using these elements, we help you build a strong and professional business identity that is in line with your vision and aims to attract and interact with the target audience and stand out from your competitors.")}
                    </p>
                  </div>
                </Col>
              </Row>
              <HashLink to='/#contact-us' className={styles['header__feature-item']}>{t('Ask For Consultation')}</HashLink>
            </Container>
          </section>

          <section className={styles['seo-info-wrapper']}>
            <Container>
              <h2 className={`${styles['seo-details-wrapper__title']} text-center`}>{t('Projects')}</h2>
              <Row>
                <Col lg='6' className='m-0 p-0'>
                  <img src={brandIdentityProjectImage1} alt='seo image' className={styles['seo-info-wrapper__image2']}/>
                </Col>
                <Col lg='6' className='m-0 p-0'>
                  <img src={brandIdentityProjectImage2} alt='seo image' className={styles['seo-info-wrapper__image2']}/>
                </Col>
                <Col lg='6' className='m-0 p-0'>
                  <img src={brandIdentityProjectImage3} alt='seo image' className={styles['seo-info-wrapper__image2']}/>
                </Col>
                <Col lg='6' className='m-0 p-0'>
                  <img src={brandIdentityProjectImage4} alt='seo image' className={styles['seo-info-wrapper__image2']}/>
                </Col>
                <Col lg='6' className='m-0 p-0'>
                  <img src={brandIdentityProjectImage5} alt='seo image' className={styles['seo-info-wrapper__image2']}/>
                </Col>
                <Col lg='6' className='m-0 p-0'>
                  <img src={brandIdentityProjectImage6} alt='seo image' className={styles['seo-info-wrapper__image2']}/>
                </Col>
                <Col lg='6' className='m-0 p-0'>
                  <img src={brandIdentityProjectImage7} alt='seo image' className={styles['seo-info-wrapper__image2']}/>
                </Col>
                <Col lg='6' className='m-0 p-0'>
                  <img src={brandIdentityProjectImage8} alt='seo image' className={styles['seo-info-wrapper__image2']}/>
                </Col>
              </Row>
              <HashLink to='/#contact-us' className={styles['header__feature-item']}>{t('Ask For Consultation')}</HashLink>
            </Container>
          </section>
        </section>

        <ContactUs/>
        <Footer/>
    </motion.div>
    </>
  )
}

export default BrandIdentity