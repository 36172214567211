import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './PortfolioDetailsChallenge.module.css'
import { useTranslation } from 'react-i18next'

function PortfolioDetailsChallenge({item}) {
    const {t} =useTranslation()
 
  return (
    <header className={styles['header']}>
        <Container className='h-100 container--header'>
          <Row>
              <h1 className={styles['header__title']}>{t('Challenge')}</h1>
              <p className={styles['header__description']}>{item?.challenge}</p>
          </Row>
        </Container>
    </header>
  )
}

export default PortfolioDetailsChallenge