import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './HeaderAboutUs.module.css'
import {ReactComponent as TwitterIcon} from 'assets/icons/twitterIcon.svg'
import {ReactComponent as InstagramDarkIcon} from 'assets/icons/instagramDarkIcon.svg'
import {ReactComponent as FacebookdarkIcon} from 'assets/icons/facebookdarkIcon.svg'
import {ReactComponent as BehanceDarkIcon} from 'assets/icons/behanceDarkIcon.svg'
import {ReactComponent as LinkedInDarkIcon} from 'assets/icons/linkedInDarkIcon.svg'
import {ReactComponent as TiktokDarkIcon} from 'assets/icons/tiktokDarkIcon.svg'
import aboutUsHeaderImg from 'assets/imgs/aboutUsHeaderImg.png'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper";
import "swiper/css/pagination";
import { HashLink } from 'react-router-hash-link'
function HeaderAboutUs() {
    const {t} =useTranslation()
    const [settings,setSettings]=useState([])
    const selector=useSelector(data=>data.GlobalReducer)
    useEffect(()=>{
        setSettings(selector.generalSettings)
    },[selector])
    useEffect(()=>{
    let imgSliderInterval =setInterval(()=>{
        let imgs = document.querySelectorAll('.js-header__img')
        let changedIndex = 0
        let priviousIndex =1
        imgs.forEach((img,index)=>{
            if(img.classList.contains(styles['header__img--active'])){
                img.classList.remove(styles['header__img--right-position'])
                img.classList.remove(styles['header__img--active'])
                img.classList.add(styles['header__img--left-position'])
                changedIndex =imgs[index+1]?index+1:0
                priviousIndex =index
            }
        })
        imgs[changedIndex].classList.add(styles['header__img--active'])
        setTimeout(()=>{
            imgs[priviousIndex].classList.remove(styles['header__img--left-position'])
            imgs[priviousIndex].classList.add(styles['header__img--right-position'])
        },1000)
    },5000)
    return ()=>clearInterval(imgSliderInterval)
    },[])
  return (
    <header className={styles['header']}>
        <Container className='h-100 container--header'>
            <Row className='h-100 position-relative'>
                <Col xl='1' className='d-none d-xl-flex'>
                    <div className={styles['header__social-wrapper']}>
                        {
                            settings?.twitter_link&&
                            <a href={`${settings?.twitter_link}`} target='_blank' className={styles['header__social-button']} data-aos='fade-up' data-aos-once='true' data-aos-delay='100' data-aos-duration='800'>
                                <TwitterIcon className={styles['header__social-button-icon']}/>
                            </a>
                        }
                        {
                            settings?.instagram_link&&
                            <a href={`${settings?.instagram_link}`} target='_blank' className={styles['header__social-button']} data-aos='fade-up' data-aos-once='true' data-aos-delay='200' data-aos-duration='800' >
                                <InstagramDarkIcon className={styles['header__social-button-icon']}/>
                            </a>
                        }
                        {
                            settings?.facebook_link&&
                            <a href={`${settings?.facebook_link}`} target='_blank' className={styles['header__social-button']} data-aos='fade-up' data-aos-once='true' data-aos-delay='300' data-aos-duration='800'>
                                <FacebookdarkIcon className={styles['header__social-button-icon']}/>
                            </a>
                        }
                        {
                            settings?.behance_link&&
                            <a href={`${settings?.behance_link}`} target='_blank' className={styles['header__social-button']} data-aos='fade-up' data-aos-once='true' data-aos-delay='400' data-aos-duration='800'>
                                <BehanceDarkIcon className={styles['header__social-button-icon']}/>
                            </a>
                        }
                        {
                            settings?.linkedin_link&&
                            <a href={`${settings?.linkedin_link}`} target='_blank' className={styles['header__social-button']} data-aos='fade-up' data-aos-once='true' data-aos-delay='500' data-aos-duration='800'>
                                <LinkedInDarkIcon className={styles['header__social-button-icon']}/>
                            </a>
                        }
                        {
                            settings?.tiktok_link&&
                            <a href={`${settings?.tiktok_link}`} target='_blank' className={styles['header__social-button']} data-aos='fade-up' data-aos-once='true' data-aos-delay='600' data-aos-duration='800'>
                                <TiktokDarkIcon className={styles['header__social-button-icon']}/>
                            </a>
                        }
                    </div>
                </Col>
                <Col xl='6' lg='6' >
                    <div className={styles['header__content-wrapper']}>
                        {/* <h3 className={styles['header__title']} data-aos='fade-up' data-aos-duration='800' data-aos-once='true'>{t('Who We Are')}</h3> */}
                        <h1 className={styles['header__title']} data-aos='fade-up' data-aos-duration='800' data-aos-once='true'>{t('Who We Are')}</h1>
                        <h2 className={`${styles['header__sub-title']}`} data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'>
                            {t('Pavilion Technology is a web design and development company and Mobile applications in addition to all digital marketing services. Founded in 2018 To provide all electronic solutions for you Pavilion Technology has gained a prestigious position with more than 5 years of experience in the field of developing and designing websites and applications.Pavilion Technology is also one of the best leading companies in all digital marketing solutions in Emirates, Egypt and the Arabian Gulf..')}
                        </h2>
                        
                    </div>
                </Col>
                <Col xl='5' lg='6'>
                    <div className={`${styles['header__img-wrapper']} d-none d-xl-flex`} >
                        <img src={aboutUsHeaderImg} alt='header image' className={`${styles['header__img']} `} />
                    </div>
                </Col>
            </Row>
        </Container>
    </header>
  )
}

export default HeaderAboutUs